import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";

import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from 'react-i18next';
import Details from "./Views/Details";
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spPickingCrateDetailsConfig } from "./spPickingCrateDetailsConfig";
// import { ReactComponent as Crate } from '../../../../../styles/svg/crate.svg';
import { ReactComponent as Crate } from '../../../../styles/svg/crate.svg';
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import { useFindOnePickupCrateQuery } from "../../../../newapi/warehouse/pickupCartSlice";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpPickingCrateDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: pickupCrateData, isLoading,isFetching,isError } = useFindOnePickupCrateQuery(props.pickingCrateId,
    {
        skip: !props.pickingCrateId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <Crate/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t("zone")} /> },
        { component: <ShRowSkeleton heading={t("reachtype")} /> },
        { component: <ShRowSkeleton heading={t("capacity")} /> },
    ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        options: defaultOptions,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon, defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.pickingCrateId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && pickupCrateData) {
            setPanel(prevPanel => ({ 
                ...prevPanel,
                title: pickupCrateData.barcode,
                subTitle: `${pickupCrateData.pickupCartLocations?.length ?? 0} ${t("locations")}`,
                icon :{
                    theme: theme.palette.mode === "dark" ? "blue" : "primary",
                    content: <Crate/>
                },
                options: [
                    {
                        heading: t('zone'),
                        content: pickupCrateData.warehouseZone?.description ?? t("unknown")
                    },
                    {
                        heading: t('reachtype'),
                        content: pickupCrateData.reachType?.description ?? t("unknown")
                    },
                    {
                        heading: t('capacity'),
                        content: pickupCrateData.capacity ?? 0
                    }
                ],
            }));
        }
    }, [pickupCrateData, isLoading,isFetching, t,theme.palette.mode]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spPickingCrateDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spPickingCrateDetailsConfig.details} data={pickupCrateData}/>
                </>
        </Sidepanel2>
    );
};

export default SpPickingCrateDetails;