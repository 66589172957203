// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    // Set virtual product is true for POST request
    useEffect(() => {
        if(loaded) {
            props.handleDataUpdate({ isVirtual: true });
        }
        setLoaded(true);
    }, [loaded]);

    return (
        <div>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("sku")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                            name="sku"
                            value={data.sku ?? ""}
                            onChange={handleChange}
                            error={props.errors.sku}
                        />
                   
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("description")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                            name="description"
                            value={data.description ?? ""}
                            onChange={handleChange}
                            error={props.errors.description}
                        />
                </Box>
            </Box>
    </div>
    );
};

const Details = (props) => {
    const { t } = useTranslation();

    const schema = yup.object({
        sku: yupRequired('sku',t),
        description: yupRequired('description',t),
    });

    return (
        <ProgressionTab 
        tabId="tabDetails" 
        subtitle={t("details")} 
        step={1}
        nextTab={"tabChildProducts"}
        maxSteps={2}
        schema={schema}
    >
        <Content />
    </ProgressionTab>
    )
}

export default Details;

