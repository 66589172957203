// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import cx from "classnames"
import { useGetRelationsQuery } from '../../../../../newapi/global/relation/relationSlice';
import ShValidatedInputDropdown from '../../../../theme/dropdowns/ShValidatedInputDropdown';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const dispatch = useDispatch();

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    const [webshopCredentialsInfo, setWebshopCredentialsInfo] = useState(data);

    const handleChange = (key, val) => {
        console.log(key, val);
        setWebshopCredentialsInfo({
            ...webshopCredentialsInfo,
            [key]: val
        });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    }

    useEffect(() => {
        props.handleDataUpdate(webshopCredentialsInfo);
    }, [webshopCredentialsInfo])



    return (
        <div>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("sync_orders")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ToggleButtonGroup
                                color={webshopCredentialsInfo.syncOrders === 'true' ? "green" : "red"}
                                value={webshopCredentialsInfo.syncOrders === 'true' ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange("syncOrders", value)}
                                aria-label="Active"
                                className={cx(`${webshopCredentialsInfo.syncOrders}`, {
                                    'active': webshopCredentialsInfo.syncOrders === 'true'
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                    
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("sync_products")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,

                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ToggleButtonGroup
                                color={webshopCredentialsInfo.syncProducts === 'true' ? "green" : "red"}
                                value={webshopCredentialsInfo.syncProducts === 'true' ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange("syncProducts", value)}
                                aria-label="Active"
                                className={cx(`${webshopCredentialsInfo.syncProducts}`, {
                                    'active': webshopCredentialsInfo.syncProducts === 'true'
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                    
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("sync_create_products")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,

                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ToggleButtonGroup
                                color={webshopCredentialsInfo.syncCreateProducts === 'true' ? "green" : "red"}
                                value={webshopCredentialsInfo.syncCreateProducts === 'true' ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange("syncCreateProducts", value)}
                                aria-label="Active"
                                className={cx(`${webshopCredentialsInfo.syncCreateProducts}`, {
                                    'active': webshopCredentialsInfo.syncCreateProducts === 'true'
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                    
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("sync_stock")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ToggleButtonGroup
                                color={webshopCredentialsInfo.syncStock === 'true' ? "green" : "red"}
                                value={webshopCredentialsInfo.syncStock === 'true' ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange("syncStock", value)}
                                aria-label="Active"
                                className={cx(`${webshopCredentialsInfo.syncStock}`, {
                                    'active': webshopCredentialsInfo.syncStock === 'true'
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                    
                </Box>
            </Box>

    </div>
    );
};

const Sync = (props) => {
    const data = props.data;
    const { t } = useTranslation();

    // let schema = {
    //     description: yupRequired('description', t),
    // };

    // if (data && data.webshop_type === "woocommerce") {
    //     schema = {
    //         ...schema,
    //         url: yupRequired('url', t),
    //         consumer_key: yupRequired('consumer_key', t),
    //         consumer_secret: yupRequired('consumer_secret', t),
    //     };
    // } else if (data && data.webshop_type === "shopify") {
    //     schema = {
    //         ...schema,
    //         shop: yupRequired('shop', t),
    //         client_key: yupRequired('client_key', t),
    //         access_token: yupRequired('access_token', t),
    //     };
    // }
    return (
        <ProgressionTab 
        tabId="tabSync" 
        subtitle={t("synchronisations")} 
        step={4}
        maxSteps={4}
        // schema={schema}
    >
        <Content />
    </ProgressionTab>
    )
}

export default Sync;

