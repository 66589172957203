import { rootSlice } from "../createRootSlice";
const PASSWORD_RESET_BASE = "passwordreset";

export const passwordResetSlice =  rootSlice.injectEndpoints({
    endpoints: (builder) => ({
        requestPasswordReset: builder.mutation({
            query: (email) => ({
                url: `/${PASSWORD_RESET_BASE}/request`,
                method: 'POST',
                body: { email },
            }),
        }),
        resetPassword: builder.mutation({
            query: (props) => ({
                url: `/${PASSWORD_RESET_BASE}/reset`,
                method: 'POST',
                body: props,
            }),
        }),
        verifyResetCode: builder.mutation({
            query: (resetCode) => ({
                url: `/${PASSWORD_RESET_BASE}/verify`,
                method: 'POST',
                body: { resetCode },
            }),
        }),
    }),
});

export const { useRequestPasswordResetMutation, useResetPasswordMutation, useVerifyResetCodeMutation } = passwordResetSlice;
