import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme"

import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../../components/theme/label/ShLabel";
import { useEffect, useRef, useState } from "react";
import { useGetWorkflowsQuery } from "../../../../../newapi/workflow/workflowSlice";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import { general_states } from "../../../../../utils/staticEnums";

import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import SpWorkflowDetails from "../../../../../components/global/Sidepanels/SpWorkflowDetails/SpWorkflowDetails";

const GridActions = (props) => {
    const theme = useTheme();
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <ButtonGroup version={2}>
            </ButtonGroup>
        </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    // const [selectedRowId, setSelectedRowId] = useState(-1);
    // const [rowSelected, setRowSelected] = useState(false);
    
    const ref = useRef();
    const [config, setConfig] = useState({"state": general_states.ACTIVE});

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
        }
    },[]);
    // pickingJobListId

    const [workflowId, setWorkflowId] = useState();
    const workflowIdRef = useRef(workflowId);
    
    const getWorkflow = (params) => {
        if (!params) return;
    
        const newWorkflowId = parseInt(params.row.id);
        // Only update state if the pickingJobListId has actually changed
        if (workflowIdRef.current !== newWorkflowId) {
            setWorkflowId(newWorkflowId);
            workflowIdRef.current = newWorkflowId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {
            field: "createdAt",
            headerName: t("created_on"),
            flex:1,
            shFilter : {
                type: "date",
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                let created = new Date(params.row.createdAt);
                return `${created.toLocaleDateString()} ${created.toLocaleTimeString()}`;
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                //TODO: brownie, implement
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                
                return (
                    <ShLabel size={32} palette="normal" variant="blue">
                      {t('active')}
                    </ShLabel>
                  );
              }
        },
        {
            field: "name", 
            headerName: t("name"),
            flex:2,
            renderCell: (params) => {
                if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
                return params.row.name
            }
        },
        {field: "actions", headerName: t("actions"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.row.actions.length
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <ShTextSkeleton/>
                )
            }
        }
    ];

    return (
        <Box height={1} ref={ref} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetWorkflowsQuery}
                config={config}
                // gridActions={<GridActions></GridActions>} 
                title={t('all')}
                gridOptions={gridOptions} 
                columns={columns}
                rows={data}
                onRowClick={getWorkflow}
                >
            </ShDataGrid2>

            <SpWorkflowDetails
                workflowId={workflowId}
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                // data={pickingJobData}
            ></SpWorkflowDetails>
        </Box>
    );
}

export default All;