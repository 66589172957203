import { Box, InputBase, useTheme } from "@mui/material"
import { useEffect, useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { useAddWarehouseLocationMutation } from "../../../newapi/warehouse/warehouselocationSlice";
import { showToastMessagePostRequest, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired, yupNumberRequired } from "../../../utils/validation";
import { useGetWarehouseLocationTypesSelectQuery } from "../../../newapi/warehouse/warehouseLocationTypeSlice";
import { useGetReachTypesSelectQuery } from "../../../newapi/warehouse/reachTypeSlice";
import { useGetWarehouseLevelsSelectQuery, useGetWarehouseZonesSelectQuery, useGetWarehousesSelectQuery } from "../../../newapi/warehouse/warehouseApiSlice";


const MAddWarehouseLocation = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);
    const [addWarehouseLocation,{isLoading}] = useAddWarehouseLocationMutation();

    const select = (selected) => {
        setSelected(selected);
    }
    
    const { data: locationTypes, locationTypesLoading } = useGetWarehouseLocationTypesSelectQuery();
    const { data: reachTypes, reachTypesLoading } = useGetReachTypesSelectQuery();
    const { data: warehouses, warehousesLoading } = useGetWarehousesSelectQuery();
    // console.log("warehouses",warehouses.data);

    const { data: warehouseLevels, warehouseLevelsLoading } = useGetWarehouseLevelsSelectQuery();
    const { data: warehouseZones, warehouseZonesLoading } = useGetWarehouseZonesSelectQuery();
    ///useGetWarehousesSelectQuery

    const [locationInfo, setLocationInfo] = useState({
        location: "",
        reachTypeId: null,
        warehouseLocationTypeId: null,
        warehouseId: null,
        warehouseZoneId: null,
        warehouseLevelId: null
    });

    useEffect(() => {
        if (props.open) {
            setLocationInfo({
                location: "",
                reachTypeId: null,
                warehouseLocationTypeId: null,
                warehouseId: null,
                warehouseZoneId: null,
                warehouseLevelId: null
            });
        }
    }, [props.open]);
    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        location: yupRequired('location', t),
        reachTypeId: yupNumberRequired('reachtype', t),
        warehouseLocationTypeId: yupNumberRequired('location_type', t),
        warehouseId: yupNumberRequired('warehouse', t),
        warehouseZoneId: yupNumberRequired('warehouse_zone', t),
        warehouseLevelId: yupNumberRequired('warehouse_level', t)
    };
    
    const schema = yup.object().shape(rules);
   
    const SaveWarehouseLocation = async (locationInfo, props) => {
        try {
            console.log(locationInfo)
            setFieldErrors({});
            await schema.validate(locationInfo, { abortEarly: false });
            const data = await addWarehouseLocation(locationInfo).unwrap()
            showToastMessagePostRequest(t, data);

            // reset object
            setLocationInfo({
                location: "",
                reachTypeId: null,
                warehouseLocationTypeId: null,
                warehouseId: null,
                warehouseZoneId: null,
                warehouseLevelId: null
            });

            props.handleClose();
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("create_failed"), t, { success: false, error: err.error });
              }
        }
    };
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        setLocationInfo({
          ...locationInfo,
          [key]: value
        });
        setFieldErrors({
            ...fieldErrors,
            [key]: ''
          });
      };
      

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("new_location")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                        }}
                    >
                        <Text light>{t("location")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                        
                                name="location"
                                value={locationInfo.location}
                                onChange={handleChange}
                                error={fieldErrors.location}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >


                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                        }}
                    >
                        <Text light>{t("warehouse")}</Text>
                    </Box>
                    <ShValidatedInputDropdown
                        name="warehouseId"
                        displayName="name"
                        changeField="id"
                        options={warehouses?.data ?? []}
                        noSelection={t("choose_option")} 
                        onChange={handleChange}
                        error={fieldErrors.warehouseId}
                    />
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                        }}
                    >
                        <Text light>{t("warehouse_level")}</Text>
                    </Box>
                        <ShValidatedInputDropdown
                            name="warehouseLevelId"
                            displayName="description"
                            changeField="id"
                            options={warehouseLevels?.data ?? []}
                            noSelection={t("choose_option")} 
                            onChange={handleChange}
                            error={fieldErrors.warehouseLevelId}
                        />
                    
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                        }}
                    >
                        <Text light>{t("warehouse_zone")}</Text>
                    </Box>
                    
                    {/* <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    > */}
                        <ShValidatedInputDropdown
                            name="warehouseZoneId"
                            displayName="description"
                            changeField="id"
                            options={warehouseZones?.data ?? []}
                            noSelection={t("choose_option")} 
                            onChange={handleChange}
                            error={fieldErrors.warehouseZoneId}
                        />
                    {/* </Box> */}
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                        }}
                    >
                        <Text light>{t("location_type")}</Text>
                    </Box>
                    
                    {/* <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    > */}
                        <ShValidatedInputDropdown
                            name="warehouseLocationTypeId"
                            displayName="description"
                            changeField="id"
                            options={locationTypes?.data ?? []}
                            noSelection={t("choose_option")} 
                            onChange={handleChange}
                            error={fieldErrors.warehouseLocationTypeId}
                        />
                    {/* </Box> */}
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                        }}
                    >
                        <Text light>{t("reach_type")}</Text>
                    </Box>
                    <ShValidatedInputDropdown
                        name="reachTypeId"
                        displayName="description"
                        changeField="id"
                        options={reachTypes?.data ?? []}
                        noSelection={t("choose_option")} 
                        onChange={handleChange}
                        error={fieldErrors.reachTypeId}
                    />
                </Box>
            </Box>
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"}
                    variant="contained" 
                    onClick={() => SaveWarehouseLocation(locationInfo, props)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddWarehouseLocation;
