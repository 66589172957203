import { Box, Button, Divider, FormControl, IconButton, InputBase, InputLabel, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import cx from "classnames"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import NumberInput from "../../../../theme/inputs/NumberInput";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import CropFreeIcon from '@mui/icons-material/CropFree';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useEditStreamMutation } from "../../../../../newapi/global/stream/streamSlice";
import {checkAllPropertiesTruthy } from "../../../../../utils"

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import SelectGroupMultiple from "../../../../theme/Select/SelectGroupMultiple";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { useGetShippersSelectQuery } from "../../../../../newapi/shipper/shipperSlice";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import EMDefaultShipper from '../../../ModalsPopover/explainers/streams/EMDefaultShipper';
const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const ShipmentPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [streamInfo, setStreamInfo] = useState({
      id: 0,
      name: "",
      shortName: "",
      minWeight: 0,
      minLngth: 0,
      minWidth: 0,
      minHeight: 0,
      minVolume: 0,
      maxWeight: 0,
      maxLength: 0,
      maxWidth: 0,
      maxHeight: 0,
      maxVolume: 0,
      deliverytimePriority: false,
      shipperId: 0
    });

    const { data: shippers } = useGetShippersSelectQuery();

    useEffect(() => {
        setStreamInfo(props.data);
    },[props.data]);
    

    const handleChange = (key, value) => {
        setStreamInfo({
          ...streamInfo,
          [key]: value
        });
      };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions
                id={streamInfo?.id}
                action={handleSaveClick}
                />,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[streamInfo]);

    
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    
    const [EditStream,{isLoading}] = useEditStreamMutation();
    const handleSaveClick = async () => {
        const canSave = true;
        if(canSave){    
            try {
                const data = await EditStream(streamInfo).unwrap()
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                showToastMessageRequestError(t("update_failed"), t, {success: false, error: err.error});
            }
            
        } else {
            if(!isLoading) {
                  showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
            }    
        }        
    };

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                overflow:'scroll',
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("shipment")} explainer={<EMDefaultShipper/>} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                        {/* <FormControl fullWidth>
        <InputLabel id="shipper-select-label">{t('shipper')}</InputLabel>
        <Select
            labelId="shipper-select-label"
            id="shipper-select"
            value={streamInfo.shipperId ?? 0}
            label={t('shipper')}
            onChange={(event) => handleChange('shipperId', event.target.value)}
        >
                {shippers?.data?.map(shipper => (
                    <MenuItem value={shipper.id}>{shipper.name}</MenuItem>
                ))
            }
        </Select>
    </FormControl> */}
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{

                            //maxWidth: 160,
                            width:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                gap:4,
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    mb:0.5,
                                    "@media screen and (max-width:47.9375em)" : {
                                        mb:0
                                    }
                                }}
                            >
                                <Text semiBold>
                                    {t('default_shipper')}
                                </Text>
                                {/*  */}
                                {/* <EMDef */}
                                
                            </Box>
                            <InputDropdown
                            displayName={"name"} 
                            onChange={(selected) => handleChange('shipperId', shippers[selected].id)}
                            noSelection={t("choose_shipper")} //Custom text when nothing is selected
                            options={shippers ?? null}
                            selected={shippers?.findIndex(shipper => shipper.id === streamInfo.shipperId) ?? -1}
                            closeOnSelection
                        />
                    </Box>
                </Box>
        {/* <Box 
        sx={{
            background: colors["blue"][100],
            color: colors["blue"][400],
            padding:2,
            width:1,
            marginTop:5,
        }}
    >
        <Typography fontWeight={"bold"} fontSize={"17"}>
            {t("mp_add_stream_shipper_rules_title")}
        </Typography>
        <Box sx={{paddingTop:0.5}}>
            <Typography fontSize={"14"}>
            {t("mp_add_stream_shipper_rules_text")}
            </Typography>
        </Box>
    </Box> */}
                <Box 
                    display={"flex"} 
                    flexDirection={"column"}
                    sx={{
                        gap:1,
                        paddingTop:3,
                        maxWidth: 160,
                        "@media screen and (max-width:47.9375em)" : {
                            width:1,
                            flexDirection:"row",
                            alignItems:"center",
                            justifyContent:"space-between",
                        }
                    }}
                    >
                    <Text light>
                    {t('deliverytime_priority')}
                    </Text>
                    <ToggleButtonGroup
                        color={streamInfo.deliverytimePriority === true ? "green" : "red"}
                        value={streamInfo.deliverytimePriority ? "true" : "false"}
                        exclusive
                        onChange={(e,b) => handleChange("deliverytimePriority", b === "true")}
                        aria-label="Active"

                        className={cx(`${streamInfo.deliverytimePriority}`, {
                            'active': streamInfo.deliverytimePriority === true
                        })}
                        sx={{
                            background: colors.red[100],
                            padding:0.5,
                            borderRadius:5,
                            transition:"250ms",
                            "&.active" : {
                                background:colors.green[100],
                                transition:"250ms",
                            },
                        }}
                    >
                        <ToggleButton 
                            sx={{
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.green[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.red[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="false">
                            {t('inactive')}
                        </ToggleButton>
                        <ToggleButton 
                            sx={{
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.red[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.green[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="true"
                        >
                            {t('active')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Dropdown>
            
            

            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    },
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"none"
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
                <GridActions
                    id={streamInfo?.id}
                    action={handleSaveClick}
                />
            </Box>
        </Box>
    );
}

export default ShipmentPanel;