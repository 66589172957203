import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const WORKFLOW_BASE = "/workflows";
const buildUrl = (endpoint) => WORKFLOW_BASE + endpoint;

const workflowSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getWorkflows: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'Workflows' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getWorkflows', 'Workflows')
        }),
        getWorkflow: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            // providesTags: (id) => [{ type: `CountingStrategySingle`,id }]
            providesTags: (result, error, arg) => [{ type: `WorkflowSingle`, id: arg }]
        }),
        updateWorkflow: builder.mutation({
            query: workflowInfo => ({
                url: buildUrl(`/${workflowInfo.id}`),
                method: 'PUT',
                body: workflowInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Workflows' }, { type: `WorkflowSingle`, id: arg.id }],
        }),
        deleteWorkflow: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Workflows' }, { type: `WorkflowSingle`, id: arg.id }],
        }),
        getWorkflowLogs: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/logs`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: `WorkflowLogs`}]
        }),
        createWorkflowLog: builder.mutation({
            query: logInfo => ({
                url: buildUrl(`/${logInfo.workflowId}/logs/new`),
                method: 'POST',
                body: logInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'WorkflowLogs' }],
        }),
        updateWorkflowLog: builder.mutation({
            query: logInfo => ({
                url: buildUrl(`/${logInfo.workflowId}/logs/${logInfo.id}`),
                method: 'PUT',
                body: logInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'WorkflowLogs' }],
        }),
        deleteWorkflowLog: builder.mutation({
            query: logInfo => ({
                url: buildUrl(`/${logInfo.workflowId}/logs/${logInfo.id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'WorkflowLogs' }],
        }),
        updateWorkflowAction: builder.mutation({
            query: actionInfo => ({
                url: buildUrl(`/${actionInfo.workflowId}/actions/${actionInfo.id}`),
                method: 'PUT',
                body: actionInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'WorkflowLogs' }],
        }),
    })
});

export const {
    useGetWorkflowsQuery,
    useGetWorkflowQuery,
    useUpdateWorkflowMutation,
    useDeleteWorkflowMutation,
    useGetWorkflowLogsQuery,
    useCreateWorkflowLogMutation,
    useUpdateWorkflowLogMutation,
    useDeleteWorkflowLogMutation,
    useUpdateWorkflowActionMutation
  } = workflowSlice;
