import { Box, InputBase, List, ListItem, ListItemButton, ListItemText, Popover } from "@mui/material";
import { useTheme } from "@mui/material";
import {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import { tokens } from "../../../theme";
import Shbutton from "../buttons/Shbutton";
import SortIcon from '@mui/icons-material/Sort';
import SHModal from "../Modal/SHModal";
import CheckIcon from '@mui/icons-material/Check';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Subheading from "../text/Subheading";

import FilterModal from "./Filter/FilterModal";
import FilterContextProvider, { useFilter } from "../../../contexts/FilterState";
import { getConditionExtensionTypes } from "./Filter/Settings";

import { useDispatch, useSelector } from "react-redux";

import { resetSearchString, setFilterObject, updateSearch } from "../../../newapi/global/filter/gridFilterSlice";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}


const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
};

const GridFilterBody = forwardRef((props,ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();

    const dispatch = useDispatch();

    const location = useLocation();

    const [windowSize, setWindowSize] = useState(window.innerWidth);
    
    const [exportAnchorEl, setExportAnchorEl] = useState(null);
    const exportPopoverOpen = Boolean(exportAnchorEl);
    const exportPopoverId = exportPopoverOpen ? 'export-popover' : undefined;

    
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const filterPopoverOpen = Boolean(filterAnchorEl);
    const filterPopoverId = filterPopoverOpen ? 'filter-popover' : undefined;

    
    const [apiRef, setApiRef] = useState(props.apiRef);
    const [columns, setColumns] = useState([]);
    const [sortedBy, setSortedBy] = useState(null);
    const [sortTypes, setSortTypes] = useState([{type: "asc", sort: true}, {type:"desc", sort:false}])
    const [searchText, setSearchText] = useState("");

    const {filter,updateFilter} = useFilter();

    let hasBeenSet = false;

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }


    const handleFilterChange = (obj) => {
        const filterObj = obj;
        // setFilterObject
        // dispatch(setFilterObject(filterObj));
    }

    // useEffect(() => {
    //     if (prevPathnameRef.current !== undefined && prevPathnameRef.current !== location.pathname) {
    //         console.log("hiya",location.pathname);
    //         // Only dispatch if the pathname has actually changed.
    //         dispatch(resetSearchString());
    //     }
    //     prevPathnameRef.current = location.pathname;
    // }, [location.pathname])




    const handleExportPopoverClick = (event) => {
        setExportAnchorEl(event.currentTarget);
    };

    const handleExportPopoverClose = () => {
        setExportAnchorEl(null);
    };


    const handleFilterPopoverClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
        // setExportAnchorEl(event.currentTarget);
    };
    // useEffect(() => {
    //     console.log("filterAnchorEl",filterAnchorEl);
    // },[filterAnchorEl])

    const handleFilterPopoverClose = () => {
        setFilterAnchorEl(null);
        // setExportAnchorEl(null);
    };

    const handleResize = () => {
        setWindowSize(window.innerWidth);
    }

    const sort = (column) => {
        const field = column.field;
        const sort = sortTypes[0].sort ? "asc" : "desc";
        props.apiRef.current.setSortModel([
            {
                field: field,
                sort: sort,
            },
        ]);
        setSortedBy({
            field: field,
            sort: sort,
        })
    }

    const changeSortType = (sortType) => {
        setSortTypes(sortType);
        sort(sortedBy);
    }

    const exportData = (type) => {
        var fields = [];
        for(var i = 0; i < columns.length;i++) {
            fields.push(columns[i].field);
        }

        var options = {
            fields: fields
        }
        if(type === "csv") {
            props.apiRef.current.exportDataAsCsv(options);
        } else {
            props.apiRef.current.exportDataAsPrint(options);
        }
    }

    const [searchTerm, setSearchTerm] = useState('');
    const debounceTimeoutRef = useRef(null);


    const debounceSearch = (searchValue) => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }
        debounceTimeoutRef.current = setTimeout(() => {
            dispatch(updateSearch(searchValue));
        }, 500); // 500ms delay
    };

    const handleSearchChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        debounceSearch(value);
    };

    useEffect(() => {
        // Cleanup function to clear the timeout when the component unmounts
        return () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
        };
    }, []);

    // const search = (searchValue) => {
    //     // setSearchText(searchValue);
    //     // const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    //     // const arr = Array.from(rows, function (entry) {
    //     //     return entry[1];
    //     // });
    //     // // console.log(arr);
    //     // const newRows = arr.filter((row) => {
    //     //     return Object.keys(row).some((field) => {
    //     //         return searchRegex.test(row[field].toString());
    //     //     });
    //     // });
    //     // // console.log(newRows);
    //     // props.apiRef.current.setRows(newRows);
    //     dispatch(updateSearch(searchValue));

    //     // handleFilterChange({searchString: searchValue});
    //     // console.log(props.apiRef.current.getRowModels());
    // }
    // const search = (searchValue) => {
    //     if (searchTimeout) {
    //         clearTimeout(searchTimeout);
    //     }
    //     const newTimeout = setTimeout(() => {
    //         dispatch(updateSearch(searchValue));
    //     }, 500); // 500ms delay
    //     setSearchTimeout(newTimeout);
    // };

    // const applyNewFilterGroup = (groupIndex) => {
        
    //     if(typeof filter.groups !== "undefined"){
    //         let group = filter.groups[groupIndex];
    //         let newrows = [];

    //         if(getConditionExtensionTypes(group.conditionExtensionType) === "and") {
    //             //Apply to current set of rows
    //             newrows = pushoutDuplicates(
    //                 ...filterGroup(filteredRows, group)
    //             );
    //         } else{
    //             //Condition for OR and WHERE are the same.
    //             newrows = pushoutDuplicates(
    //                 [...filteredRows,...filterGroup(rows, group)]
    //             );
    //         }
    //         setFilteredRows(newrows);
    //         props.apiRef.current.setRows(newrows);
    //     }
    // }

    // const pushoutDuplicates = (rows) => {
    //     return rows.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
    // }

    // //Filter
    // const applyNewFilterRule = (rule) => {
    //     if(getConditionExtensionTypes(rule.extensionType) === "and") {
    //         setFilteredRows((filteredRows) => filteredRows.push(filterRule(filteredRows,rule)))
    //     } else {
    //         //Need to fix this
    //         setFilteredRows((filteredRows) => filteredRows.push(filterRule(rows,rule)));

    //     }
    // }

    // const reapplyFilterGroupImportance = (oldIndex, newIndex) => {

    // }

    // const reapplyFilterRuleImportance = (group, oldIndex, newIndex) => {

    // }

    // const filterAfterRemoval = () => {
    //     if(filter.groups.length===1&&filter.groups[0].rules.length===0){
    //         props.apiRef.current.setRows(rows);
    //         return;
    //     }

    //     const newRows = [];
    //     for(let i =0; i < filter.groups.length;i++) {
    //         for(let j = 0; j < filter.groups[i].rules.length;j++) {
    //             newRows.push(...filterRule(filteredRows,filter.groups[i].rules[j]));
    //         }
    //     }
    //     setFilteredRows(pushoutDuplicates(newRows));
    //     props.apiRef.current.setRows(newRows);
    // }

    // const filterGroup = (rowsToFilterOn,group) => {
    //     const newRows = [];
    //     for(let i = 0; i < group.rules.length; i++) {
    //         newRows.push(...filterRule(rowsToFilterOn,group.rules[i]));
    //     }

    //     return newRows;
    // }
    // const filterRule = (rowsToFilterOn,rule) => {
    //     if(rule.type.name === "text" || rule.type.name === "string") {
    //         return filterText(rowsToFilterOn,rule);
    //     } else if(rule.type.name === "number") {
    //         return filterNumber(rowsToFilterOn,rule);
    //     } else if(rule.type.name === "date") {
    //         return filterDate(rowsToFilterOn,rule);
    //     } else if(rule.type.name === "boolean"){
    //         return booleanFilter(rowsToFilterOn,rule);
    //     } else if(rule.type.name === "options"){
    //         return optionsFilter(rowsToFilterOn,rule);
    //     }
    //     return [];
    // }

    // const filterText = (rowsToFilterOn, rule) => {
    //     switch(rule.type.value) { 
    //         case 0: //equal to
    //             return equalsFilter(rowsToFilterOn,rule);
    //         case 1: //except
    //             return notEqualsFilter(rowsToFilterOn,rule);
    //         case 2: //contains
    //             return containsFilter(rowsToFilterOn,rule);
    //         case 3: //starts with
    //             return startsWithFilter(rowsToFilterOn,rule);
    //         case 4: //ends with
    //             return endsWithFilter(rowsToFilterOn,rule);
    //         default: 
    //             alert("Incorrect type for filter"); 
    //             return [];
    //     }
    // }

    // const filterNumber = (rowsToFilterOn,rule) => {
    //     switch(rule.type.value) { 
    //         case 0: //equal to
    //             return equalsFilter(rowsToFilterOn,rule);
    //         case 1: //bigger than
    //             return largerThanFilter(rowsToFilterOn,rule);
    //         case 2: //smaller than
    //             return smallerThanFilter(rowsToFilterOn,rule);
    //         case 3: //between
    //             return betweeNumberFilter(rowsToFilterOn,rule);
    //         default: 
    //             alert("Incorrect type for filter"); 
    //             return [];
    //     }
    // }

    // const filterDate = (rowsToFilterOn,rule) => {
    //     switch(rule.type.value) { 
    //         case 0: //after
    //             return afterFilter(rowsToFilterOn, rule);
    //             // return containsFilter(rowsToFilterOn,rule);
    //         case 1: //before
    //             return beforeFilter(rowsToFilterOn, rule);
    //             // return containsFilter(rowsToFilterOn,rule);
    //         case 2: //between
    //             return betweenFilter(rowsToFilterOn,rule);
    //             // return containsFilter(rowsToFilterOn,rule);
    //         default: 
    //             // alert("Incorrect type for filter"); 
    //             return [];
    //     }
    // }

    // const booleanFilter = (rowsToFilterOn,rule) => {
    //     switch(rule.type.value) { 
    //         case 0: //equals
    //             return booleanEqualsFilter(rowsToFilterOn,rule);
    //         case 1: //not equals
    //             return booleanNotEqualsFilter(rowsToFilterOn,rule);
    //         default: 
    //             alert("Incorrect type for filter"); 
    //             return [];
    //     }
    // }

    // const optionsFilter = (rowsToFilterOn,rule) => {
    //     switch(rule.type.value) { 
    //         case 0: //equals
    //             return equalsFilter(rowsToFilterOn,rule);
    //         case 1: //not equals
    //             return notEqualsFilter(rowsToFilterOn,rule);
    //         default: 
    //             alert("Incorrect type for filter"); 
    //             return [];
    //     }
    // }

    // const containsFilter = (rowsToFilterOn, rule) => {
    //     const filterRegex = new RegExp(escapeRegExp(rule.value), 'i');
    //     const arr = Array.from(rowsToFilterOn, function (entry) {
    //         return entry[1];
    //     });
    //     return arr.filter((row) => {
    //         return filterRegex.test(row[columns[rule.column].field].toString());
    //     });
    // }

    // const endsWithFilter = (rowsToFilterOn,rule) => {
    //     const arr = Array.from(rowsToFilterOn, function (entry) {
    //         return entry[1];
    //     });
    //     return arr.filter((row) => {
    //         return row[columns[rule.column].field].toString().endsWith(rule.value);
    //     })
    // }

    // const startsWithFilter = (rowsToFilterOn,rule) => {
    //     const arr = Array.from(rowsToFilterOn, function (entry) {
    //         return entry[1];
    //     });
    //     return arr.filter((row) => {
    //         return row[columns[rule.column].field].toString().startsWith(rule.value);
    //     })
    // }

    // const equalsFilter = (rowsToFilterOn,rule) => {
    //     const arr = Array.from(rowsToFilterOn, function (entry) {
    //         return entry[1];
    //     });
    //     return arr.filter((row) => {
    //         return row[columns[rule.column].field] === rule.value;
    //     })
    // }

    // const notEqualsFilter = (rowsToFilterOn,rule) => {
    //     const arr = Array.from(rowsToFilterOn, function (entry) {
    //         return entry[1];
    //     });
    //     return arr.filter((row) => {
    //         return row[columns[rule.column].field] !== rule.value;
    //     })
    // }

    // const beforeFilter = (rowsToFilterOn, rule) => {
    //     const arr = Array.from(rowsToFilterOn, function (entry) {
    //         return entry[1];
    //     });

    //     return arr;
    //     return arr.filter((row) => {
    //         return row[columns[rule.column].field];
    //     })
    // };
    // const afterFilter = (rowsToFilterOn, rule) => {
    //     const arr = Array.from(rowsToFilterOn, function (entry) {
    //         return entry[1];
    //     });

    //     return arr;
    //     return arr.filter((row) => {
    //         return row[columns[rule.column].field];
    //     })
    // };
    // const betweenFilter = (rowsToFilterOn, rule) => {
    //     const arr = Array.from(rowsToFilterOn, function (entry) {
    //         return entry[1];
    //     });

    //     return arr;
    //     return arr.filter((row) => {
    //         return row[columns[rule.column].field];
    //     })
    // };

    // const booleanEqualsFilter = (rowsToFilterOn,rule) => {
    //     const arr = Array.from(rowsToFilterOn, function (entry) {
    //         return entry[1];
    //     });
    //     return arr.filter((row) => {
    //         return row[columns[rule.column].field];
    //     })
    // }

    // const booleanNotEqualsFilter = (rowsToFilterOn,rule) => {
    //     const arr = Array.from(rowsToFilterOn, function (entry) {
    //         return entry[1];
    //     });
    //     return arr.filter((row) => {
    //         return row[columns[rule.column].field] === false;
    //     })
    // }

    // const largerThanFilter = (rowsToFilterOn, rule) => {
    //     const arr = Array.from(rowsToFilterOn, function (entry) {
    //         return entry[1];
    //     });
    //     return arr.filter((row) => {
    //         return row[columns[rule.column].field] > rule.value;
    //     })
    // }

    // const smallerThanFilter = (rowsToFilterOn, rule) => {
    //     const arr = Array.from(rowsToFilterOn, function (entry) {
    //         return entry[1];
    //     });
    //     return arr.filter((row) => {
    //         return row[columns[rule.column].field] < rule.value;
    //     })
    // }

    // const betweeNumberFilter = (rowsToFilterOn, rule) => {
    //     const arr = Array.from(rowsToFilterOn, function (entry) {
    //         return entry[1];
    //     });
    //     return arr.filter((row) => {
    //         return row[columns[rule.column].field] > rule.value && row[columns[rule.column].field] < rule.value;
    //     })

    // }

    useEffect(() => {
        updateFilter(props.gridOptions.filter);

        window.addEventListener("resize", handleResize);
        
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    },[])

    // useEffect(() => {
    //     console.log("From gridfilter", props.apiRef);
    // },[props.apiRef]);

    // useEffect(() => {
    //     if(Object.keys(props.apiRef).length > 0 && props.apiRef.current !== null && Object.keys(props.apiRef.current).length > 0 && props.apiRef.current !== null) {

    //         // setApiRef(props.apiRef);
    //         var tColumns = props.apiRef.current.getAllColumns();
    //         tColumns = tColumns.slice(0,-1);
    //         setColumns(tColumns);
    //         const sort = sortTypes[0].sort ? "asc" : "desc";
    //         setSortedBy([{
    //             field: tColumns[0].field,
    //             sort: sort
    //         }]);
    //         // console.log("gfilter",props.apiRef.current);
    //         // console.log("gfilterrows",props.apiRef.current.getRowModels());
    //         setRows(props.apiRef.current.getRowModels());
    //         // hasBeenSet = true;
    //     }
    // },[props.apiRef.current]);

    // useImperativeHandle(ref,() => ({
    //     reapplyFilterGroupImportance(oldIndex, newIndex) {

    //     },
    //     reapplyFilterRuleImportance(group, oldIndex, newIndex) {

    //     },
    //     applyNewFilterGroup(groupIndex) {
    //         applyNewFilterGroup(groupIndex);
    //     },
    //     applyNewFilterRule(groupIndex,ruleIndex) {
    //         applyNewFilterRule(filter.groups[groupIndex].rules[ruleIndex]);
    //     },
    //     applyFilterAfterRemoval(){
    //         filterAfterRemoval();
    //     }
    // }),[filter,props.apiRef]);
    

    return (
        <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
            {/* {alert(JSON.stringify(props.gridOptions))} */}
            <Box
                sx={{
                    background: `${theme.palette.mode === "dark" ? colors.grey[300] : props.gridOptions.isSidepanel && windowSize < 768 ? colors.grey[200] : colors.grey[100]}`,
                    // height:40,
                    display:"flex",
                    borderRadius:5,
                    alignItems:"center",
                    paddingRight:0.5,
                    "@media screen and (max-width: 47.9375em)" : {
                        flex:1,
                    }
                    // alignItems:"center"
                }}
            >
                <InputBase 
                    sx={{
                        maxWidth:300,
                        height:40,
                        paddingX:3,
                        flex:1,
                        width:1,
                    }} 
                    type="search" 
                    placeholder={t("search")}
                    value={searchTerm}
                    onChange={handleSearchChange}
                >

                </InputBase>
                <Shbutton 
                    size="32" 
                    color="primaryGrey" 
                    variant="contained" 
                    isFilterModalBtn 
                    aria-describedby={filterPopoverId} 
                    onClick={handleFilterPopoverClick}
                >
                    <FilterAltIcon fontSize="16" />
                    <Box sx={{
                        paddingX:1,
                        height:24,
                        lineHeight:"24px", 
                        background:"#fff",
                        borderRadius:8, 
                        marginLeft:1.5,
                        color:`${theme.palette.mode === "dark" ? colors.grey[500] : colors.txt["primary"]}`,
                        fontWeight:700
                        }}>
                        8
                    </Box>
                </Shbutton>
            </Box>

            {/* {
                windowSize <= 767 ? 
                <Shbutton onClick={handleOpen} aria-describedby={exportPopoverId} icon color="primaryGrey" variant="contained" ><SortIcon fontSize="small" /></Shbutton>
                :
                null
            } */}
            {
                windowSize > 767 ? 
                <Shbutton aria-describedby={exportPopoverId} onClick={handleExportPopoverClick} color="primaryGrey" variant="contained" >{t('export')}</Shbutton>
                :
                <Shbutton aria-describedby={exportPopoverId} icon onClick={handleExportPopoverClick} color="primaryGrey" variant="contained" ><DownloadIcon /></Shbutton>
            }
            
            <FilterModal
                id={filterPopoverId}
                open={filterPopoverOpen}
                anchorEl={filterAnchorEl}
                onClose={handleFilterPopoverClose}
                apiRef={apiRef}
                filterRef={ref}
                changeSortType={changeSortType}
                sortTypes={sortTypes}
                columns={columns}
                hiddenColumns={props.hiddenColumns}
                pinnedColumns={props.pinnedColumns}
                sortedBy={sortedBy}
                sort={sort}
            />

            
            <Popover 
                id={exportPopoverId}
                open={exportPopoverOpen}
                anchorEl={exportAnchorEl}
                onClose={handleExportPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    // background:"blue"
                    borderRadius:8,
                    "& .MuiPaper-root" : {

                        "& ul" : {
                            background:`${theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[0]}`,
                            marginTop:1.5,
                            borderRadius:3,
                            boxShadow: "0px 2px 2px rgba(49, 65, 95, 0.25)"
                        },
                        // borderRadius:3,
                        background:`transparent`,
                        boxShadow: "none"
                        // background:"orange",
                    }
                }}
            >
                <List
                    sx={{
                        padding:0
                    }}
                >
                    <ListItem disablePadding onClick={() => exportData("csv")}>
                        <ListItemButton>
                            <ListItemText sx={{fontSize:"15px !important"}} primary="Download as CSV"></ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={() => exportData("print")}>
                        <ListItemButton>
                            <ListItemText fontSize="14" primary="Print"></ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Popover>

            {/* Extra grid actions */}
            { props.gridOptions.disableCustomActionsOnPhone && windowSize > 767 
                ?
                props.gridActions
                :
                null 
            }

            {/* sort modal */}
            <SHModal
                open={open}
                onClose={handleClose}
            >
                <Box 
                    sx={{
                        background:colors.bg["tertiary"]
                    }}
                >
                    <Box 
                        sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"center",
                            position:"relative",
                            textAlign:"center",
                            paddingY:4,
                            paddingX:3,
                        }}>

                        <Box
                            sx={{
                                // position:"absolute",
                                top:16,
                                left:24,
                                display:"flex",
                                gap:1,
                                alignItems:"center"
                            }}
                        >
                            <Shbutton onClick={() => changeSortType([{type: "asc", sort: true}, {type:"desc", sort:false}])} size="32" icon color={sortTypes[0].sort ? "primary" : "primaryGrey"} variant="contained"><SortIcon fontSize="small" /></Shbutton>
                            <Shbutton onClick={() => changeSortType([{type: "asc", sort: false}, {type:"desc", sort:true}])} size="32" icon color={sortTypes[1].sort ? "primary" : "primaryGrey"} flipped variant="contained"><SortIcon fontSize="small" /></Shbutton>

                        </Box>

                        <Subheading>Sorteren</Subheading>
                        <Box width={72}></Box>
                        
                    </Box>
                {/* Hello */}
                
                    <Box
                        sx={{
                            borderTopLeftRadius:24,
                            borderTopRightRadius:24,
                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                            paddingTop:3,
                        }}
                    >
                        <List
                            sx={{
                                padding:0,
                            }}
                        >
                        {columns.map((column, index) => (

                            <ListItem key={column.field + "index"} disablePadding onClick={() => sort(column)}>
                                <ListItemButton 
                                    sx={{
                                        color:`${sortedBy !== null && sortedBy.field === column.field ? theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400] : colors.txt["secondary"]}`,
                                        fontWeight:`${sortedBy !== null && sortedBy.field === column.field ? 500 : 400}`,
                                        display:"flex",
                                        justifyContent:"space-between",
                                        fontSize:"14px",
                                        paddingX:3,
                                        height:40,
                                    }}
                                >
                                    {column.headerName}

                                    {sortedBy !== null && sortedBy.field === column.field 
                                        ?
                                        <CheckIcon fontSize="small"/>
                                        :
                                        null
                                    }
                                </ListItemButton>
                            </ListItem>
                            // <Box>
                            //     {column.headerName}
                            // </Box>
                        ))}
                        </List>
                    </Box>
                    <Box
                        sx={{
                            paddingBottom:isStandAlone ? "32px" : "16px",
                            width:1,
                            paddingX:3,
                            paddingTop:3,
                            display:"flex",
                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                        }}
                    >
                        <Shbutton onClick={handleClose} color="primaryGrey" variant="contained" modalFooterBtn endIcon={<ArrowRightAltIcon/>}>Sorteren</Shbutton>
                    </Box>
                </Box>
            </SHModal>
            {/* <Modal></Modal> */}
        </Box>
    )
});


const GridFilter = (props) => {
    const ref = useRef();
    return(
            <GridFilterBody ref={ref} {...props}></GridFilterBody>
    )
}
export default GridFilter