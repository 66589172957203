import { Box, IconButton, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../theme";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
//import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import ShDataGrid from "../../../../components/theme/datagrid/ShDataGrid";
import Subheading from "../../../../components/theme/text/Subheading";
import Text from "../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import { useTranslation } from "react-i18next";
import { useGetRelationQuery, useGetRelationsQuery  } from "../../../../newapi/global/relation/relationSlice";
import SpRelationDetails from "../../../../components/global/Sidepanels/SpRelationDetails/SpRelationDetails";
import MpAddRelation from "../../../../components/global/ModalsProgression/MpAddRelation/MpAddRelation";
import ShDataGrid2 from "../../../../components/theme/datagrid2/ShDataGrid2";
//import MpAddShipper from "../../../../../components/global/ModalsProgression/MpAddShipper/MpAddShipper";


const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('add_relation')}
            </Shbutton>
        </ButtonGroup>
    );
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(1);
    const [rowSelected, setRowSelected] = useState(false);
    // const { data: relationData, error, isLoading } = useGetRelationQuery(
    //    selectedRowId,
    //     {
    //         skip: !rowSelected
    //     }
    // );
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            // apiRef: props.apiRef
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);


    const [relationId, setRelationId] = useState();
    const relationIdRef = useRef(relationId);
    
    const getRelationDetails = (params) => {
        if (!params) return;
    
        const newrelationId = parseInt(params.row.id);
        // Only update state if the relationId has actually changed
        if (relationIdRef.current !== newrelationId) {
            setRelationId(newrelationId);
            relationIdRef.current = newrelationId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    // useEffect(() => {
    //     if (props.apiRef.current) {
    //         props.addView({
    //             ...view,
    //             apiRef: props.apiRef
    //         },true);
    //     } 
    // }, [props.apiRef.current]);
    
    
    const columns = [
        {
            field: "name", 
            headerName: t("relation"), 
            flex:2, 
            cellClassName: "relation", 
            renderCell : (cellValues) => {
            return (
                <Text semibold>{cellValues.row.name}</Text>
            )
        }},
        {
            field: "phone",
            headerName: t("phone"),
            flex:1,
            renderCell : (cellValues) => {
                return cellValues.row.phone
            },
        },
        {
            field: "email",
            headerName: t("email"),
            flex:1,
            renderCell : (cellValues) => {
                return cellValues.row.email
            },
        },
        {
            field: "website",
            headerName: t("website"),
            flex:1,
            renderCell : (cellValues) => {
                return cellValues.row.website
            },
        },
        {
            field: "webshopcount",
            headerName: t("websites_linked"),
            flex:1,
            renderCell : (cellValues) => {
                return (cellValues.row.webshopCredentials ? cellValues.row.webshopCredentials.length : 0)
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];

    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetRelationsQuery}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                columns={columns} 
                
                onRowClick={getRelationDetails}
                rows={data}>
            </ShDataGrid2>

            <SpRelationDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                relationId={relationId}
                // data={relationData}                
            ></SpRelationDetails>
            <MpAddRelation onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    )
};

export default All;