import { Box } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import AddIcon from '@mui/icons-material/Add';
import WidgetsIcon from '@mui/icons-material/Widgets';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 

const GridActions = (props) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        </Box>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Batches = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const  { t } = useTranslation();

    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    
    

    const columns = [
        {field: "id", headerName: t('batch'),flex:1, renderCell : (cellValues) => {
            return (
                <Box
                
                    sx={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <Text bold>{cellValues.row.id}</Text>
                </Box>
            )
        }},
        {field: "bestBeforeDate", headerName: t('best_before'),flex:1, renderCell : (cellValues) => {
            return (
                <Box
                
                    sx={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <Text semibold>{cellValues.row.bestBeforeDate}</Text>&nbsp;&nbsp;
                    <Text light italic>{cellValues.row.bestBeforeTime}</Text>
                </Box>
            )
        }},
        {field: "stock", headerName: t('stock'),flex:1},
        
        {field: "creationDate", headerName: t('creation_date'),flex:1, renderCell : (cellValues) => {
            return (
                <Box
                
                    sx={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <Text semibold>{cellValues.row.creationDate}</Text>&nbsp;&nbsp;
                    <Text light italic>{cellValues.row.creationTime}</Text>
                </Box>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                return (
                    <Box
                        sx={{
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            paddingX:2,
                            paddingY:1.5,
                            borderRadius:2
                        }}
                    >
                        {cellValues.row.id + " " + cellValues.row.name}
                        <br/>
                        {cellValues.row.age}
                        <br/>
                        {cellValues.row.phone}
                    </Box>
                )
            }
        }
    ];

    const mockDataTeam = [
        {
            id: randomIntFromInterval(0, 99999999),
            bestBeforeDate: "21-05-2023",
            bestBeforeTime: "21:00",
            stock: 179,
            creationDate: "21-05-2023",
            creationTime: "21:00",
            phoneView: {}
        },
        {
            id: randomIntFromInterval(0, 99999999),
            bestBeforeDate: "21-05-2023",
            bestBeforeTime: "21:00",
            stock: 179,
            creationDate: "21-05-2023",
            creationTime: "21:00",
            phoneView: {}
        },
    ];
    
    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                gridActions={<GridActions></GridActions>} 
                title="Overview" 
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                rows={mockDataTeam}>
            </ShDataGrid2>
        </Box>
    );
}
export default Batches;