import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";

import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from 'react-i18next';
import Details from "./Views/Details";
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import Locations from "./Views/Locations";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spPickingCartDetailsConfig } from "./spPickingCartDetailsConfig";
import { useFindOnePickupCartQuery } from "../../../../newapi/warehouse/pickupCartSlice";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpPickingCartDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;

    const { data: pickupcartData, isLoading,isFetching,isError } = useFindOnePickupCartQuery(props.pickingCartId,
    {
        skip: !props.pickingCartId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ShoppingCartCheckoutOutlinedIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t("zone")} /> },
        { component: <ShRowSkeleton heading={t("reachtype")} /> },
        { component: <ShRowSkeleton heading={t("capacity")} /> },
    ], [t]);

    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        options: defaultOptions,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon, defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.pickingCartId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && pickupcartData) {
            setPanel(prevPanel => ({ 
                ...prevPanel,
                title: pickupcartData.barcode,
                subTitle: `${pickupcartData.pickupCartLocations?.length ?? 0} ${t("locations")}`,
                icon :defaultIcon,
                options: [
                    {
                        heading: t('zone'),
                        content: pickupcartData.warehouseZone?.description ?? t("unknown")
                    },
                    {
                        heading: t('reachtype'),
                        content: pickupcartData.reachType?.description ?? t("unknown")
                    },
                    {
                        heading: t('capacity'),
                        content: pickupcartData.capacity ?? 0
                    }
                ],
            }));
        }
    }, [pickupcartData, isLoading, isFetching, t,theme.palette.mode]);
    
    return (
        <Sidepanel2
            skeleton={isFetching || isLoading}
            tabConfig={spPickingCartDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spPickingCartDetailsConfig.details} data={pickupcartData} setIsExtended={props.setIsExtended}/>
                    <Locations config={spPickingCartDetailsConfig.locations} data={pickupcartData} /> 
                </>
        </Sidepanel2>
    );
};

export default SpPickingCartDetails;