import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import WidgetsIcon from '@mui/icons-material/Widgets';
import Text from "../../../../theme/text/Text";
import StockOnLocationCard from "../../../../theme/cards/stock/StockOnLocationCard";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import SpRelationDetails from "../../SpRelationDetails/SpRelationDetails";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShDoubleTextSkeleton from "../../../../theme/skeleton/ShDoubleTextSkeleton";
import { useGetRelationInvoiceProductsFromInvoiceProductQuery } from "../../../../../newapi/financial/relationInvoiceProductSlice";
import { useSelector } from "react-redux";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import { general_states } from "../../../../../utils/staticEnums";

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const LinkedProducts = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [isExtended, setIsExtended] = useState(false);
    const [config, setConfig] = useState({"state": general_states.ACTIVE});
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        },false);

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const [relationId, setRelationId] = useState();
    const relationIdRef = useRef(relationId);
    
    const getRelationDetails = (params) => {
        if (!params && params.row) return;
    
        const newRelationId = parseInt(params.row.relation.id);
        // Only update state if the productId has actually changed
        if (relationIdRef.current !== newRelationId) {
            setRelationId(newRelationId);
            relationIdRef.current = newRelationId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (params) => {
        if (!params && params.row) return;
    
        const newProductId = parseInt(params.row.product.id);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    const columns = [
        {
            field: "name", 
            headerName: t('name'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.invoiceProduct.name
            }
        },
        {
            field: "relation", 
            headerName: t('relation'),
            flex:1,
            renderCell : (params) => {
                if (params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <Text bold clickable={true} variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.relation.name}</Text>
                )
            }
        },
        {
            field: "price", 
            headerName: t('price'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.price
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return <ShTextSkeleton/>
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetRelationInvoiceProductsFromInvoiceProductQuery}
                config={config}
                itemId={props.invoiceProductId}
                // gridActions={<GridActions></GridActions>} 
                title={t('linked_to_relations')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                onRowClick={getRelationDetails}
                columns={columns}>
            </ShDataGrid2>
            <SpRelationDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                relationId={relationId}
            ></SpRelationDetails>
        </Box>
    );
}

export default LinkedProducts;