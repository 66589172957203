import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import WidgetsIcon from '@mui/icons-material/Widgets';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import { useGetWarehouseZonesQuery, useDeleteWarehouseZoneMutation } from "../../../../../newapi/warehouse/warehouseApiSlice";
import AddIcon from '@mui/icons-material/Add';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import DeleteIcon from '@mui/icons-material/Delete';
import { showToastMessageDeleteRequest } from "../../../../../utils/toasts";
import MAddWarehouseZone from "../../../Modals/MAddWarehouseZone";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('add_zone')}
            </Shbutton>
        </ButtonGroup>
        // <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
        //     <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        // </Box>
    )
}

const WarehouseZones = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [isExtended, setIsExtended] = useState(false);
    const [config, setConfig] = useState(props.warehouseId);

    const [view, setView] = useState({
        title: 'zones',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
        apiRef: props.apiRef,
        mobileActions: <GridActions />,
        gridOptions:gridOptions,
    });
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    const [DeleteWarehouseZone,{syncIsLoading}] = useDeleteWarehouseZoneMutation();
    const handleDeleteWarehouseZone = async (id) => {
        try {
            const response = await DeleteWarehouseZone({id: id, warehouseId: props.warehouseId}).unwrap();
            showToastMessageDeleteRequest(t, response);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>,
            gridOptions:gridOptions,
        },);

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);


    const columns = [
        {
            field: "description", 
            headerName: t('description'),
            flex:2, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.description
            }
        },
        {
            field: "multiproduct", 
            headerName: t('multiproduct'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                if (params.row.multiproduct) {
                    return <CheckIcon/>;
                } else {
                    return <CloseIcon/>;
                }
            }
        },
        {
            field: "multibatch", 
            headerName: t('multibatch'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                if (params.row.multibatch) {
                    return <CheckIcon/>;
                } else {
                    return <CloseIcon/>;
                }
            }
        },
        {
            field: "actions", 
            headerName: "",
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return <IconButton type="button" onClick={() => handleDeleteWarehouseZone(params.row.id)}
                sx={{ 
                    color: colors.red[400]
                }}>
        <DeleteIcon/>
    </IconButton>
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetWarehouseZonesQuery}
                itemId={props.warehouseId}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t('zones')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                rows={data}>
            </ShDataGrid2>
            <MAddWarehouseZone warehouseId={props.warehouseId} onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}

export default WarehouseZones;