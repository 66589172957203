import { Box, Button, IconButton, InputBase, TextField, Typography, useTheme } from "@mui/material";
import { Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useAuthHeader, useSignIn } from "react-auth-kit";
import axios, { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../../components/theme/text/Heading";
import Text from "../../components/theme/text/Text";
import Shbutton from "../../components/theme/buttons/Shbutton";

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';

import { tokens } from "../../theme";
import Subheading from "../../components/theme/text/Subheading";
import cx from 'classnames';
import { useLoginMutation } from "../../newapi/user/authentication/authApiSlice";
import { useTranslation } from "react-i18next";
import { selectCustomers, selectRelations } from "../../newapi/user/authentication/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { resetSideBar, setSelectedEnvironment } from "../../newapi/global/settingsSlice";
import { ENV_TYPES } from "../../components/global/Sidebar";
import {persistor} from "../../newapi/store";
import { ConstructionOutlined, Warning } from "@mui/icons-material";
import { useResetPasswordMutation, useVerifyResetCodeMutation } from "../../newapi/user/passwordresetSlice";
// import { login } from "../../api/global/user";
// import { useAuth } from "../../authentication/AuthState";
const initialValues = {
    password: "",
    passwordAgain: "",
}

const userSchema = yup.object().shape({
    password: yup.string().required("required"),
    passwordAgain: yup.string().required("required"),
    // passwordAgain: yup.string()
    //    .oneOf([yup.ref('password'), null], 'Passwords must match')
    //    .required('Password confirmation is required'),
});

const ResetPassword = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    // const signIn = useSignIn();
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [passwordType, setPasswordType] = useState("password");
    const [resetCodeData, setResetCodeData] = useState({});

    useEffect(() => {
        async function fetchData() {
        const queryParams = new URLSearchParams(window.location.search);
        const resetCode = queryParams.get('code');
        if(!resetCode){
            navigate("/login");
            return;
        }
        if(resetCode){
            const result = await verifyResetCode(resetCode);
            setResetCodeData(result.data);
        }
        }
        fetchData();
    },[]);

    

    // useLoginMutation
    const [ResetPasswordMut, { isSuccess, isLoading,isUninitialized, isError,error }] = useResetPasswordMutation();
    const [verifyResetCode, { isSuccess: isSuccessVerify, isLoading: isLoadingVerify,isUninitialized: isUninitializedVerify, isError: isErrorVerify,error: errorVerify }] = useVerifyResetCodeMutation();

    const customers = useSelector(selectCustomers);
    const relations = useSelector(selectRelations);


    const dispatch = useDispatch();

    const handleFormSubmit = async (values) => {
        setErrorMessage("");

        let resetVals = {
            passwordAgain: values.passwordAgain, 
            password: values.password,
            code: resetCodeData.code,
            email: resetCodeData.email,
        };

        if(resetVals.password !== resetVals.passwordAgain){
            console.log("passwords do not match");
            setErrorMessage(t("passwords_do_not_match"));
            return;
        }
        if(resetVals.password.length < 8){
            console.log("password is too short");
            setErrorMessage(t("password_too_short"));
            return;
        }
        

        try {
            const res = await ResetPasswordMut(resetVals);
            if(res.data.status === 200){
                navigate("/login");
            }
        } catch (error) {
            navigate("/login");
        }

    }

    useEffect(() => {
        if(isError) { 
            setErrorMessage(t("invalid_reset_code"));
            return;
        }
        

        if(isSuccess) {
           //Do we log them in? idk

            // navigate("/warehouse/stock");
        }
    },[isSuccess, isError]);

    useEffect(() => {
        if(!isUninitializedVerify && !isLoadingVerify){
            if(isErrorVerify){
                navigate("/login");
            }
            
        }
    },[isErrorVerify]);

    

    return (
        <Box width={1} 
            sx={{
                overflow:"hidden",
                background:"#fff",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                flexDirection:"column",
                background:"#E5EEFF",
                "@media screen and (max-width: 47.9375em)" : {
                    alignItems:"flex-end"
                }
            }}
        >
            <Box
                sx={{
                    // width:"min-content",
                    padding:4,
                    borderRadius:6,
                    background:"white",
                    display:"flex",
                    flexDirection:"column",
                    justifyContent:"center",
                    alignItems:"center",
                    position:"relative",
                    boxShadow:"0px 4px 8px rgba(49,65,95,.25)",
                    paddingTop:5,

                    
                }}
            >

            <Box
                sx={{
                    width:64,
                    height:64,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius:4,
                    mb:4,
                }}
            >

                <img src={require('../../styles/img/logo.png')} alt="Dashboard" style={{ maxWidth: '100%', maxHeight: '100%',borderRadius:16, }} />
            </Box>
                
                <Typography variant="h1" fontWeight="1000" fontFamily="Roboto, sans-serif"
                    sx={{
                        color:"#31415f",
                        textOverflow:"ellipsis",
                        whiteSpace:"nowrap",
                        overflow:"hidden",
                        fontSize:"32px",
                        "@media screen and (max-width: 47.9375em)" : {
                            fontSize:21
                        }
                    }}
                >
                    {t("set_new_password")}
                </Typography>
                <Typography variant="p" fontWeight="400" fontFamily="Roboto, sans-serif"
                    sx={{
                        color:"#88869D",
                        textOverflow:"ellipsis",
                        whiteSpace:"nowrap",
                        overflow:"hidden",
                        fontSize:"17px",
                        marginBottom:4,
                        "@media screen and (max-width: 47.9375em)" : {
                            fontSize:14,
                            marginBottom:3,
                        }
                    }}
                >
                    {t("set_new_password_description")}
                </Typography>
                {/* <Heading>Nieuwe gebruiker</Heading> */}
                {/* <Text light>Nieuwe gebruikersprofiel</Text> */}
                {/* <Header title="NIEUWE GEBRUIKER" subtitle="Nieuwe gebruikersprofiel"></Header> */}
                <Box 
                    sx={{
                        // background:"white",
                        // padding:3,
                        // borderRadius:4,
                    }}
                >
                    {errorMessage && (<Box 
                                    display="flex"
                                    sx={{
                                        width:"450px",
                                        borderRadius:6,
                                        display:"flex",
                                        alignItems:"center",
                                        marginBottom:2,
                                        background:`${colors.red[300]}`,
                                        boxShadow:"0px 2px 4px rgba(49,65,95,.15)",
                                        border:"1px solid #ced7e1",
                                        height:64,
                                        pl:2,
                                        pr:2,
                                        color:"white",
                                        "@media screen and (max-width: 64em)" : {
                                            height:40,
                                            borderRadius:5,
                                            px:1,
                                        },
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:1,
                                        }
                                        
                                    }}

                                >
                                    <Box
                                        sx={{
                                            height:32,
                                            width:32,
                                            textAlign:"center",
                                            background: tokens("light").white[300],
                                            color: tokens("light").red[400],
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                            borderRadius:16,
                                            mr:2,
                                        }}
                                    >
                                        <Warning
                                        fontSize="small"
                                            // color={!!touched.email && !!errors.email ? "red" : "primaryGrey"}
                                        
                                        />

                                    </Box>

                                    <Box >
                                        <Text sx={{color:"white"}} >{errorMessage}</Text>
                                        
                                    </Box>
                                   
                                </Box>)}
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={userSchema}>
                        {({values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                            <form className="login-form" onSubmit={handleSubmit}>
                                <Box 
                                    display="flex"
                                    sx={{
                                        width:"450px",
                                        borderRadius:6,
                                        display:"flex",
                                        alignItems:"center",
                                        // background:"#ecf0f5",
                                        boxShadow:"0px 2px 4px rgba(49,65,95,.15)",
                                        border:"1px solid #ced7e1",
                                        height:64,
                                        pl:2,
                                        pr:2,
                                        "&.error": {
                                            background:colors.red[100],
                                            border: `1px solid ${colors.red[400]}`,
                                            color: colors.red[400]
                                        },
                                        "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active" : {
                                            backgroundClip:"#31415f",
                                            "webkit-text-fill-color":"#31415f",
                                            transition: "background-color 5000s ease-in-out 0s",
                                            boxShadow:"inset 0 0 20px 20px #ecf0f5",
                                            WebkitTextFillColor: "#31415f",
                                            fontSize:16
                                        },
                                        "@media screen and (max-width: 64em)" : {
                                            height:40,
                                            borderRadius:5,
                                            px:1,
                                        },
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:1,
                                        }
                                    }}

                                    className={cx({
                                        'error': !!touched.email && !!errors.email
                                    })}
                                >
                                    <Box
                                        sx={{
                                            height:32,
                                            width:32,
                                            textAlign:"center",
                                            background: tokens("light").primary[100],
                                            color: tokens("light").primary[400],
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                            borderRadius:16,
                                            mr:2,
                                        }}
                                    >
                                        <MailOutlineIcon
                                        fontSize="small"
                                            // color={!!touched.email && !!errors.email ? "red" : "primaryGrey"}
                                        
                                        />

                                    </Box>

                                    <Box >
                                        <Text bold >{resetCodeData?.name}</Text>
                                        <br/>
                                        <Text light>{resetCodeData?.email}</Text>
                                    </Box>
                                   
                                </Box>


                                <Box 
                                    sx={{
                                        width:"450px",
                                        marginTop:2,
                                        width:"450px",
                                        borderRadius:6,
                                        display:"flex",
                                        alignItems:"center",
                                        background:"#ecf0f5",
                                        border:"1px solid rgba(255,255,255,.15)",
                                        height:48,
                                        px:2,
                                        "&.error": {
                                            background:colors.red[100],
                                            border: `1px solid ${colors.red[400]}`,
                                            color: colors.red[400]
                                        },
                                        "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active" : {
                                            backgroundClip:"#31415f",
                                            "webkit-text-fill-color":"#31415f",
                                            transition: "background-color 5000s ease-in-out 0s",
                                            boxShadow:"inset 0 0 20px 20px #ecf0f5",
                                            WebkitTextFillColor: "#31415f",
                                            fontSize:16
                                        },
                                        "@media screen and (max-width: 64em)" : {
                                            height:40,
                                            borderRadius:5,
                                            px:1,
                                        },
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:1,
                                            marginTop:1.5,
                                        }
                                    }}

                                    className={cx({
                                        'error': !!touched.password && !!errors.password
                                    })}
                                >
                                    <Box
                                        sx={{
                                            height:32,
                                            width:32,
                                            textAlign:"center",
                                            color: "rgba(49,65,95,.55)",
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                        }}
                                    >
                                        <LockIcon
                                            // color={!!touched.password && !!errors.password ? "red" : "primaryGrey"}
                                        
                                        />


                                    </Box>
                                    <InputBase 
                                        type={passwordType}
                                        sx={{
                                            pl:1.5,
                                            flex:1,
                                            color:"#31415f",
                                            fontSize:16
                                        }} 
                                        placeholder={t("password") }
                                        value={values.password}
                                        onBlur={handleBlur}
                                        onChange={(e, event) => {
                                            handleChange({ ...event, target: { name: 'password', value: e.target.value } })
                                        }}
                                        // onChange={(e) => console.log(e.target.value)}
                                        // error={!!touched.password && !!errors.password}
                                        
                                        />
                                        <IconButton 
                                            tabIndex={-1} 
                                            type="button" 
                                            sx={{
                                                p: 1,
                                                color:"#31415f"
                                            }} 
                                            onClick={() => setPasswordType((prev) => (prev === "password" ? "text" : "password"))}
                                        >
                                            {passwordType === 'password' ? (
                                                // <DarkModeOutlinedIcon />
                                                <VisibilityIcon/>
                                            ) : (
                                                // <LightModeOutlinedIcon />
                                                <VisibilityOffIcon />
                                            )}
                                            {/* <PersonOutlineIcon color={!!touched.password && !!errors.password ? "red" : "primaryGrey"} /> */}
                                        </IconButton>
                                </Box>

                                <Box 
                                    sx={{
                                        width:"450px",
                                        marginTop:2,
                                        width:"450px",
                                        borderRadius:6,
                                        display:"flex",
                                        alignItems:"center",
                                        background:"#ecf0f5",
                                        border:"1px solid rgba(255,255,255,.15)",
                                        height:48,
                                        px:2,
                                        "&.error": {
                                            background:colors.red[100],
                                            border: `1px solid ${colors.red[400]}`,
                                            color: colors.red[400]
                                        },
                                        "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active" : {
                                            backgroundClip:"#31415f",
                                            "webkit-text-fill-color":"#31415f",
                                            transition: "background-color 5000s ease-in-out 0s",
                                            boxShadow:"inset 0 0 20px 20px #ecf0f5",
                                            WebkitTextFillColor: "#31415f",
                                            fontSize:16
                                        },
                                        "@media screen and (max-width: 64em)" : {
                                            height:40,
                                            borderRadius:5,
                                            px:1,
                                        },
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:1,
                                            marginTop:1.5,
                                        }
                                    }}

                                    className={cx({
                                        'error': !!touched.passwordAgain && !!errors.passwordAgain
                                    })}
                                >
                                    <Box
                                        sx={{
                                            height:32,
                                            width:32,
                                            textAlign:"center",
                                            color: "rgba(49,65,95,.55)",
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                        }}
                                    >
                                        <LockIcon
                                            // color={!!touched.password && !!errors.password ? "red" : "primaryGrey"}
                                        
                                        />

                                    </Box>
                                    <InputBase 
                                        type={passwordType}
                                        sx={{
                                            pl:1.5,
                                            flex:1,
                                            color:"#31415f",
                                            fontSize:16
                                        }} 
                                        placeholder={t("password_again") }
                                        value={values.passwordAgain}
                                        onBlur={handleBlur}
                                        onChange={(e, event) => {
                                            handleChange({ ...event, target: { name: 'passwordAgain', value: e.target.value } })
                                        }}
                                        // onChange={(e) => console.log(e.target.value)}
                                        // error={!!touched.password && !!errors.password}
                                        
                                    />
                                        <IconButton 
                                            tabIndex={-1} 
                                            type="button" 
                                            sx={{
                                                p: 1,
                                                color:"#31415f"
                                            }} 
                                            onClick={() => setPasswordType((prev) => (prev === "password" ? "text" : "password"))}
                                        >
                                            {passwordType === 'password' ? (
                                                // <DarkModeOutlinedIcon />
                                                <VisibilityIcon/>
                                            ) : (
                                                // <LightModeOutlinedIcon />
                                                <VisibilityOffIcon />
                                            )}
                                            {/* <PersonOutlineIcon color={!!touched.password && !!errors.password ? "red" : "primaryGrey"} /> */}
                                        </IconButton>
                                </Box>

                                {/* <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                label="Wachtwoord"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                name="password"
                                error={!!touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                /> */}
                                <Box
                                
                                    sx={{
                                        display:"flex",
                                        justifyContent:"flex-end",
                                        marginTop:2,
                                        width:1,
                                        "@media screen and (max-width: 47.9375em)" : {
                                            marginTop:4
                                        }

                                    }}
                                >
                                    <Shbutton type="submit" block color="primary" variant="contained">
                                        {t("confirm")}
                                    </Shbutton>
                                    {/* <Button type="submit" color="primary" variant="contained">
                                        Create new user
                                    </Button> */}
                                </Box>
                                <Box
                                
                                    sx={{
                                        display:"flex",
                                        justifyContent:"flex-end",
                                        marginTop:2,
                                        width:1,
                                        "@media screen and (max-width: 47.9375em)" : {
                                            marginTop:4
                                        }

                                    }}
                                >
                                    <Shbutton link="/login" block color="primaryGrey" variant="text">
                                        {t("back_to_login")}
                                    </Shbutton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Box>

            {/* <Box
                sx={{
                    flex:1,
                    position:"absolute",
                    background:"orange",
                    overflow:"hidden",
                    top:"80%",
                    "& img" : {
                        // width:"100%",
                        height:1,
                        zIndex:2,
                        position:"relative",
                    },
                    "&:after" : {
                        content:'""',
                        display:"block",
                        filter:"blur(300px)",
                        background:"rgba(141,181,255,.45)",
                        position:"absolute",
                        height:"50%",
                        width:"60%",
                        left:"-40%",
                        top:"25%",
                        zIndex:0
                    },
                    "@media screen and (max-width: 85.5em)" : {
                        display:"none",
                    }
                }}
            >
                <img src={require('../../styles/img/dashboard.png')} alt="Dashboard" />
            </Box> */}
        </Box>
    )
}

export default ResetPassword;