import { Box, useTheme } from "@mui/material";
import Header from "../../../Header"
import Icon from "../../../theme/icons/Icon";
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import Heading from "../../../theme/text/Heading";
import Subheading from "../../../theme/text/Subheading";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import Inbound from "./Views/Inbound";
import Return from "./Views/Return";
import Logs from "./Views/Logs";

import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import {useTranslation} from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../utils";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spReturnDetailsConfig } from "./spReturnDetailsConfig";
import { ReactComponent as ReturnPackage } from "../../../../styles/svg/package-return.svg";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import { useGetReturnQuery } from "../../../../newapi/inbound/returnsSlice";

const SidePanelActions = (props) => {
    const { t } = useTranslation();
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                {t("close")}
            </Shbutton>
            {/* <Shbutton 
                onClick={() => props.setIsExtended(false)} 
                color="primaryGrey" 
                variant="contained" 
                startIcon={<ModeEditOutlinedIcon/>}
            >
                {t("edit")}
            </Shbutton>
            <Shbutton 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="contained" 
                startIcon={<PictureAsPdfOutlinedIcon/>}
            >
                {t("upload_packing_list")}
            </Shbutton> */}

        </SidepanelHeaderActiongroup>
    )
}

// const HeaderDateBox = (props) => {
//     const [from, setFrom] = useState({});
//     const [to, setTo] =  useState({});
    
//     useEffect(() => {
//         setFrom(getTranslatedDateAndTime(props.data.order?.date_sent, props.t));
//         setTo(getTranslatedDateAndTime(props.data.createdAt, props.t));
//     },[props.data])

//     return (
//         <>
//             {/* <Text light>Date</Text> */}
//             <Box display={"flex"} alignItems={"center"}>
//                 <Box width={66} marginRight={1}><Text light>Bestelling</Text></Box>
//                 <Box marginRight={1}><Text bold>{from?.date}</Text></Box>
//                 <Text>{from?.time}</Text>
//             </Box>
//             <Box  display={"flex"} alignItems={"center"}>
//                 <Box width={66}marginRight={1}><Text light>Retour</Text></Box>
//                 <Box marginRight={1}><Text bold>{to?.date}</Text></Box>
//                 <Text>{to?.time}</Text>
//             </Box>
//         </>
//     )

// }


const HeaderDateBox = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [from, setFrom] = useState({});
    const [to, setTo] =  useState({});
    
    useEffect(() => {
        setFrom(getTranslatedDateAndTime(props.data?.order?.date_sent, props.t));
        setTo(getTranslatedDateAndTime(props.data?.createdAt, props.t));
    },[props.data])

    

    return (
        <>
            {/* <Text light>Date</Text> */}
            <Box display={"flex"} alignItems={"center"} width={210}>
                <Box 
                    sx={{
                        width:66,
                        flexShrink:0,
                        mr:1,
                    }}
                >
                    <Text light>Scheduled</Text>
                </Box>
                {props.skeleton ?
                    <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
                : 
                    <>
                        <Box marginRight={1}><Text bold>{from?.date}</Text></Box>
                        <Text>{from?.time}</Text>
                    </>
                }
            </Box>
            <Box  display={"flex"} alignItems={"center"} width={210}>
                
                <Box 
                    sx={{
                        width:66,
                        flexShrink:0,
                        mr:1,
                    }}
                >
                    <Text light>Shipped</Text>
                </Box>
                {props.skeleton ?
                    <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
                : 
                    <>
                        <Box marginRight={1}><Text bold>{to?.date}</Text></Box>
                        <Text>{to?.time}</Text>
                    </>
                }
            </Box>
                
        </>
    )

}

const getReturnStatus = (data, t)  => {
    let status = data.state;
    let labelcolor = "";
    let labeltext = "";
    switch(status){
        case "announced":
            labelcolor = "blue"
            labeltext = t('announced');
            break;
        case "approved":
            labelcolor = "green"
            labeltext = t('approved');
            break;
        case "declined":
            labelcolor = "red"
            labeltext = t('declined');
            break;
        case "cancelled":
            labelcolor = "grey"
            labeltext = t('cancelled');
            break;
        case "receivedwithoutnotice":
            labelcolor = "purple"
            labeltext = t('receivedwithoutnotice');
            break;
        default:
            labelcolor = "orange"
            labeltext = t('unknown');
            break;
    }
    return {text: labeltext, color: labelcolor};
}

const SpReturnDetails = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data } = props;

    const { t, ready, i18n } = useTranslation();

    const { data: returnData, isLoading,isFetching,isError } = useGetReturnQuery(props.returnId,
    {
        skip: !props.returnId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ReturnPackage/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t("order")} /> },
        { component: <ShRowSkeleton heading={t("reference")} /> },
        { component: <ShRowSkeleton heading={t("name")} /> },
        { component: <ShRowSkeleton heading={t("phone")} /> },
        { component: <ShRowSkeleton heading={t("email")} /> },
        { component: <ShRowSkeleton heading={t("relation")} /> },
        { component: <HeaderDateBox skeleton t={i18n}/> },
        { component: <ShRowSkeleton heading={t("warehouse")} /> },
        { component: <ShRowSkeleton heading={t("sku_amount")} /> },
        { component: <ShRowSkeleton heading={t("items")} /> },
    ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon,defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.returnId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && returnData) {
            setPanel(prevPanel => ({
                ...prevPanel,
                title: `${t("order")} ${returnData.order?.webshopreference}`,
                subTitle: returnData.reference ?? t("reference_missing"),
                // icon :{
                //     theme: "orange", 
                //     content: <Inventory2Icon/>
                // },
                options: [
                    {
                        heading: t("order"),
                        content: returnData.order?.webshopreference
                    },
                    {
                        heading: t("reference"),
                        content: returnData.reference
                    },
                    {
                        heading: t("name"),
                        content: returnData.name
                    },
                    {
                        heading: t("phone"),
                        content: returnData.phone
                    },
                    {
                        heading: t("email"),
                        content: returnData.email
                    },
                    {
                        heading: t("relation"),
                        content: returnData.relation?.name
                    },
                    {
                        component: <HeaderDateBox data={returnData} t={i18n}/>
                    },
                    {
                        heading: t("warehouse"),
                        content: returnData.warehouse?.name
                    },
                    {
                        heading: t("sku_amount"),
                        content: returnData.skuCount
                    },
                    {
                        heading: t("items"),
                        content: returnData.itemCount
                    }
                ],
            }));
        }
    }, [returnData, isLoading,isFetching, t,theme.palette.mode]);

    // const [panel, setPanel] = useState({
    //     title: "",
    //     subTitle: "",
    //     icon :{
    //         theme: "green",
    //         content: <Inventory2Icon/>
    //     },
    //     //Heading row for default shit
    //     options : [],
    //     actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    // });

    // useEffect(() => {
    //     if (typeof data !== "undefined" && ready) {
    //         //const { text, color } = getReturnStatus(props.data, t);
    //         setPanel((prevPanel) => ({
    //         ...prevPanel,
    //         title: `${t("order")} ${returnData.order?.webshopreference}`,
    //         subTitle: returnData.reference ?? t("reference_missing"),
    //         icon :{
    //             theme: "orange", 
    //             content: <Inventory2Icon/>
    //         },
    //         options : [
    //             {
    //                 heading: t("order"),
    //                 content: returnData.order?.webshopreference
    //             },
    //             {
    //                 heading: t("reference"),
    //                 content: returnData.reference
    //             },
    //             {
    //                 heading: t("name"),
    //                 content: returnData.name
    //             },
    //             {
    //                 heading: t("phone"),
    //                 content: returnData.phone
    //             },
    //             {
    //                 heading: t("email"),
    //                 content: returnData.email
    //             },
    //             {
    //                 heading: t("relation"),
    //                 content: returnData.relation?.name
    //             },
    //             {
    //                 component: <HeaderDateBox data={props.data} t={i18n}/>
    //             },
    //             {
    //                 heading: t("warehouse"),
    //                 content: returnData.warehouse?.name
    //             },
    //             {
    //                 heading: t("sku_amount"),
    //                 content: returnData.skuCount
    //             },
    //             {
    //                 heading: t("items"),
    //                 content: returnData.itemCount
    //             }
    //         ],
    //       }));
    //     }
    //   }, [data,ready]);

   
    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spReturnDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} >
                <>
                    <Return config={spReturnDetailsConfig.return} data={returnData}/>
                    <Inbound config={spReturnDetailsConfig.inbound} data={returnData}/>
                    <Logs config={spReturnDetailsConfig.logs} data={returnData}/> 
                </>
        </Sidepanel2>
    );
};

export default SpReturnDetails;
