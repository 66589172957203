import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";
import Text from "../../../../theme/text/Text";

import { useTranslation } from "react-i18next";
import SPLocationDetails from "../../SpLocationDetails/SpLocationDetails";
import { useGetStockForProductQuery } from "../../../../../newapi/warehouse/stockSlice";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import ShLabel from "../../../../theme/label/ShLabel";

const GridActions = (props) => {
    return (<></>)
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Warehouse = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [locationIsExtended, setLocationIsExtended] = useState(false);
    // const [data, setData] = useState([]);
    // const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [config,setConfig] = useState({productId: props.productId})

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions />,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const [locationId, setLocationId] = useState();
    const locationIdRef = useRef(locationId);
    
    const getLocationDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.warehouseLocation.id);
        // Only update state if the productId has actually changed
        if (locationIdRef.current !== newProductId) {
            setLocationId(newProductId);
            locationIdRef.current = newProductId; // Update the ref to the new value
        }
        setLocationIsExtended(true);
    };
    

    const columns = [
        {
            field: "location", 
            headerName: t('location'), 
            width:216,
            shFilter : {
                type: "text",
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                const { warehouseLocation } = params.row;
                const iconMap = {
                  bulk: "box",
                  transition: "truck",
                  general: "pick",
                  link: "link",
                };
              
                return (
                    <Box>
                        <Text bold>
                            {warehouseLocation.location}
                        </Text>
                        <br/>
                        <Text light>
                            {warehouseLocation.warehouse?.name}
                        </Text>
                    </Box>
                );
              }
            },
            {
                field: "location_type",
                headerName: t('location_type'),
                flex:1,
                renderCell : (params) => {
                    if(params.row.skeleton) {
                        return <ShTextSkeleton/>
                    }

                    const { warehouseLocation } = params.row;

                    const iconMap = {
                        bulk: "box",
                        transition: "truck",
                        general: "pick",
                        link: "link",
                    };

                    const type = iconMap[warehouseLocation.warehouseLocationType.type] || warehouseLocation.warehouseLocationType.type;

                    const getColor = (icon) => {
                        switch(icon) {
                            case iconMap.bulk :
                                return "pink";
                            case iconMap.transition :
                                return "cyan";
                            case iconMap.general :
                                return theme.palette.mode === "dark" ? "blue" : "primary";

                            case iconMap.link :
                                return "purple";
                            default :
                                return "grey"
                        }
                    }

                    return (
                        <ShLabel size={32} palette={theme.palette.mode === "dark" ? "pastel" : "normal"} variant={getColor(type)}>
                            {type}
                        </ShLabel>
                    )
                },
            },
        {
            field: "administrative",
            headerName: t('administrative'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.available ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        {
            field: "physical",
            headerName: t('physical'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.quantity ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        {
            field: "inOrder",
            headerName: t('in_order'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.inOrder ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        {
            field: "blocked", 
            headerName: t('blocked'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.blocked ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        {
            field: "pickingprocess", 
            headerName: t('in_pick_process'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.pickingprocess ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        {
            field: "reserved",
            headerName: t('reserved'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.reserved ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        // {
        //     field: "pickedUp", 
        //     headerName: t('picked_up'),
        //     flex:1,
        //     renderCell : (params) => {
        //         if(params.row.skeleton) {
        //             return <ShTextSkeleton/>
        //         }
        //         return params.row.available -5
        //     },
        //     shFilter : {
        //         type: "number",
        //     }
        // },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Box
                        sx={{
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            paddingX:2,
                            paddingY:1.5,
                            borderRadius:2
                        }}
                    >
                        {params.row.state + " " + params.row.available}
                        <br/>
                        {params.row.backorder}
                        <br/>
                        {params.row.backorder}
                    </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetStockForProductQuery}
                onRowClick={getLocationDetails}
                config={config}
                gridActions={<GridActions></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}>
            </ShDataGrid2>
            <SPLocationDetails
                isExtended={locationIsExtended}
                setIsExtended={setLocationIsExtended}
                locationId={locationId}
                // data={locationData} // Pass the locationData as a prop
            ></SPLocationDetails>
        </Box>
    );
}
export default Warehouse;