const carrierKeys = [
    "Carriers",
    "CarriersSingle",
    "CarriersSingleBarcode"
];

const countingStrategyKeys = [
    "CountingStrategyTemplates",
    "CountingStrategyTemplatesSingle"
];

const generalKeys = [
    "enums"
];

const globalRelationKeys = [
    "Relations",
    "RelationsSingle",
    "RelationContacts"
];

const globalStreamKeys = [
    "Streams",
    "StreamsSingle"
];

const globalSupplierKeys = [
    "Suppliers",
    "SuppliersSingle"
];

const globalGeneralKeys = [
    "Settings"
];

const inboundKeys = [
    "Inbound",
    "InboundDashboard",
    "InboundsCounts",
    "InboundLines"
];

const logKeys = [
    "InboundLogs",
    "OrderLogs",
    "ProductLogs"
];

const orderKeys = [
    "Orders",
    "OrdersStatusCounts",
    "DashboardOrdersStatusCounts", 
    "OrderLines",
    "OrderColli",
    "OrderMutations",
    "OrderPickingLines"
];

const pickingjobKeys = [
    "PickingJobs",
    "PickingJobsSingle",
    "OrderPickingList",
    "PickingJobPickingLines"
];

const productKeys = [
    "ChildProducts",
    "Products"
];

const productFieldKeys = [
    "ProductFields"
];

const reachTypeKeys = [
    "ReachTypes"
];

const returnKeys = [
    "Returns",
    "ReturnLines"
];

const shipperKeys = [
    "Shippers",
    "ShippersSingle"
];

const stockKeys = [
    "Stock",
    "StockMutations",
];

const userKeys = [
    "Usergroups"
];

const warehouseKeys = [
    "Warehouse",
    "Warehouses",
    "WarehousesSelect",
    "WarehouseLocations",
    "WarehouseZones",
    "WarehouseLevels",
    "WarehouseReplenishOrder"
];

const warehouseLocationTypeKeys = [
    "WarehouseLocationTypes"
];

const webshopKeys = [
    "WebshopCredentials",
    "WebshopCredentialsSingle"
];

const workflowTemplateKeys = [
    "WorkflowTemplates",
    "WorkflowTemplatesSingle"
];

const workflowKeys = [
    "Workflows",
    "WorkflowSingle",
    "WorkflowLogs"
];

export const cacheKeys = [
    ...carrierKeys,
    ...countingStrategyKeys,
    ...generalKeys,
    ...globalRelationKeys,
    ...globalStreamKeys,
    ...globalSupplierKeys,
    ...globalGeneralKeys,
    ...inboundKeys,
    ...logKeys,
    ...orderKeys,
    ...pickingjobKeys,
    ...productKeys,
    ...productFieldKeys,
    ...returnKeys,
    ...reachTypeKeys,
    ...shipperKeys,
    ...stockKeys,
    ...userKeys,
    ...warehouseKeys,
    ...warehouseLocationTypeKeys,
    ...webshopKeys,
    ...workflowTemplateKeys,
    ...workflowKeys
];
