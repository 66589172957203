import React, { useRef, useState,createRef,Component,componentDidMount, forwardRef, useEffect, useContext } from "react";
import { Box, IconButton, Typegraphy, Typography, useTheme, Button } from '@mui/material';
import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
// import { createRef } from "react";
import cx from 'classnames';
import { MenuStateContext } from "../../../contexts/MenuState";

import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import CloseOverlayBtn from "../buttons/CloseOverlayBtn";
import Shbutton from "../buttons/Shbutton";
// import Subheading from "../text/Subheading";
// import Text from "../text/Text";
// import Spheadingrow from "./Spheadingrow";
import Icon from "../icons/Icon";
import ShDataGrid from "../datagrid/ShDataGrid";
import Circle from "../Seperators/Circle";
import Text from "../text/Text";
import Subheading from "../text/Subheading";
import Spheadingrow from "./Spheadingrow";
import ShTextSkeleton from "../skeleton/ShTextSkeleton";
const SidepanelHeader = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const settings = props.settings ?? null;

    const headerRef = useRef(null);

    useEffect(() => {
        props.setHeader(headerRef)
    },[headerRef])

    return (
        <Box
        ref={headerRef}
            sx={{
                display:"flex",
                flexDirection:"column",
                padding:3,
                paddingBottom:settings?.paddingBottom ?? 4,
                background:settings?.background ?? `${colors.bg["tertiary"]}`,
                "@media screen and (max-width: 63.9375em)":{
                    paddingBottom:3
                }
            }}
        >
            {props.isCustom ? 
                props.children
                :
                
                <Box display="flex" alignItems={typeof props.content?.options !== "undefined" && props.content?.options.length > 0  ? "flex-start" : "center"}
                sx={{
                    overflow:"hidden",
                    "@media screen and (max-width: 63.9375em)":{
                        flexDirection:"column",
                        alignItems:"center",
                        justifyContent:"center",
                        width:"100%"
                    }
                }}>
                    {typeof props.content.beforeChild !== "undefined" ? props.content.beforeChild : null}
                    <Icon 
                        theme={props.content.icon.theme ? props.content.icon.theme : null} 
                        color={props.content.icon.color ? props.content.icon.color : null} 
                        // color={props.content.icon.color} // This sets the color prop directly
                    >

{props.content.icon.content}
                    </Icon>
                    {/* <Icon theme={props.content.icon.theme}>
                        {props.content.icon.content}
                    </Icon> */}
                    <Box 
                        sx={{
                            textOverflow:"ellipsis",
                            whiteSpace:"nowrap",
                            overflow:"hidden",
                            flex:1,
                            "@media screen and (max-width: 63.9375em)":{
                                pl:0,
                                width:"100%",
                                mt: (typeof props.content?.options !== "undefined" && props.content?.options.length > 0) ? 3 : 2,
                            }
                        }} 
                        pl={2} 
                        display="flex" 
                        flexDirection="column">
                        <Box  
                            display="flex" 
                            justifyContent="space-between"
                            sx={{
                                "@media screen and (max-width: 63.9375em)":{
                                    textAlign:"center",
                                    pl:0,
                                    overflow:"hidden"
                                }
                            }}>
                            <Box
                                sx={{
                                    minWidth:300,
                                    "@media screen and (max-width: 63.9375em)":{
                                        width:"100%",
                                        textAlign:"center",
                                    }
                                }}>
                                <Subheading>
                                    {props.skeleton ?
                                        <ShTextSkeleton waveColor={theme.palette.mode === "dark" ? "rgba(255,255,255,.25)": "rgba(255,255,255,.25)"} color={theme.palette.mode === "dark" ? colors.txt['secondary'] : colors.grey[300]}/>
                                    :
                                        props.content.title
                                    }
                                </Subheading>
                                <Box display={"flex"} alignItems={"center"}
                                    sx={{
                                        "@media screen and (max-width: 1023px)" : {
                                            justifyContent:"center"
                                        }
                                    }}
                                >
                                    {props.skeleton ?
                                        <ShTextSkeleton sx={{mt:1,}} color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]}/>
                                    : 
                                        <>
                                        {typeof props.content.subTitle !== "undefined" ?
                                            typeof props.content.subTitle === "object" ?
                                                props.content.subTitle.map((title, index) => (
                                                    <React.Fragment key={index}>
                                                        <Text light>{title}</Text>
                                                        {index !== props.content.subTitle.length-1 ?
                                                            <Circle/>
                                                        :null}
                                                    </ React.Fragment>
                                                    // <Box>Hello</Box>
                                                ))
                                            : <Text light>{props.content.subTitle}</Text>
                                        :null}
                                        </>  
                                    }
                                </Box>
                                {/* {typeof props.content.subTitle !== "undefined" ? <Text light>{props.content.subTitle}</Text> : null} */}
                            </Box>
                            {props.content.actions}
                        </Box>
                        {typeof props.content?.options !== "undefined" && props.content?.options.length > 0  ?
                            <Spheadingrow rowsettings={settings?.row} options={props.content.options}></Spheadingrow>
                        : null}
                    </Box>
                    {typeof props.content.topRight !== "undefined" ? 
                        <Box
                            sx={{
                                position:"absolute",
                                right:0,
                                top:24,
                                height:40,
                                display:"flex",
                                justifyContent:"flex-end",
                                alignItems:"center"
                            }}
                        >
                            {props.content.topRight}
                        </Box>
                    : null}
                </Box>
            }
        </Box>
    );
};

export default SidepanelHeader;