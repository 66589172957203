
import CropFreeIcon from '@mui/icons-material/CropFree';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WidgetsIcon from '@mui/icons-material/Widgets';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const spVirtualProductDetailsConfig = {
    childProductOverview: {
        title: 'products',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
    
    
};
