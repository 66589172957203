import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useCallback, useEffect, useMemo, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import WidgetsIcon from '@mui/icons-material/Widgets';

import { useTranslation } from "react-i18next";
import { showToastMessageDeleteRequest, showToastMessageUpdateRequest} from "../../../../../utils/toasts";
import { useDeleteReachTypeMutation, useUpdateReachTypeMutation } from "../../../../../newapi/warehouse/reachTypeSlice";
import * as yup from "yup";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../../../utils/validation";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [reachTypeInfo, setReachTypeInfo] = useState({
      id: 0,
      description: "",
      type: "",
    });

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        description: yupRequired('description', t)           
    };  

    const schema = yup.object().shape(rules);

    const [UpdateReachType,{isLoading}] = useUpdateReachTypeMutation();
    const handleSaveClick = async () => {
        try {
            setFieldErrors({});
            await schema.validate(reachTypeInfo, { abortEarly: false });
            const response = await UpdateReachType({id: props.data.id, data: reachTypeInfo}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                    ...acc,
                    [currentError.path]: currentError.message
                }), {});
            
                // Set the validation errors in the state
                setFieldErrors(errors);
            } else {
                // Handle other errors, such as network errors
                showToastMessageUpdateRequest(t, {success: false, error: err.error});
            }
        }
    };

    const [DeleteReachType] = useDeleteReachTypeMutation();
    const handleDeleteClick = async () => {
      try {
          const response = await DeleteReachType({id: props.data.id}).unwrap();
          showToastMessageDeleteRequest(t, response);
          props.setIsExtended(false);
      } catch (err) {
          showToastMessageDeleteRequest(t, {success: false, error: err.error});
      }
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions 
                id={reachTypeInfo?.id}
                action={handleSaveClick}
                deleteAction={handleDeleteClick}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[reachTypeInfo,handleSaveClick,handleDeleteClick]);

    

    useEffect(() => {
        setReachTypeInfo(props.data);
    },[props.data]);

    const handleChange = (key, value) => {
        setReachTypeInfo({
          ...reachTypeInfo,
          [key]: value
        });
      };
    
    

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
        <Dropdown title={t("general")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 4,
                        justifyContent:"flex-start"
                    }}
                >
                    <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                flex:1
                            }
                        }}
                    >
                        <Box lineHeight={"40px"} width={"104px"}>
                            <Text>{t("description")}</Text>
                        </Box>
                    </Box>
                    <Box flex={1}>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:0.5,
                                marginBottom:0.5,
                                justifyContent:"space-between",
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <ShValidatedInput
                                name="description"
                                value={reachTypeInfo?.description || ""}
                                onChange={handleChange}
                                error={fieldErrors.description}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dropdown>
        <Box
            sx={{
                position:"absolute",
                bottom:0,
                right:0,
                padding:3,
                width:1,
                background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                    padding:2,
                    paddingY:2.5,
                }
            }}
        >
        <GridActions
            id={reachTypeInfo?.id}
            action={handleSaveClick}
            deleteAction={handleDeleteClick}
        />
            </Box>
        </Box>
    );
}

export default DetailsPanel;