
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import InputIcon from '@mui/icons-material/Input';

export const settingsWarehouseGeneralCoreConfig = {
    future:  {
        title: 'future',
        icon: <UpdateOutlinedIcon />,
    },
    pickBasis:  {
        title: 'pick_basis',
        icon: <AccountTreeOutlinedIcon />,
    },
    palletPicking:  {
        title: 'pallet_picking',
        icon: <ChecklistOutlinedIcon/>,
    },
    inbound:  {
        title: 'inbound',
        icon: <InputIcon />,
    },
    replenish:  {
        title: 'replenish',
        icon: <InputIcon />,
    },
    
};
