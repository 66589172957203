
import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const SHIPPERS_BASE = "/shippers";
const buildUrl = (endpoint) => SHIPPERS_BASE + endpoint;
const ApiName = "Shippers";


const shippersApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getShippers: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}` , identifier: 'datatable'}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getShippers', `${ApiName}`)
        }),
        getShippersSelect: builder.query({
            query: () => ({
                url: buildUrl(`/`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}`, identifier: 'select' }],
        }),
        getShipper: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
        addNewShipper: builder.mutation({
            query: shipperInfo => {
                console.log("shipperInfo",shipperInfo);
                
                return {
                    url: buildUrl(`/new`),
                    method: 'POST',
                    body: shipperInfo,
                }
            },
            invalidatesTags: [`${ApiName}`],
        }),
        editShipper: builder.mutation({
            query: shipperInfo => ({
                url: buildUrl(`/${shipperInfo.id}`),
                method: 'PUT',
                body: shipperInfo,
            }),
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg.id }, {type: `${ApiName}`}],
        }),
        deleteShipper: builder.mutation({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg.id }, {type: `${ApiName}`}],
        }),
    })
});

export const {
    useGetShippersQuery,
    useGetShippersSelectQuery,
    useGetShipperQuery,
    useAddNewShipperMutation,
    useEditShipperMutation,
    useDeleteShipperMutation
  } = shippersApiSlice;