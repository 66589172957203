import { countriesFromLocale } from "../../utils/countries"

export const DND_STREAM_RULE = "STREAM_RULE";
export const DND_STREAM_RULE_LINE = "STREAM_RULE_LINE";

export const getCountries = (language) => countriesFromLocale(language);

export const dropdownKeys = {
    destination: 'destination'
}

export const conditionKeys = [
    'colli',
    'weight',
    'length',
    'width',
    'height',
    'volume',
    'time'
]

export const streamRuleLineKeys = [
    ...conditionKeys,
    ...Object.values(dropdownKeys),
]

export const getStreamRuleKey = (key) => {
    if(streamRuleLineKeys.includes(key)) {
        return key;
    }
    return "";
}

export const conditions = (key) => {
    if(conditionKeys.includes(key)) {
        return [
            'greaterthan',
            'smallerthan',
            'equalto',
            'notequalto',
            'greaterthanorequalto',
            'smallerthanorequalto',
        ]
    }
    return ['equalto','notequalto'];
}

export const getMobileCondition = (condition) => {
    switch(condition) {
        case "greaterthan" : return ">";
        case "smallerthan" : return "<";
        case "equalto" : return "=";
        case "notequalto" : return "!=";
        case "greaterthanorequalto" : return ">=";
        case "smallerthanorequalto" : return "<=";
    }
}

// export const getNominator = (condition) => {
//     switch(condition) {
//         case 'greater_than': return 'greaterthan';
//         case 'smaller_than': return 'smallerthan';
//         case 'equal_to': return 'equalto';
//         case 'not_equal_to': return 'notequalto';
//         case 'greater_than_or_equal_to': return 'greaterthanorequalto';
//         case 'smaller_than_or_equal_to': return 'smallerthanorequalto';
//         default: return 'undefined'
//     }
// }

export const shippers = [
    'dpd',
    'postnl',
    'dhl'
]

export const shipperColors = {
    dpd : {
        base: "#DC0032",
        background: "#FDF2F5",
        streamrule: "#FADCE4",
        streamrulehover: "#F6C6D3",
        streamrulevalue: "#FDF2F5",
        streamrulevaluetxtcolor: "#DC0032",
        streamrulevaluehover: "#F6C6D3",
    },
    postnl: {
        base: "#F56900",
        background: "#FFF8F2",
        streamrule: "#FFE9D6",
        streamrulehover: "#FFDBBD",
        streamrulevalue: "#FFF8F2",
        streamrulevaluetxtcolor: "#F56900",
        streamrulevaluehover: "#FFDBBD",
    },
    dhl: {
        base: "#F56900",
        background: "#FFFCF2",
        streamrule: "#FFF6D6",
        streamrulehover: "#FFF0BD",
        streamrulevalue: "#FFFCF2",
        streamrulevaluetxtcolor: "#CDA300",
        streamrulevaluehover: "#FFF0BD",
    },
    bol: {
        base: "#000099",
        background: "#F2F2FA",
        streamrule: "#DADAF0",
        streamrulehover: "#B5B5E2",
        streamrulevalue: "#F2F2FA",
        streamrulevaluetxtcolor: "#000099",
        streamrulevaluehover: "#B5B5E2",
    }
}

export const getShipperColorSettings = (streamRuleId, shippers) => {
    const shipper = shippers.find(shipper => shipper.id === streamRuleId);
    // console.log("shipper",shipper);
    if (shipper && shipperColors[shipper.type]) {
        return shipperColors[shipper.type];
    }
    return null; // or a default color setting if needed
}
export const getShipperColorSettingsByType = (shipperType) => {
    return shipperColors[shipperType];
}