
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const warehouseFinancialInvoicesConfig = {
    all:  {
        title: 'all_invoices',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
};
