import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import WidgetsIcon from '@mui/icons-material/Widgets';
import Text from "../../../../theme/text/Text";
import StockOnLocationCard from "../../../../theme/cards/stock/StockOnLocationCard";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "channelFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Channels = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [isExtended, setIsExtended] = useState(false);

    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    

    const columns = [
        {
            field: "name", 
            headerName: t('name'),
            flex:1.5, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton variant={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                }
                return (
                    <Box paddingLeft={0} onClick={() => console.log("Open modal edit channel")}>
                        <Text bold clickable={true} variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.name}</Text><br/>
                    </Box>
                )
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:2,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.state
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <StockOnLocationCard skeleton={params.row.skeleton ?? false} {...params}/>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            <ShDataGrid2 key={theme.palette.mode}
                // gridActions={<GridActions></GridActions>} 
                //content={barcodes}
                title={t('channels')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                rows={data}>
            </ShDataGrid2>
        </Box>
    );
}

export default Channels;