// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from '../../../../../theme';
import { useSelector } from 'react-redux';
import { showToastMessage } from '../../../../../utils/toasts';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    
    const [newChildProducts, setNewChildProducts] = useState([{
        sku: "",
        quantity: 0,
        index: 1
    }]);

    const handleChange = (index, key, value) => {
        setNewChildProducts(currentProducts => {
            return currentProducts.map(product => {
                if (product.index === index) {
                    return {
                        ...product,
                        [key]: value
                    };
                }
                return product;
            });
        });

        if (key === "sku") {
            setNewChildProducts(currentProducts => {
                if (value.length > 0) {
                    if (index === currentProducts.length) {
                        return [...currentProducts, {
                            sku: "",
                            quantity: 0,
                            index: currentProducts[currentProducts.length - 1].index + 1
                        }];
                    }
                } else {
                    showToastMessage("error", t("invalid_sku"));
                }
                return currentProducts;
            });
        }
    };
    
    useEffect(() => {
        const mappedChildProducts = newChildProducts
        .filter(x => x.sku.length > 0 && x.quantity > 0)
        .map(x => ({
            sku: x.sku,
            quantity: x.quantity
        }));

        handleInput("childProducts", mappedChildProducts)
    },[newChildProducts]);
      
    const handleDeleleteChildRow = (index) => {
        const updatedChildProducts = newChildProducts
            .filter(product => product.index !== index)
            .map((product, idx) => ({
                ...product,
                index: idx + 1
            }));
        setNewChildProducts(updatedChildProducts);
    }

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
    };

    return (
        <div>
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                {newChildProducts.map((childProduct, index) => (
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap:4,
                        justifyContent:"flex-start"
                    }}
                >
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:1
                        }}
                    >
                        <Box
                            sx={{
                                width:40,
                            }}
                        >
                            <Text light>{t("sku")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32
                            }}
                        >
                            <InputBase 
                                sx={{
                                    px:2,
                                    flex:1, 
                                    color:colors.txt["primary"],
                                    lineHeight:"32px",
                                }}
                                value={childProduct.sku}
                                onChange={(e) => handleChange(childProduct.index, 'sku', e.target.value)}
                                />
                        </Box>
                    </Box>
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:1
                        }}
                    >
                        <Box
                            sx={{
                                width:64,
                            }}
                        >
                            <Text light>{t("quantity")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                width:120,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32
                            }}
                        >
                            <NumberInput btnSize={"24"}
                                value={childProduct.quantity}
                            change={(e) => handleChange(childProduct.index, 'quantity', e)}/>
                        </Box>

                        { newChildProducts.length > 1 ?
                        <>
                        <IconButton type="button" onClick={() => handleDeleleteChildRow(childProduct.index)} sx={{p:1}}>
                            <DeleteIcon/>
                        </IconButton>
                        </> 
                        : null
                        }
                    </Box>
                </Box>
                

                ))}
            </Box>
    </div>
    );
};

const ChildProducts = (props) => {
    const {t} = useTranslation();
    return (
        <ProgressionTab 
        tabId="tabChildProducts" 
        subtitle={t("products")} 
        step={2}
        maxSteps={2}
    >
        <Content />
    </ProgressionTab>
    )
}

export default ChildProducts;

