// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const colors = tokens(theme.palette.mode);

    const data = {...props.data};

    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.5,
                    "@media screen and (max-width: 47.9375em)": {
                        flexDirection: "row",
                        gap: 5,
                        alignItems: "center",
                        width: 1
                    }
                }}
            >
                <Box
                    sx={{
                        "@media screen and (max-width: 47.9375em)": {
                            width: 80
                        }
                    }}
                >
                    <Text>{t("name")}</Text>
                </Box>
                <Box
                    sx={{
                        "@media screen and (max-width: 47.9375em)": {
                            flex: 1
                        }
                    }}
                >
                    <Box display="flex"
                        borderRadius="20px"
                        sx={{
                            marginTop: 0.5,
                            
                        }}
                    >
                        <ShValidatedInput
                            name="name"
                            value={data.name ?? ""}
                            onChange={handleChange}
                            error={props.errors.name}
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: 2,
                    gap: 0.5,
                    "@media screen and (max-width: 47.9375em)": {
                        flexDirection: "row",
                        gap: 5,
                        alignItems: "center",
                        width: 1
                    }
                }}
            >
                <Box
                    sx={{
                        "@media screen and (max-width: 47.9375em)": {
                            width: 80
                        }
                    }}
                >
                    <Text>{t("short_name")}</Text>
                </Box>
                <Box
                    sx={{
                        "@media screen and (max-width: 47.9375em)": {
                            flex: 1
                        }
                    }}
                >
                    <Box display="flex"
                        borderRadius="20px"
                        sx={{
                            marginTop: 0.5,
                            
                        }}
                    >
                        <ShValidatedInput
                            name="short_name"
                            value={data.short_name ?? ""}
                            onChange={handleChange}
                            error={props.errors.short_name}
                        />
                        
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const StreamBasicInfo = (props) => {
    const { t } = useTranslation();

    const schema = yup.object({
        name: yupRequired('name',t),
        short_name: yupRequired('short_name',t)
    });

    return (
        <ProgressionTab
            tabId="tabBasicInfo"
            subtitle={"basic"}
            step={1}
            nextTab={"tabMeasurements"}
            maxSteps={4}
            schema={schema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default StreamBasicInfo;

