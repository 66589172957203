import { Box, IconButton, InputBase, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Text from "../../../../theme/text/Text";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import cx from "classnames"
import NumberInput from "../../../../theme/inputs/NumberInput";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import { useTranslation } from "react-i18next";
import { useUpdateProductMutation } from "../../../../../newapi/warehouse/productSlice";
import { useGetWorkflowTemplatesSelectQuery } from "../../../../../newapi/workflow/workflowTemplateSlice";
import { useGetEnumsQuery } from "../../../../../newapi/global/generalApiSlice";
import * as yup from "yup";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import { yupRequired } from "../../../../../utils/validation";
import ShValidatedNumberInput from "../../../../theme/inputs/ShValidatedNumberInput";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
        <Shbutton
            className={"group-btn"}
            onClick={props.action}
            color={theme.palette.mode === "dark" ? "blue" : "primary"}
            variant="contained"
            endIcon={<SaveIcon />}
        >
            {t('save')}
        </Shbutton>
    </ButtonGroup>
    );
};



const getInputOptions = (colors, theme, field, onChange) => {
    switch(field.valueType) {
        case "boolean":
            return <ShSwitch
                checked={field?.value ?? false}
                onChange={(e) => onChange(field, e.target.checked)}
            />;
        case "number":
            return <NumberInput btnSize={"24"}
                value={field?.value ?? 0}
                change={(e) => onChange(field, e)}
            />;
        case "text":
            return <Box
            sx={{
                flex:1,
                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                borderRadius:4,
                display:"flex",
                height:32
            }}
        ><InputBase 
                type="text"
                sx={{
                    px:2,
                    flex:1, 
                    color:colors.txt["primary"],
                    lineHeight:`32px`,
                }}
                value={field?.value ?? ""}
                onChange={(e) => onChange(field, e.target.value)}
            /></Box>;
    }
}

const Info = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [productInfo, setProductInfo] = useState({
        state: 'active',
        sku: '',
        barcode: '',
        secondarySku: '',
        safetyStock: 0,
        food: false,
        fragile: false,
        dangerous: false,
        informShipper: false,
        highcare: false,
        enterDimensionsAfterInbound: false,
        DaWBlockedByWarehouse: false,
        quarantineAfterReturn: false,
        lotPossible: false,
        lotRequired: false,
        batchPossible: false,
        batchRequired: false,
        customInboundSettings: false,
        inboundProcessType: '',
        repackingRequired: false,
        directReplenish: false,
        stackSize: 0,
        stackHeight: 0,
        inboundTemplates: [],
        outboundTemplates: [],
        quarantineOnInbound: false,
    });

    const  { t } = useTranslation();

    const { data: globalEnums, enumIsLoading } = useGetEnumsQuery();
    const { data: workflowTemplates, workflowTemplatesIsLoading } = useGetWorkflowTemplatesSelectQuery("inboundline");

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions 
                            id={props.data?.id}
                            action={handleSaveClick} />
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[productInfo,props.data]);
    
    useEffect(() => {
        const newData = {...props.data};
        if (newData?.inboundTemplates) {
            newData.inboundTemplates = newData.inboundTemplates.map(template => template.id);
        }
        if (newData?.outboundTemplates) {
            newData.outboundTemplates = newData.outboundTemplates.map(template => template.id);
        }

        setProductInfo(newData);
    },[props.data]);

    const handleNumberChange = (key, val) => {
        setProductInfo({ ...productInfo, [key]: val });
    };


    const handleInputChange = (event, field) => {
        setProductInfo({ ...productInfo, [field]: event.target.value });
    };

    const handleSwitchChange = (event, field) => {
        setProductInfo({ ...productInfo, [field]: event.target.checked });
    };

    const handleToggleButtonChange = (event, newActive) => {
        if(newActive !== null){
         setProductInfo({ ...productInfo, active: newActive });
        }
    };

    const handleCustomFieldChange = (field, val) => {
        setProductInfo(prevInfo => ({
            ...prevInfo,
            productFieldValues: prevInfo.productFieldValues.map(customField => 
                customField.id === field.id 
                    ? { ...customField, value: val }
                    : customField
            )
        }));
    }
    
    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        sku: yupRequired('sku',t),
    };

    const schema = yup.object().shape(rules);

    const [UpdateProduct,{isLoading}] = useUpdateProductMutation();
    const handleSaveClick = async () => {
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                setFieldErrors({});
                await schema.validate(productInfo, { abortEarly: false });
                const data = await UpdateProduct(productInfo).unwrap()
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                if (err.name === 'ValidationError') {
                    // Create an errors object where keys are field names and values are error messages
                    const errors = err.inner.reduce((acc, currentError) => ({
                        ...acc,
                        [currentError.path]: currentError.message
                    }), {});
                
                    // Set the validation errors in the state
                    setFieldErrors(errors);
                } else {
                    // Handle other errors, such as network errors
                    showToastMessageRequestError(t("update_failed"), t, err);
                }
            }
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
          }   
        }        
    };

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}>
            {/* Information Section */}
            <Dropdown title={t('information')} open>
                <Box
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        gap:1,
                    }}
                >
                       <Box 
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>{t('active')}</Text>
                        </Box>
                    {/* Active Status */}
                        <ToggleButtonGroup
                            color={productInfo.state === 'active' ? "green" : "red"}
                            value={productInfo.state === 'active'}
                            exclusive
                            onChange={handleToggleButtonChange}
                            aria-label="Active"

                            className={cx(`${productInfo.state === 'active'}`, {
                                'active': productInfo.state === 'active'
                            })}
                            sx={{
                                background: colors.red[100],
                                padding:0.5,
                                borderRadius:5,
                                transition:"250ms",
                                "&.active" : {
                                    background:colors.green[100],
                                    transition:"250ms",
                                },
                            }}
                        >
                            <ToggleButton 
                                className={cx(``, {
                                    'active': !productInfo.state === 'active'
                                })}
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.green[400],
                                    transition:"250ms",
                                    "&.active,&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.red[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value={'inactive'}>
                                {t('inactive')}
                            </ToggleButton>
                            <ToggleButton 
                                className={cx(``, {
                                    'active': productInfo.state === 'active'
                                })}
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.red[400],
                                    transition:"250ms",
                                    "&.active,&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.green[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value={'active'}
                            >
                                {t('active')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                    {/* <ToggleButtonGroup
                        value={productInfo.active}
                        exclusive
                        onChange={handleToggleButtonChange}
                        aria-label="Active status"
                    >
                        <ToggleButton value="true">{t('active')}</ToggleButton>
                        <ToggleButton value="false">{t('inactive')}</ToggleButton>
                    </ToggleButtonGroup> */}

                    {/* SKU */}

                    <Box 
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>{t('sku')}</Text>
                        </Box>
                        
                            <Box display="flex"
                                borderRadius="20px"
                                backgroundColor={`${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`}
                                sx={{
                                    pl:2,
                                }}
                            >
                            <InputBase 
                                value={productInfo.sku}
                                onChange={(e) => handleInputChange(e, 'sku')}
                                endAdornment={
                                    <IconButton>
                                        <ModeEditOutlineOutlinedIcon />
                                    </IconButton>
                                }
                            />
                        </Box>
                    </Box>

                    <Box 
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>{t('secondary_sku')}</Text>
                            </Box>
                            {/* Secondary SKU */}

                            <Box display="flex"
                                borderRadius="20px"
                                backgroundColor={`${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`}
                                sx={{
                                    pl:2,
                                }}
                            >
                            <InputBase 
                                value={productInfo.secondarySku}
                                onChange={(e) => handleInputChange(e, 'secondarySku')}
                                endAdornment={
                                    <IconButton>
                                        <ModeEditOutlineOutlinedIcon />
                                    </IconButton>
                                }
                            />
                        </Box>
                    </Box>

                    <Box 
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>{t('barcode')}</Text>
                        </Box>
                            {/* Secondary SKU */}

                        <Box display="flex"
                            borderRadius="20px"
                            backgroundColor={`${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`}
                            sx={{
                                pl:2,
                            }}
                        >
                            {/* Barcode */}
                            <InputBase 
                                value={productInfo.barcode}
                                onChange={(e) => handleInputChange(e, 'barcode')}
                                endAdornment={
                                    <IconButton>
                                        <ModeEditOutlineOutlinedIcon />
                                    </IconButton>
                                }
                            />
                        </Box>
                        
                    </Box>
                    <Box 
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>{t('stack_size')}</Text>
                        </Box>
                            {/* Secondary SKU */}

                        <Box display="flex"
                            borderRadius="20px"
                            backgroundColor={`${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`}
                            sx={{
                                pl:2,
                            }}
                        >
                            <ShValidatedNumberInput
                            width={1}
                            btnSize={"24"}
                            name="length"
                            value={productInfo?.stackSize ?? 0}
                            onChange={(e, val) =>  handleNumberChange('stackSize', val)}
                            error={fieldErrors.length}
                        />
                        </Box>
                    </Box>
                    <Box 
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>{t('stack_height')}</Text>
                        </Box>
                            {/* Secondary SKU */}

                        <Box display="flex"
                            borderRadius="20px"
                            backgroundColor={`${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`}
                            sx={{
                                pl:2,
                            }}
                        >
                            <ShValidatedNumberInput
                            btnSize={"24"}
                            name="length"
                            value={productInfo?.stackHeight ?? 0}
                            onChange={(e, val) =>  handleNumberChange('stackHeight', val)}
                            error={fieldErrors.length}
                        />
                        </Box>
                    </Box>
                </Box>
            </Dropdown>

            { productInfo?.productFieldValues?.length > 0 && (
            <Dropdown title={t('custom_fields')} open>
                <Box 
                    sx={{
                        display:"flex",
                        gap:8,
                        "@media screen and (max-width:47.9375em)" : {
                            flexDirection:"column",
                        }
                    }}
                >
                    <Box 
                        sx={{
                            display:"flex",
                            flexDirection:"column",
                        }}
                    >
                        {productInfo?.productFieldValues?.map((item, index) => (
                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:1,
                                "@media screen and (max-width:47.9375em)" : {
                                    width:1,
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    width:250
                                }}
                            >
                                <Text light>{item.name}</Text>
                            </Box>
                            
                        {getInputOptions(colors, theme, item, handleCustomFieldChange)}
                        </Box>
                        ))}
                    </Box>
                </Box>
            </Dropdown>
            )}

            {/* Settings Section */}
            <Dropdown title={t('settings')} open>
                <Box 
                    sx={{
                        display:"flex",
                        gap:8,
                        "@media screen and (max-width:47.9375em)" : {
                            flexDirection:"column",
                        }
                    }}
                >
                    <Box 
                        sx={{
                            display:"flex",
                            flexDirection:"column",
                        }}
                    >

                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:1,
                                "@media screen and (max-width:47.9375em)" : {
                                    width:1,
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    width:140
                                }}
                            >
                            {/* Food Switch */}
                                <Text light>{t('food')}</Text>
                            </Box>
                            <ShSwitch
                                checked={productInfo.food}
                                onChange={(e) => handleSwitchChange(e, 'food')}
                            />
                        </Box>

                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:1,
                                "@media screen and (max-width:47.9375em)" : {
                                    width:1,
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                }
                            }}
                        >
                            
                            <Box
                                sx={{
                                    width:140
                                }}
                            >
                                <Text light>{t('fragile')}</Text>
                            </Box>
                            <ShSwitch
                                checked={productInfo.fragile}
                                onChange={(e) => handleSwitchChange(e, 'fragile')}
                            />
                        </Box>


                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:1,
                                "@media screen and (max-width:47.9375em)" : {
                                    width:1,
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    width:140
                                }}
                            >
                                <Text light>{t('dangerous_product')}</Text>
                            </Box>
                            <ShSwitch
                                checked={productInfo.dangerous}
                                onChange={(e) => handleSwitchChange(e, 'dangerous')}
                            />
                        </Box>


                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:1,
                                "@media screen and (max-width:47.9375em)" : {
                                    width:1,
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    width:140
                                }}
                            >
                                <Text light>{t('let_shipper_know')}</Text>
                            </Box>
                            <ShSwitch
                                checked={productInfo.informShipper}
                                onChange={(e) => handleSwitchChange(e, 'informShipper')}
                            />
                        </Box>

                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:1,
                                "@media screen and (max-width:47.9375em)" : {
                                    width:1,
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    width:140
                                }}
                            >
                                <Text light>{t('high_care')}</Text>
                            </Box>
                            <ShSwitch
                                checked={productInfo.highcare}
                                onChange={(e) => handleSwitchChange(e, 'highcare')}
                            />
                        </Box>
                    </Box>


                    <Box 
                        sx={{
                            display:"flex",
                            flexDirection:"column",
                        }}
                    >
                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:1,
                                "@media screen and (max-width:47.9375em)" : {
                                    width:1,
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    width:300
                                }}
                            >
                                <Text light>{t('size_and_weight_locked_by_warehouse')}</Text>
                            </Box>
                            <ShSwitch
                                checked={productInfo.DaWBlockedByWarehouse}
                                onChange={(e) => handleSwitchChange(e, 'DaWBlockedByWarehouse')}
                            />
                        </Box>
                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:1,
                                "@media screen and (max-width:47.9375em)" : {
                                    width:1,
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    width:300
                                }}
                            >
                            {/* Enter Dimensions After Inbound Switch */}
                                <Text light>{t('re_enter_size_at_inbound')}</Text>
                            </Box>
                            <ShSwitch
                                checked={productInfo.enterDimensionsAfterInbound}
                                onChange={(e) => handleSwitchChange(e, 'enterDimensionsAfterInbound')}
                            />
                        </Box>

                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:1,
                                "@media screen and (max-width:47.9375em)" : {
                                    width:1,
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    width:300
                                }}
                            >
                                {/* Quarantine After Return Switch */}
                                <Text light>{t('quarantine_at_return')}</Text>
                            </Box>
                            <ShSwitch
                                checked={productInfo.quarantineAfterReturn}
                                onChange={(e) => handleSwitchChange(e, 'quarantineAfterReturn')}
                            />
                        </Box>
                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:1,
                                "@media screen and (max-width:47.9375em)" : {
                                    width:1,
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    width:300
                                }}
                            >
                                {/* Quarantine After Inbound Switch */}
                                <Text light>{t('quarantine_on_inbound')}</Text>
                            </Box>
                            <ShSwitch
                                checked={productInfo.quarantineOnInbound}
                                onChange={(e) => handleSwitchChange(e, 'quarantineOnInbound')}
                            />
                        </Box>

                        
                    </Box>
                </Box>
            </Dropdown>

            {/* Inbound Section */}
            <Dropdown title={t('inbound')} open>
                <Box
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        gap:1,
                    }}>
                    <Box 
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        {/* Custom Inbound Settings Switch */}
                        
                        <Box
                            sx={{
                                width:240,
                            }}
                        >
                            <Text light>{t('workflow_template')}</Text>
                        </Box>
                        <InputDropdown
                            displayName={"name"} 
                            noSelection={t("choose_option")}
                            options={workflowTemplates?.templates ?? []}
                            selected={workflowTemplates?.templates ? workflowTemplates.templates.findIndex(item => productInfo && productInfo.inboundTemplates.length > 0 && item.id === productInfo.inboundTemplates[0]) ?? -1 : -1}
                            onChange={(selected) => handleInputChange({ target: { value: [workflowTemplates.templates[selected].id] } }, 'inboundTemplates')}
                            closeOnSelection
                        />
                    </Box>
                    <Box 
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        {/* Custom Inbound Settings Switch */}
                        
                        <Box
                            sx={{
                                width:240,
                            }}
                        >
                            <Text light>{t('custom_inbound_settings')}</Text>
                        </Box>
                        <ShSwitch
                            checked={productInfo.customInboundSettings}
                            onChange={(e) => handleSwitchChange(e, 'customInboundSettings')}
                        />
                    </Box>

                    {/* Conditional rendering based on customInboundSettings */}
                    {productInfo.customInboundSettings && (
                        <Box
                            sx={{
                                display:"flex",
                                gap:5,
                                mt:2,
                                "@media screen and (max-width:47.9375em)" : {
                                    flexDirection:"column",
                                    gap:2,
                                }
                            }}
                        >

                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                }}
                            >
                                <Box>
                                    <Text semibold>{t("lot_settings")}</Text>
                                </Box>

                                <Box 
                                    sx={{
                                        display:"flex",
                                        alignItems:"center",
                                        gap:1,
                                        "@media screen and (max-width:47.9375em)" : {
                                            width:1,
                                            flexDirection:"row",
                                            alignItems:"center",
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width:110
                                        }}
                                    >
                                        {/* Lot Possible Switch */}
                                        <Text light>{t('lot_possible')}</Text>
                                    </Box>
                                    <ShSwitch
                                        checked={productInfo.lotPossible}
                                        onChange={(e) => handleSwitchChange(e, 'lotPossible')}
                                    />
                                </Box>

                                <Box 
                                    sx={{
                                        display:"flex",
                                        alignItems:"center",
                                        gap:1,
                                        "@media screen and (max-width:47.9375em)" : {
                                            width:1,
                                            flexDirection:"row",
                                            alignItems:"center",
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width:110
                                        }}
                                    >
                                    {/* Lot Required Switch */}
                                    <Text light>{t('lot_required')}</Text>
                                    </Box>
                                    <ShSwitch
                                        checked={productInfo.lotRequired}
                                        onChange={(e) => handleSwitchChange(e, 'lotRequired')}
                                    />
                                </Box>
                            </Box>

                            <Box>
                                <Box>
                                    <Text semibold>{t("batch_settings")}</Text>
                                </Box>

                                <Box 
                                    sx={{
                                        display:"flex",
                                        alignItems:"center",
                                        gap:1,
                                        "@media screen and (max-width:47.9375em)" : {
                                            width:1,
                                            flexDirection:"row",
                                            alignItems:"center",
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width:110
                                        }}
                                    >
                                    {/* Batch Possible Switch */}
                                    <Text light>{t('batch_possible')}</Text>
                                    </Box>
                                    <ShSwitch
                                        checked={productInfo.batchPossible}
                                        onChange={(e) => handleSwitchChange(e, 'batchPossible')}
                                    />
                                </Box>

                                <Box 
                                    sx={{
                                        display:"flex",
                                        alignItems:"center",
                                        gap:1,
                                        "@media screen and (max-width:47.9375em)" : {
                                            width:1,
                                            flexDirection:"row",
                                            alignItems:"center",
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width:110
                                        }}
                                    >
                                    {/* Batch Required Switch */}
                                        <Text light>{t('batch_required')}</Text>
                                    </Box>
                                    <ShSwitch
                                        checked={productInfo.batchRequired}
                                        onChange={(e) => handleSwitchChange(e, 'batchRequired')}
                                    />
                                </Box>
                            </Box>
                            
                            <Box> 
                                <Box>
                                    <Text semibold>{t("repack_settings")}</Text>
                                </Box>

                                <Box 
                                    sx={{
                                        display:"flex",
                                        alignItems:"center",
                                        gap:1,
                                        "@media screen and (max-width:47.9375em)" : {
                                            width:1,
                                            flexDirection:"row",
                                            alignItems:"center",
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width:140
                                        }}
                                    >
                                    {/* Repacking Required Switch */}
                                    <Text light>{t('repacking_required')}</Text>
                                    </Box>
                                    <ShSwitch
                                        checked={productInfo.repackingRequired}
                                        onChange={(e) => handleSwitchChange(e, 'repackingRequired')}
                                    />
                                    </Box>
                            </Box>
                            
                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    flexDirection:"column",
                                    gap:1,
                                    "@media screen and (max-width:47.9375em)" : {
                                        width:1,
                                        flexDirection:"row",
                                        alignItems:"center",
                                        justifyContent:"space-between",
                                    }
                                }}
                            >
                                {/* Inbound Process Type Dropdown */}
                                <Text semibold>{t('inbound_process_type')}</Text>
                                <InputDropdown
                                    options={globalEnums["inboundline.process.type"]}
                                    selected={productInfo.inboundProcessType}
                                    onChange={(selected) => handleInputChange({ target: { value: selected } }, 'inboundProcessType')}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Dropdown>
            {/* Replenish Section */}
            {/* <Dropdown title={t('replenish')} open>
                <Box>
                    <Box 
                        sx={{
                            mt:"-16px",
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    > */}
                        {/* Custom Inbound Settings Switch */}
                        {/* <Text light>{t('replenish_direct_from_bulk_to_pick')}</Text>
                        <ShSwitch
                            checked={productInfo.directReplenish}
                            onChange={(e) => handleSwitchChange(e, 'directReplenish')}
                        />
                    </Box>
                </Box>
            </Dropdown> */}

            {/* Actions Box */}
            <Box
                sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    padding: 3,
                    width: 1,
                    background: theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                }}
            >
                <GridActions 
                id={props.data?.id}
                action={handleSaveClick} />
            </Box>
        </Box>
    );
}


export default Info;