import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useTranslation } from 'react-i18next';
import Invoicelines from "./Views/Invoicelines";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spInvoiceDetailsConfig } from "./spInvoiceDetailsConfig";
import { useGetInvoiceQuery, useFinalizeInvoiceMutation } from "../../../../newapi/financial/invoiceSlice";
import ListIcon from '@mui/icons-material/List';
import ShLabel from "../../../theme/label/ShLabel";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import CheckIcon from '@mui/icons-material/Check';
import { showToastMessageUpdateRequest} from "../../../../utils/toasts";

const SidePanelActions = (props) => {
    const { t } = useTranslation();
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>
            {props.invoiceData?.isConcept && <Shbutton
                onClick={() => props.finalizeAction(props.invoiceData?.id)}
                color={"primaryGrey"}
                variant="contained"
                endIcon={<CheckIcon />}
            >
                {t('finalize')}
            </Shbutton>}
        </SidepanelHeaderActiongroup>
    )
}

const SpInvoiceDetails = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: invoiceData, isLoading,isFetching,isError } = useGetInvoiceQuery(props.invoiceId,
    {
        skip: !props.invoiceId
    });

    const [finalizeInvoice,{isFinalizing}] = useFinalizeInvoiceMutation();
    const handleFinalizeClick = async (id) => {
        try {
            const response = await finalizeInvoice(id).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    }

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ListIcon/>
    }), [theme.palette.mode]);


    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t("relation")} /> },
        { component: <ShRowSkeleton heading={t("invoice_date")} /> },
        { component: <ShRowSkeleton heading={t("start_date")} /> },
        { component: <ShRowSkeleton heading={t("end_date")} /> },
        { component: <ShRowSkeleton heading={t("invoicelines")} /> },
    ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} finalizeAction={handleFinalizeClick} invoiceData={invoiceData} />  
    }), [defaultIcon,defaultOptions, setIsExtendedCallback]);


    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.invoiceId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && invoiceData) {
            let date = new Date(invoiceData.date);
            let startDate = new Date(invoiceData.startDate);
            let endDate = new Date(invoiceData.endDate);

            setPanel(prevPanel => ({
                ...prevPanel,
                title: <ShLabel size={32} palette="normal" fitted={true} variant={invoiceData.isConcept ? "orange" : "green"}>{t('invoice')} {invoiceData.number}</ShLabel>,
                subTitle:"",
                options: [
                    {
                        heading: t("relation"),
                        content: invoiceData.relation.name
                    },
                    {
                        heading: t("invoice_date"),
                        content: date.toLocaleDateString(i18n.language)
                    },
                    {
                        heading: t("start_date"),
                        content: startDate.toLocaleDateString(i18n.language)
                    },
                    {
                        heading: t("end_date"),
                        content: endDate.toLocaleDateString(i18n.language)
                    },
                    {
                        heading: t("invoicelines"),
                        content: invoiceData.invoiceProducts.length
                    }
                ],
                actions:<SidePanelActions setIsExtended={setIsExtendedCallback} finalizeAction={handleFinalizeClick} invoiceData={invoiceData} />  
            }));
        }
    }, [invoiceData, isLoading, isFetching, t,theme.palette.mode]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spInvoiceDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Invoicelines config={spInvoiceDetailsConfig.details} invoiceId={props.invoiceId} setIsExtended={props.setIsExtended} />
                </>
        </Sidepanel2>
    );
};

export default SpInvoiceDetails;