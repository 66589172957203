import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useEditProductFieldMutation, useDeleteProductFieldMutation } from "../../../../../newapi/product/productFieldSlice";
import * as yup from "yup";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired } from "../../../../../utils/validation";
import { useGetEnumsQuery } from "../../../../../newapi/global/generalApiSlice";

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { showToastMessageDeleteRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(false);
    

    const [productFieldValueInfo, setProductFieldValueInfo] = useState({
        name: "",
        valueType: "",
    });

    useEffect(() => {
        setProductFieldValueInfo(props.data);
    },[props.data]);
    
    const handleChange = (key, value) => {
        setProductFieldValueInfo(prevState => ({
            ...prevState,
            [key]: value
        }));
      };

      
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={productFieldValueInfo}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[productFieldValueInfo]);

    const { data: globalEnums, enumIsLoading } = useGetEnumsQuery("productfield_value_type");
    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        valueType: yupRequired('value_type', t),
    };
    
    const schema = yup.object().shape(rules);

    const [EditProductField,{isLoading}] = useEditProductFieldMutation();
    const handleSaveClick = async () => {
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                setFieldErrors({});
                await schema.validate(productFieldValueInfo, { abortEarly: false });
                const data = await EditProductField(productFieldValueInfo).unwrap()
                showToastMessageUpdateRequest(t,data);
            } catch (err) {
                if (err.name === 'ValidationError') {
                    // Create an errors object where keys are field names and values are error messages
                    const errors = err.inner.reduce((acc, currentError) => ({
                      ...acc,
                      [currentError.path]: currentError.message
                    }), {});
              
                    // Set the validation errors in the state
                    setFieldErrors(errors);
                  } else {
                    // Handle other errors, such as network errors
                    showToastMessageRequestError(t("update_failed"), t, { success: false, error: err.error });
                  }
            }
        } else {
            if(!isLoading) {
                toast.error(t("not_all_fields_filled"), {
                    position: toast.POSITION.TOP_RIGHT
                });
            }    
        }        
    };

    const [DeleteProductFieldMutation] = useDeleteProductFieldMutation();
    const confirmDelete = async () => {
        try {
            const response = await DeleteProductFieldMutation({id: props.data.id}).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };

    const handleDeleteClick = async () => {
        setOpen(true);
    };

    const confirmDeleteModal = {
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    }
    

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("basic")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    
                    {/* Two columns */}
                    
                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("name")}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text>{t("value_type")}</Text>
                            </Box> 
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <ShValidatedInput
                                    name="name"
                                    value={productFieldValueInfo?.name}
                                    onChange={handleChange}
                                    error={fieldErrors?.name}
                                />
                            </Box>
                            
                            <Box 
                                sx={{
                                    lineHeight:"38px",
                                    "@media screen and (max-width:47.9375em)" : {
                                        display:"flex",
                                        justifyContent:"flex-end"
                                    }
                                }}
                            >
                                <ShValidatedInputDropdown
                                    name="valueType"
                                    displayName="displayName"
                                    changeField={"name"}
                                    selected={globalEnums ? globalEnums["productfield.value.type"].findIndex(item => item === productFieldValueInfo?.valueType) ?? -1 : -1}
                                    options={globalEnums ? globalEnums["productfield.value.type"].map((item, index) => ({ id: index, name: item, displayName: t(item) })) : []}
                                    noSelection={t("choose_option")} 
                                    onChange={handleChange}
                                    error={fieldErrors.valueType}
                                />
                            </Box>


                        </Box>
                    </Box>
                </Box>
                <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                
            </Box>
            </Dropdown>
            
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
                    <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
        <GridActions
            id={productFieldValueInfo?.id}
            action={handleSaveClick}
            deleteAction={handleDeleteClick}
            // active={active}
            // blocked={blocked}
            // remark={remark}
            // carrier={carrier}
            // quarantine={quarantine}
            // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
        />
            </Box>
        </Box>
    );
}

export default DetailsPanel;