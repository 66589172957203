import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const WAREHOUSE_BASE = "/warehouselocations";
const buildUrl = (endpoint) => WAREHOUSE_BASE + endpoint;

const warehouseLocationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getWarehouseLocations: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'WarehouseLocations' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getWarehouseLocations', 'WarehouseLocations')
        }),
        getWarehouseLocation: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'WarehouseLocation', id }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getWarehouseLocation', 'WarehouseLocation')
        }),
        getWarehouseLocationSp: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'WarehouseLocation', id }],
        }),
        updateWarehouseLocation: builder.mutation({
            query: ({ id, data }) => ({
                url: buildUrl(`/${id}`),
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'WarehouseLocation', id }],
        }),
        addWarehouseLocation: builder.mutation({
            query: locationInfo => ({
                url: buildUrl(`/add`),
                method: 'POST',
                body: locationInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'WarehouseLocations'}],
        }),
        deleteWarehouseLocation: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'WarehouseLocation'}],
        }),
    })
});

export const { 
    useGetWarehouseLocationsQuery,
    useGetWarehouseLocationQuery,
    useUpdateWarehouseLocationMutation,
    useAddWarehouseLocationMutation,
    useGetWarehouseLocationSpQuery,
    useDeleteWarehouseLocationMutation
} = warehouseLocationsApiSlice;
