// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useSelector } from 'react-redux';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { useDispatch } from 'react-redux';


/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };
    

    return (
        <>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("name")}</Text>
                </Box>
                
                
                    <ShValidatedInput
                            name="name"
                            value={data.name ?? ""}
                            onChange={handleChange}
                            error={props.errors.name}
                        />
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("reference")}</Text>
                </Box>
                
                
                    <ShValidatedInput
                            name="reference"
                            value={data.reference ?? ""}
                            onChange={handleChange}
                            error={props.errors.reference}
                        />
            </Box>
        </>
    );
};

const ShipperBasicInfo = (props) => {
    const { t } = useTranslation();
    const schema = yup.object({
        name: yupRequired('name',t),
        reference: yupRequired('reference',t),
    });

    return (
        <ProgressionTab 
            tabId="tabBasicInfo" 
            subtitle={"Info"} 
            step={2}
            nextTab={"tabMeasurements"}
            maxSteps={6}
            schema={schema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default ShipperBasicInfo;

