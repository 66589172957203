import { Box, useTheme } from "@mui/material"
import { tokens } from "../../../theme";
import Text from "../text/Text";
import ShTextSkeleton from "./ShTextSkeleton";


const ShRowSkeleton = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box 
            sx={{
                display:"flex",
                flexDirection:"column"
            }}
        >
            <Text light>{props.heading}</Text>
            <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
        </Box>
    )
}

export default ShRowSkeleton;