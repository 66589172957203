import { Box, Button, InputBase, useTheme } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import PopoverModal from './Modal/PopoverModal';
import { tokens } from '../../theme';
import Subheading from './text/Subheading';
import Text from './text/Text';
import { useTranslation } from 'react-i18next';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import dayjs from 'dayjs';

String.prototype.replaceAt = function(index, replacement) {
    return this.substring(0, index) + replacement + this.substring(index + replacement.length);
}

const ShTimePicker = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    
    console.log("props.value",props.value);

    const [hour, setHour] = useState(() => {
        // Check if the incoming value is a valid dayjs object
        if (props.value && props.value.isValid()) {
          return props.value.format("HH");
        }
        return '';  // Set to empty string if the value is invalid
      });
    
      const [minute, setMinute] = useState(() => {
        // Check if the incoming value is a valid dayjs object
        if (props.value && props.value.isValid()) {
          return props.value.format("mm");
        }
        return '';  // Set to empty string if the value is invalid
      });

      useEffect(() => {
        if(!props.noUseEffect){
            setHour(() => {
                if (props.value && props.value.isValid()) {
                    return props.value.format("HH");
                }
                return '';  // Set to empty string if the value is invalid
            })
            setMinute(() => {
                if (props.value && props.value.isValid()) {
                    return props.value.format("HH");
                }
                return '';  // Set to empty string if the value is invalid
            })
        }
      },[props.value])


    let comingFromMinuet = false;

    const [error, setError] = useState(''); // Error state for validation

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        if(props.onChange){
            props.onChange(dayjs(hour+":"+minute,'HH:mm'));
        }
    };

        
    // Refs for hour and minute inputs
    const hourRef = useRef(null);
    const minuteRef = useRef(null);


    useEffect(() => {
        if(props.onChange){
            props.onChange(dayjs(hour+":"+minute,'HH:mm'));
        }
    },[hour,minute])

    // Validate hour and minute format (hour should be <= 23, minute <= 59)
    const validateTime = (hour, minute) => {
        if (parseInt(hour) > 23 || parseInt(minute) > 59) {
        setError('Invalid time');
        return false;
        }
        setError('');
        return true;
    };

    // Handle the hour input change with padding and restrictions
    const handleHourChange = (e) => {
        if(comingFromMinuet)  {
            comingFromMinuet = false;
            return;
        }
        let newHour = e.target.value;


        if( newHour.length === 1){
            newHour=`0${newHour}`;
        } else if(newHour.length >= 2) {
            newHour = newHour.replaceAt(0,newHour.charAt(1));
            newHour = newHour.replaceAt(1,newHour.charAt(2));
            newHour = newHour.slice(0,-1);
        }

        if (parseInt(newHour.charAt(0)) === 0 && parseInt(newHour.charAt(1)) < 3) {
            //select secondary number
            setHour(newHour);
        } else if (newHour.length === 2 && parseInt(newHour) > 23) {
            //select secondary number
            newHour="23";
            setHour(newHour); // Set the time and focus minute input
            minuteRef.current.focus();
        } else if(parseInt(newHour.charAt(0)) === 0 && parseInt(newHour.charAt(1)) >= 3) {
            setHour(newHour); // Set the time and focus minute input
            minuteRef.current.focus();

        } else if(newHour.length === 2 && parseInt(newHour.charAt(0)) !== 0 ){
            setHour(newHour);
            minuteRef.current.focus();

        }
        
    };

    // Handle the minute input change with padding and restrictions
    const handleMinuteChange = (e) => {
        let newMinute = e.target.value;


        if(newMinute.length === 1){
            newMinute=`0${newMinute}`;
        } else if(newMinute.length >= 2) {
            newMinute = newMinute.replaceAt(0,newMinute.charAt(1));
            newMinute = newMinute.replaceAt(1,newMinute.charAt(2));
            newMinute = newMinute.slice(0,-1);
        }

        if(parseInt(newMinute.charAt(0)) === 0 && parseInt(newMinute.charAt(1)) < 6) {
            setMinute(newMinute);
        } else if (newMinute.length === 2 && parseInt(newMinute) > 59) {
            //select secondary number
            newMinute="59";
            setMinute(newMinute); // Set the time and focus minute input
            minuteRef.current.blur();
        }
        else if(parseInt(newMinute.charAt(0)) === 0 && parseInt(newMinute.charAt(1)) >= 6) {
            setMinute(newMinute); // Set the time and focus minute input
            minuteRef.current.blur();

        }else if(newMinute.length === 2 && parseInt(newMinute.charAt(0)) !== 0 ){
            setMinute(newMinute);
            minuteRef.current.blur();
            // setMinute.current.focus();

        }
    };

    // Handle keydown event for deleting the last minute character
    const handleMinuteDelete = (e) => {
        if (e.key === 'Backspace' && (minute.length === 1 || (minute.length === 2 && parseInt(minute) === 0))) {
            comingFromMinuet=true;
            hourRef.current.focus(); // Focus back on hour input when deleting minute
        }
    };

    return (
        <Box
            sx={{
                flex:1,
            }}
        >
            <Box className="time-picker-container"
                onClick={() => hourRef.current.focus()}
                sx={{
                    backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                    display:"flex",
                    alignItems:"center",
                    px:1,
                    height:40,
                    borderRadius:5,
                    cursor:"text",
                    "& input::placeholder" : {
                        color:`${colors.txt.secondary} !important`,
                        opacity:"1 !important"
                    }
                }}
            >
                <InputBase
                    type="text"
                    value={hour} // Hour part
                    onChange={handleHourChange}
                    maxLength="3"
                    placeholder="hh"
                    className={`time-input ${error ? 'error' : ''}`}
                    inputRef={hourRef}
                    sx={{
                        pl:1.5,
                        wdith:"auto",
                        display:"inline",
                        width:32,
                        color:colors.txt.primaryr
                    }}
                />
                    <span>:</span>
                    
                <InputBase
                    type="text"
                    value={minute} // Minute part
                    onChange={handleMinuteChange}
                    maxLength="3"
                    placeholder="mm"
                    className={`time-input ${error ? 'error' : ''}`}
                    inputRef={minuteRef}
                    onKeyDown={handleMinuteDelete} // Handle backspace
                    sx={{
                        pl:0.5,
                        wdith:"auto",
                        display:"inline",
                        width:32,
                        color:colors.txt.primary
                    }}
                />
                <Button 
                onClick={handlePopoverOpen}
                sx={{
                    ml:"auto",
                    boxShadow:"none",
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[100],
                    color:theme.palette.mode  === "dark" ? colors.txt.secondary : colors.grey[400],
                    fill:"orange",
                    p:0,
                    width:32,
                    minWidth:0,
                    borderRadius:4,
                    height:32,
                    "&:hover": {
                        background:colors.grey[400],
                        color:colors.txt.white

                    }
                }}

                > 
                    <WatchLaterOutlinedIcon/>
                </Button>
            </Box>
        {error && <div className="error-message">{error}</div>}


        {/* Overlay with hour and minute lists */}
            <PopoverModal 
                id="simple-popover"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                triangle={{position:"center",color:theme.palette.mode === "dark" ? colors.grey[300] : "#fff"}}
            >
            <Box
                sx={{
                    maxWidth:450,
                    minWidth:300,
                    pt:3,
                    background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                    "@media screen and (max-width:47.9375em)" : {
                        maxWidth:"unset"
                    }
                }}
            >
                
                <Box className="time-picker-lists"
                    sx={{
                        position:"relative",
                        zIndex:0,
                        display:"flex",
                        px:3,
                    }}
                >
                    <Box className="hours-list"
                        sx={{
                            pb:6,
                            flex:1,
                            textAlign:"center",
                            maxHeight:260,
                            overflow:"scroll"
                        }}
                    >
                        {/* <Text>Hours</Text> */}
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                            }}
                        >
                            {Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0')).map((hour) => (
                                <Button
                                    key={hour}
                                    className="time-option-btn"
                                    variant='contained'
                                    onClick={() => {
                                    setHour(hour);
                                        if(minute === ''){
                                            setMinute('00');
                                        }
                                        //   setShowOverlay(false);
                                    }}
                                    sx={{
                                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.txt.white,
                                        color: colors.txt["primary"],
                                        width:1,
                                        height:32,
                                        flexShrink:0,
                                        borderRadius:"8px",
                                        padding:0,
                                        minWidth:0,
                                        paddingX:1.5,
                                        display: "flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        textTransform:"none",
                                        minWidth:75,
                                        boxShadow: "none",
                                        opacity:1,
                                        "&:hover" : {
                                            transition:"250ms",
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"],
                                            boxShadow:"none"
                                        },
                                    }}
                                >
                                    {hour}
                                </Button>
                            ))}
                        </Box>
                    </Box>
                    <Box className="minutes-list"
                        sx={{
                            pb:6,
                            flex:1,
                            textAlign:"center",
                            maxHeight:260,
                            overflow:"scroll"
                        }}
                    >
                        {/* <Text bold>Minutes</Text> */}
                    
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                            }}
                        >
                            {Array.from({ length: 12 }, (_, i) => String(i * 5).padStart(2, '0')).map((minute) => (
                                <Button
                                    key={minute}
                                    variant="contained"
                                    className="time-option-btn"
                                    onClick={() => {
                                        setMinute(minute);
                                    }}

                                    sx={{
                                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.txt.white,
                                        color: colors.txt["primary"],
                                        width:1,
                                        height:32,
                                        flexShrink:0,
                                        borderRadius:"8px",
                                        padding:0,
                                        minWidth:0,
                                        paddingX:1.5,
                                        display: "flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        textTransform:"none",
                                        minWidth:75,
                                        boxShadow: "none",
                                        opacity:1,
                                        "&:hover" : {
                                            transition:"250ms",
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"],
                                            boxShadow:"none"
                                        },
                                    }}
                                >
                                    {minute}
                                </Button>
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        position:"relative",
                        zIndex:1,
                        mt:-2.3,
                        boxShadow:"0px 0px 19px rgba(49,65,95,.15)",
                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                        px:2,
                        py:1.5,
                        display:"flex",
                        justifyContent:"flex-end"
                    }}
                >
                    <Button
                    onClick={handlePopoverClose}
                        variant="contained"
                        color={theme.palette.mode==="dark" ? "blue": "primary"}
                        sx={{
                            borderRadius:5,
                            boxShadow:"none",
                            px:3,
                        }}
                    >
                        {t("save")}
                    </Button>

                </Box>
            </Box>
            </PopoverModal>
        </Box>
    );
};

export default ShTimePicker;
