import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import ContactsIcon from '@mui/icons-material/Contacts';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import RelationContacts from "./Views/RelationContacts";
import WebshopCredentials from "./Views/WebshopCredentials";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from 'react-i18next';
import DetailsPanel from "./Views/Details";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spRelationDetailsConfig } from "./spRelationDetailsConfig";
import { useGetRelationQuery } from "../../../../newapi/global/relation/relationSlice";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpRelationDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: relationData, isLoading,isFetching,isError } = useGetRelationQuery(props.relationId,
    {
        skip: !props.relationId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ContactsIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t("phone")} /> },
        { component: <ShRowSkeleton heading={t("email")} /> },
        { component: <ShRowSkeleton heading={t("website")} /> },
    ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon,defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.relationId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && relationData) {
            setPanel(prevPanel => ({
                ...prevPanel,
                title: relationData.name,
                subTitle: [""],
                options: [
                    {
                        heading: t('phone'),
                        content: relationData.phone
                    },
                    {
                        heading: t('email'),
                        content: relationData.email
                    },
                    {
                        heading: t('website'),
                        content: relationData.website
                    },
                ],
            }));
        }
    }, [relationData, isLoading, isFetching, t,theme.palette.mode]);
    

    // const [panel, setPanel] = useState({
    //     title: "",
    //     subTitle: "",
    //     icon :{
    //         theme: theme.palette.mode === "dark" ? "blue" : "primary",
    //         content: <ContactsIcon/>
    //     },
    //     //Heading row for default shit
    //     options : [],
    //     actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    // });

    // useEffect(() => {
    //     if (typeof data !== "undefined" && typeof data.id !== "undefined") {
    //       setPanel((prevPanel) => ({
    //         ...prevPanel,
    //         title: data.name,
    //         subTitle: [""],
    //         options: [
    //             {
    //                 heading: t('phone'),
    //                 content: data.phone
    //             },
    //             {
    //                 heading: t('email'),
    //                 content: data.email
    //             },
    //             {
    //                 heading: t('website'),
    //                 content: data.website
    //             },
    //         ],
    //       }));
    //     }
    //   }, [data]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spRelationDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <DetailsPanel config={spRelationDetailsConfig.details} data={relationData} setIsExtended={props.setIsExtended} />
                    <RelationContacts config={spRelationDetailsConfig.relationContacts} relationId={props.relationId}/>
                    <WebshopCredentials config={spRelationDetailsConfig.webshopCredentials} relationId={props.relationId} />
                </>
        </Sidepanel2>
    );
};

export default SpRelationDetails;