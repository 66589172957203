import { Box, IconButton, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { tokens } from "../../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../../../theme";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShDataGrid from "../../../../../../components/theme/datagrid/ShDataGrid";
import Subheading from "../../../../../../components/theme/text/Subheading";
import Text from "../../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../components/theme/buttons/Shbutton";

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

import AllCarriersCard from "../../../../../../components/theme/cards/carriers/AllCarriersCard";
import SpCarrierDetails from "../../../../../../components/global/Sidepanels/SpCarrierDetails/SpCarrierDetails";
import { useTranslation } from "react-i18next";
import MAddCarrier from "../../../../../../components/global/Modals/carriers/AddCarrier";
import { useGetCarriersQuery, useGetCarrierQuery } from "../../../../../../newapi/carrier/carrierSlice";
import ShDataGrid2 from "../../../../../../components/theme/datagrid2/ShDataGrid2";


function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 


const GridActions = (props, i18n) => {
    const theme = useTheme();
    
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("add_carrier")}</Shbutton>
        </ButtonGroup>
    )
}

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:false,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};


const All = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [carrierIsExtended,setCarrierIsExtended] = useState(false);
    
    const [addStandardOpen, setAddStandardOpen] = useState(false);
    const addStandardModal = {
        open: addStandardOpen,
        setOpen: setAddStandardOpen
    }

    const handleClose = () => {
        setAddStandardOpen(false);
      };

    const [carrierId, setCarrierId] = useState();
    const carrierIdRef = useRef(carrierId);
    
    const getCarrierDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.id);
        // Only update state if the productId has actually changed
        if (carrierIdRef.current !== newProductId) {
            setCarrierId(newProductId);
            carrierIdRef.current = newProductId; // Update the ref to the new value
        }
        setCarrierIsExtended(true);
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<WareHouseDropdown {...props.warehouseModal}/>,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddStandardOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    
    const columns = [
        {field: "name", headerName: t("carrier") , flex:2, cellClassName: "carrier", renderCell : (cellValues) => {
            return (
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
            )
        }},
        {field: "fitsPost", headerName: t("fits_dutch_post") , flex:1, renderCell : (cellValues) => {
            return (
                cellValues.value === "true" ?
                    <Text variant="green"><CheckOutlinedIcon fontSize="small"/></Text>:
                cellValues.value === "false" ?
                    <Text variant="red"><CloseOutlinedIcon fontSize="Small" /></Text> 
                :
                    <Text variant="orange"><HorizontalRuleOutlinedIcon fontSize="Small"/></Text>
            )
        }},

        {field: "channels", headerName: t("channels"), flex:1, renderCell : (cellValues) => {
            return (
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
            )
        }},
        {field: "weight", headerName: t("weight_including_label"), flex:1, renderCell : (cellValues) => {
            return (
                <Text>{cellValues.value}</Text>
            )
        }},
        {field: "length", headerName: t("dimensions_lwh"), flex:1, renderCell : (cellValues) => {
            return (
                <Text>{`${cellValues.row.length} x ${cellValues.row.width} x ${cellValues.row.height}`}</Text>
            )
        }},
        {field: "barcodes", headerName: t("nr_of_barcodes"), flex:1, renderCell : (cellValues) => {
            return (
                <Text>{cellValues.value}</Text>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
                return (
                    <AllCarriersCard {...cellValues.row} />
                )
            }
        }
    ];

    useImperativeHandle(ref, () => ({
    }));

    // const handleRowClick = (params) => {
    //     console.log(params);
    //     setIsExtended(true);
    // };
    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            <Box
                sx={{
                    background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    flex:1,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    "@media screen and (max-width: 48em)" : {
                        // display:"none"
                        background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    }
                }}
            >
                <ShDataGrid2 key={theme.palette.mode}
                    content={useGetCarriersQuery}
                    gridActions={<GridActions handleOpen={() => setAddStandardOpen(true)}></GridActions>} 
                    title={t("overview")}
                    gridOptions={gridOptions} 
                    onRowClick={getCarrierDetails}
                    columns={columns} 
                    // onRowClick={getCarrierDetails}
                    rows={data}>
                </ShDataGrid2>
            </Box>

            <MAddCarrier onOpen={() => addStandardModal.setOpen(true)} onClose={() => addStandardModal.setOpen(false)} open={addStandardModal.open} handleClose={handleClose}/>
            {/* <SpCarrierDetails isExtended={isExtended} setIsExtended={setIsExtended}/> */}
            <SpCarrierDetails
                isExtended={carrierIsExtended}
                setIsExtended={setCarrierIsExtended}
                carrierId={carrierId}
                // data={carrierData}                
            ></SpCarrierDetails>
        </Box>
    )
});


// const All = (apiRef,props) => {
//     return {
//         title: "All carriers",
//         subTitle: "All carriers registered",
//         icon: <LocalShippingOutlinedIcon/>,
//         view: <AllBody apiRef={apiRef}/>,
//         topRight:<WareHouseDropdown {...props.warehouseModal}/>,
//         isSingleDataGrid: true,
//         apiRef: apiRef,
//         gridOptions:gridOptions,
//         mobileActions:<GridActions></GridActions>
//     }
// }

export default All;