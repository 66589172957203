// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useSelector } from 'react-redux';
import Shbutton from '../../../../theme/buttons/Shbutton';
import { useGetExistingQuery } from '../../../../../newapi/user/userSlice';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import { useDispatch} from 'react-redux';
/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const [email, setEmail] = useState();
    const [emailToCheck, setEmailToCheck] = useState("");
    const [fieldErrors, setFieldErrors] = useState({});
    const { data: existingData, isLoading } = useGetExistingQuery(emailToCheck,
        {
           enabled: (emailToCheck && emailToCheck !== "" && emailToCheck !== undefined),
            skip: (!emailToCheck || emailToCheck === "" || emailToCheck === undefined)
        }
    );
    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        setEmail(val);
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    const confirm = async () => {
        setFieldErrors({});
        if(props.data?.email && props.data.email !== ''){
            if(props.data.email.includes("@")){
                setEmailToCheck(email);
            } else {
                setFieldErrors({"email": t("email_invalid")});
            }
            
        } else {
              // Set the validation errors in the state
              setFieldErrors({"email": t("email_missing")});
        }
        
    }

    useEffect(() => {
        if(!existingData) return;
        //if(emailToCheck === "") return;
        let nextTab;
        if(existingData.found){
            props.handleDataUpdate({ Id: existingData.user.id, existing: true });
            nextTab = "tabConfirmInvite";
        } else {
            props.handleDataUpdate({ existing: false, usergroup_id: "1" });
            nextTab = "tabBasicInfo";
        }
        props.handleNext(nextTab);
    },[existingData, isLoading])

    return (
        <>

            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("email")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                            name="email"
                            type="email"
                            value={data.email ?? ""}
                            onChange={handleChange}
                            error={fieldErrors.email}
                        />
                    
                </Box>
            </Box>
                    
                    <Box
                        sx={{
                            display:"flex",
                            mt:3,
                            "& .btn" : {
                                ml:"auto"
                            }
                        }}
                    >
                        <Shbutton 
                            className={"btn"} 
                            color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                            onClick={() => confirm()} 
                            variant="contained"
                        >
                            Controleren
                        </Shbutton>
                    </Box>
        </>
    );
};

const UserEmail = (props) => {
    const {t} = useTranslation();
    return (
        <ProgressionTab 
            tabId="tabUserInfo" 
            subtitle={t("email")} 
            step={1}
            //nextTab={"tabBasicInfo"}
            maxSteps={2}
        >
            <Content />
        </ProgressionTab>
    )
}

export default UserEmail;

