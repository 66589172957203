
import WidgetsIcon from '@mui/icons-material/Widgets';
import CropFreeIcon from '@mui/icons-material/CropFree';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const spPickingJobPickingListConfig = {
    pickingLines:  {
        title: 'pickinglines',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
};
