function calculateDevEnvUrls(){
    let base = window.location.host;
    base = base.split(":")[0];
    if(base.startsWith("192")) {
        //Most likely a mobile dev environment
        return {
            API_ROOT_URL: "http://" + base + ":10000/api/",
            API_BACKEND_URL: "http://" + base + ":3002/api/",
        }
    } else {
        return {
            API_ROOT_URL: "http://localhost:10000/api/",
            API_BACKEND_URL: "http://localhost:3002/api/"
        }
    }
}

const prod = {
    url: {
        API_ROOT_URL: "https://root-api.stockhub.nl/api/",
        API_BACKEND_URL: "https://api.stockhub.nl/api/"
    }
};

const dev = {
    url: calculateDevEnvUrls()
};

const test = {
    url: {
        API_ROOT_URL: "https://root-api.stockhub.nl/api/",
        API_BACKEND_URL: "https://test-api.stockhub.nl/api/"
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : 
                      process.env.REACT_APP_STOCKHUB_ENV === 'test' ? test : prod;