import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useTranslation } from 'react-i18next';
import Details from ".//Views/Details";
import SettingsIcon from '@mui/icons-material/Settings';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spWorkflowTemplateDetailsConfig } from "./spWorkflowTemplateDetailsConfig";
import { useGetWorkflowTemplateQuery } from "../../../../newapi/workflow/workflowTemplateSlice";
const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpWorkflowTemplateDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;

    const { data: workflowTemplateData, error, isLoading,isFetching } = useGetWorkflowTemplateQuery(
        props.workflowTemplateId,
         {
             skip: !props.workflowTemplateId
         }
     );


    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <SettingsIcon/>
    }), [theme.palette.mode]);

    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        actions: <SidePanelActions setIsExtended={setIsExtendedCallback} />
    }), [defaultIcon, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.workflowTemplateId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && workflowTemplateData) {
            // let locations = carrierData.stocks.map(stock => stock.warehouseLocation.location);
            // let combinedLocations = locations.join(", ");

            setPanel(prevPanel => ({
                ...prevPanel,
                title: workflowTemplateData.name,
                icon: defaultIcon,
            }));
        }
    }, [workflowTemplateData, isLoading, isFetching, t,theme.palette.mode]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spWorkflowTemplateDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spWorkflowTemplateDetailsConfig.details} data={workflowTemplateData} setIsExtended={props.setIsExtended}/>
                </>
        </Sidepanel2>
    );
};

export default SpWorkflowTemplateDetails;