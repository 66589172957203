
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const warehouseFinancialInvoiceProductsConfig = {
    all:  {
        title: 'all_invoice_products',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
};
