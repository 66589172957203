import CropFreeIcon from '@mui/icons-material/CropFree';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const spWorkflowDetailsConfig = {
    details: {
        title: 'details',
        icon: <CropFreeIcon/>,
    },
    logs: {
        title: 'logs',
        icon: <FormatListBulletedIcon/>,
    }
};
