import AddIcon from '@mui/icons-material/Add';
import CropFreeIcon from '@mui/icons-material/CropFree';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Shbutton from "../../../../theme/buttons/Shbutton";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import ShLabel from "../../../../theme/label/ShLabel";
import Text from "../../../../theme/text/Text";

import { useTranslation } from "react-i18next";
import OrderLinesCard from "../../../../theme/cards/orders/OrderLinesCard";
import SpStockDetails from "../../SpStockDetails/SPStockDetails";
import ShTextSkeleton from '../../../../theme/skeleton/ShTextSkeleton';
import ShLabelSkeleton from '../../../../theme/skeleton/ShLabelSkeleton';
import { useGetReturnLinesQuery } from '../../../../../newapi/inbound/returnsSlice';
import { useGetProductQuery } from '../../../../../newapi/warehouse/productSlice';
import ShDataGrid2 from '../../../../theme/datagrid2/ShDataGrid2';
// import classNames from "classnames";
function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained" endIcon={<AddIcon/>}>{t("save")}</Shbutton>
        </ButtonGroup>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    
    filter : {
        name: "productsFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};




const Return = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    const [rowSelected, setRowSelected] = useState(false);


    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.product.id);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    

    const columns = [
        {field: "amount", headerName: t("amount"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.value;
            }
        },
        {field: "product.sku", headerName: t("sku"), flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product.sku}</Text>
                </Box>
            )
        }},
        {field: "product.description", headerName: t("description"), flex:3, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text semibold>{params.row.product.description}</Text>
                </Box>
            )
        }},
        {field: "returnreason.description", headerName: t("reason"), flex:3, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text > {params.row.returnreason.description}</Text>
                </Box>
            )
        }},
        
        {field: "remark", headerName: t("remark"), flex:2,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            if(params.row.remark !== ""){
                return (
                    <Text light>{params.row.remark}</Text> 
                  );
            } else {
                
            }
            
          }},
        {
            field: "phoneView", 
            headerName: "phoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <OrderLinesCard skeleton={params.row.skeleton ?? false} {...params}/>
                )
            }
        }
    ];

    return (
        <>
            <Box height={1} display={"flex"} flexDirection={"column"} overflow={"auto"}>
                {props.data && 
                    <ShDataGrid2 key={theme.palette.mode}
                    itemId={props.data.id}
                    content={useGetReturnLinesQuery}
                    onRowClick={getProductDetails}
                    // gridActions={<GridActions></GridActions>}
                    title={t("return")}
                    gridOptions={gridOptions}
                    isSidepanelGrid
                    columns={columns}
                    />
                }
            </Box>
            <SpStockDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                productId={productId}
                // data={productData}
            />
        </>
      );
      
}

export default Return;