import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme"; 
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import Text from "../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import { useTranslation } from "react-i18next";
import { useGetWebshopCredentialsSingleQuery, useGetWebshopCredentialsQuery } from "../../../../../newapi/webshop/webshopSlice";
import SpWebshopCredentialsDetails from "../../../../../components/global/Sidepanels/SpWebshopCredentialsDetails/SpWebshopCredentialsDetails";
import MpAddWebshop from "../../../../../components/global/ModalsProgression/MpAddWebshop/MpAddWebshop";
import { RelationWebshopIntegrationCard } from "../../../../../components/theme/cards/other/WebshopIntegrationCard";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import { ReactComponent as MagentoLogo } from '../../../../../styles/svg/magento.svg';
import { ReactComponent as ShopifyLogo } from '../../../../../styles/svg/shopify.svg';
import { ReactComponent as WooCommerceLogo } from '../../../../../styles/svg/woocommerce.svg';
import { ReactComponent as BolLogo } from '../../../../../styles/svg/bol.svg';
import { QuestionMarkOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { ReactComponent as CcvshopLogo } from '../../../../../styles/svg/ccvshop.svg';  

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("add_webshop")}</Shbutton>
        </ButtonGroup>
    );
}


let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(1);
    const [rowSelected, setRowSelected] = useState(false);
    const [config, setConfig] = useState({"all_credentials": true});
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);
    // const { data: credentialData, error, isLoading } = useGetWebshopCredentialsSingleQuery(
    //    selectedRowId,
    //     {
    //         skip: !rowSelected
    //     }
    // );
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    // const getWebshopCredentialDetails = (row) => {  
    //     if (!row) return;
    //     setSelectedRowId(row.id);
    //     setRowSelected(true);
    // };

    // useEffect(() => {
    //     if (!isLoading && credentialData && rowSelected) {
    //         setIsExtended(true);
    //     }
    // }, [credentialData, isLoading, rowSelected]);

    // useEffect(() => {
    //     if(!isExtended) {
    //         setRowSelected(false);
    //     }
    // },[isExtended])
    const [webshopCredentialsId, setWebshopCredentialsId] = useState();
    const webshopCredentialsIdRef = useRef(webshopCredentialsId);
    
    const getWebshopCredentialDetails = (params) => {
        if (!params) return;
    
        const newwebshopCredentialsId = parseInt(params.row.id);
        // Only update state if the webshopCredentialsId has actually changed
        if (webshopCredentialsIdRef.current !== newwebshopCredentialsId) {
            setWebshopCredentialsId(newwebshopCredentialsId);
            webshopCredentialsIdRef.current = newwebshopCredentialsId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    const columns = [
        {field: "description", headerName: t("webshop") , flex:2, cellClassName: "webshop", renderCell : (cellValues) => {
                return (
                    <>
                    <Box sx={{ paddingRight: '10px' }}>
                        {cellValues.row.webshop_type === "magento" ? <MagentoLogo width={30} height={30}/> : null}
                        {cellValues.row.webshop_type === "shopify" ? <ShopifyLogo  width={30} height={30}/> : null}
                        {cellValues.row.webshop_type === "woocommerce" ? <WooCommerceLogo width={30} height={30}/> : null}
                        {cellValues.row.webshop_type === "bol" ? <BolLogo width={30} height={30}/> : null}
                        {cellValues.row.webshop_type === "ccvshop" ? <CcvshopLogo width={30} height={30}/> : null}
                        {cellValues.row.webshop_type === "other" ? <QuestionMarkOutlined width={30} height={30}/> : null}
                    </Box>
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
                    </>
                )
        }},
        ...isFulfilment ? [{field: "relation", headerName: t("relation") , flex:2, cellClassName: "relation", renderCell : (cellValues) => {
            return (
                <Text>{cellValues.value?.name}</Text>
            )
        }}] : [],
        {field: "warehouse", headerName: t("warehouse") , flex:2, cellClassName: "warehouse", renderCell : (cellValues) => {
            return (
                <Text  >{cellValues.value?.name}</Text>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <RelationWebshopIntegrationCard skeleton={params.row.skeleton ?? false} {...params.row}/>
                )
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];

    return (
        props.isActive ?
            <Box
                sx={{
                    height:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingTop:0,
                        paddingX:0,
                    }
                }}
            >
                
                <ShDataGrid2 key={theme.palette.mode}
                    content={useGetWebshopCredentialsQuery}
                    config={config}
                    gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                    title={t("webshop_integrations")}
                    gridOptions={gridOptions} 
                     
                    columns={columns}
                    rows={data}
                    onRowClick={getWebshopCredentialDetails}>
                </ShDataGrid2>


                <MpAddWebshop onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
                <SpWebshopCredentialsDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    webshopCredentialsId={webshopCredentialsId}
                    // data={credentialData}                
                ></SpWebshopCredentialsDetails>
            </Box>
        : null
    )
};

export default All;