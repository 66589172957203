import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme"; 
import Text from "../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import { useTranslation } from "react-i18next";
import { useGetProductFieldsQuery } from "../../../../../newapi/product/productFieldSlice";
import SpProductValueDetails from "../../../../../components/global/Sidepanels/SpProductValueDetails/SpProductValueDetails";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import MAddProductField from "../../../../../components/global/Modals/MAddProductField";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useSelector } from "react-redux";

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("add_field")}</Shbutton>
        </ButtonGroup>
    );
}


let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(1);
    const [rowSelected, setRowSelected] = useState(false);
    const [config, setConfig] = useState({"all_credentials": true});
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);
    // const { data: credentialData, error, isLoading } = useGetWebshopCredentialsSingleQuery(
    //    selectedRowId,
    //     {
    //         skip: !rowSelected
    //     }
    // );
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    const [productFieldId, setProductFieldId] = useState();
    const productFieldIdRef = useRef(productFieldId);
    
    const getProductFieldDetails = (params) => {
        if (!params) return;
    
        const newProductFieldId = parseInt(params.row.id);
        // Only update state if the webshopCredentialsId has actually changed
        if (productFieldIdRef.current !== newProductFieldId) {
            setProductFieldId(newProductFieldId);
            productFieldIdRef.current = newProductFieldId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const columns = [
        {field: "name", headerName: t("name"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.name
            }
        },
        {field: "valueType", headerName: t("value_type"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return t(params.row.valueType)
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return <ShTextSkeleton/>
            }
        }
    ];

    return (
        props.isActive ?
            <Box
                sx={{
                    height:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingTop:0,
                        paddingX:0,
                    }
                }}
            >
                
                <ShDataGrid2 key={theme.palette.mode}
                    content={useGetProductFieldsQuery}
                    config={config}
                    gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                    title={t("custom_fields")}
                    gridOptions={gridOptions} 
                     
                    columns={columns}
                    rows={data}
                    onRowClick={getProductFieldDetails}>
                </ShDataGrid2>


                <MAddProductField onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
                <SpProductValueDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    productFieldId={productFieldId}
                    // data={credentialData}                
                ></SpProductValueDetails>
            </Box>
        : null
    )
};

export default All;