// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import { ReactComponent as ShopifyLogo } from '../../../../../styles/svg/shopify.svg';
import { ReactComponent as WooCommerceLogo } from '../../../../../styles/svg/woocommerce.svg';
import { ReactComponent as BolLogo } from '../../../../../styles/svg/bol.svg';
import { ReactComponent as CcvshopLogo } from '../../../../../styles/svg/ccvshop.svg';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    
        const columns = [
            {title: "Shopify", logo: <ShopifyLogo width={60} height={60}/>, key: "shopify"},
            {title: "Woocommerce", logo: <WooCommerceLogo width={60} height={60}/>, key: "woocommerce"},
            {title: "Bol.com", logo: <BolLogo width={60} height={60}/>, key: "bol"},
            {title: "CCV Shop", logo: <CcvshopLogo width={60} height={60}/>, key: "ccvshop"},
        ]

        const handleOptionSelect = (option) => {
            const credentialsObj = getCredentialsFromType(columns[option].key);
            props.handleDataUpdate({ credentials: credentialsObj });
            props.handleDataUpdate({ selectedOption: option, webshop_type: columns[option].key });
            props.handleNext("tabCredentials");
        };

        const getCredentialsFromType = (type) => {
            switch(type) {
                case "woocommerce": 
                    return {url: "", consumer_key: "", consumer_secret: ""}; 
                case "shopify": 
                    return {shop: "", client_key: "", access_token: ""};
                case "bol": 
                    return {client_id: "", client_secret: "", vvb_bool: "false"};
                case "ccvshop":
                    return {url: "", public_key: "", secret_key: ""};
                default:
                    return {};
            }
        }
    
        return (
            <div>
                <SelectGroup selected={-1} setSelected={handleOptionSelect} options={columns} image={true} />
            </div>
        );
    };

const ChooseWebshopType = (props) => {
    return (
        <ProgressionTab tabId="tabChooseType" step={1} maxSteps={4}>
            <Content/>
        </ProgressionTab>
    )
}

export default ChooseWebshopType;