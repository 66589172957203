import { Box, useTheme } from "@mui/material";
import Subheading from "../../../../components/theme/text/Subheading";
import Text from "../../../../components/theme/text/Text";
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useRef, useState } from "react";

import { useGridApiRef } from "@mui/x-data-grid";
import OverflowRow from "../../../../components/theme/OverflowRow";

// import { getOrderStatusCounts } from "../../../../api/orders/orders";
import { useGetDashboardOrderStatusCountsQuery } from "../../../../newapi/order/ordersSlice";
import OutboundOrdersView from "./views/Orders";
import OutboundActionRequiredView from "./views/ActionRequired";
import OutboundPriorityView from "./views/Priority";
import OutboundReplenishView from "./views/Replenish";
import { useTranslation } from 'react-i18next';
import TabView from "../../../../components/theme/TabViews/TabView";
import TabView3 from "../../../../components/theme/TabView3/TabView3";
import { warehouseOutboundOrdersConfig } from "./warehouseOutboundOrdersConfig";

const OutboundOrders = () => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const ordersRef = useGridApiRef();
    // console.log("ordersref",ordersRef.current);
    const actionRequiredRef = useGridApiRef();
    const priorityRef = useGridApiRef();
    const replenishRef = useGridApiRef();
    const ref = useRef();

     const [ data, setData] = useState();

    const { data : incData, isLoading } = useGetDashboardOrderStatusCountsQuery();
    useEffect(() => {
    
        if (!isLoading && incData) {
            setData(incData);
        }
    }, [incData, isLoading]);

    useEffect(() => {
        console.log("pipi");
        if(ref.current != null) {
            console.log("awa", ref.current.getBoundingClientRect());
        }
    }, [ref.current])
  

    return (
        <Box sx={{background:"", width:1,paddingTop:9}}>
            {ready ?
            <>
                <OverflowRow>

                    {/* <Box
                        sx={{
                            padding: 3,
                            background: `${theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[0]}`,
                            flex: 1,
                            borderRadius: 4,
                            "@media screen and (max-width: 47.9375em)": {
                                minWidth: "calc(100% - 96px)",
                            }
                        }}
                    >
                        <Subheading>All</Subheading>
                        <Box
                            sx={{
                                paddingTop: 4,
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <Box display={"flex"} flexDirection={"column"}>
                                <Text light>
                                    {t('shipped')}
                                </Text>
                                <Subheading bold>
                                    {data?.All?.shipped}
                                </Subheading>
                            </Box>

                            <Box display={"flex"} flexDirection={"column"} paddingLeft={4}>
                                <Text light>
                                {t('open')}
                                </Text>
                                <Subheading bold>
                                    {data?.All?.open}
                                </Subheading>
                            </Box>

                            <Box display={"flex"} flexDirection={"column"} paddingLeft={4}>
                                <Text variant="red">
                                    {t('overdue')}
                                </Text>
                                <Subheading bold variant="red">
                                    {data?.All?.overdue}
                                </Subheading>
                            </Box>
                        </Box>
                    </Box> */}
                    {data?.relations?.map((relation,index) => (
                        <Box
                            key={"relationind"+index}
                            sx={{
                                padding: 3,
                                background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                                flex: 1,
                                borderRadius: 4,
                                "@media screen and (max-width: 47.9375em)": {
                                    minWidth: "calc(100% - 96px)",
                                }
                            }}
                        >
                            <Subheading>{relation.name === "total" ? t("total") : relation.name}</Subheading>
                            <Box
                                sx={{
                                    paddingTop: 3,
                                    display: "flex",
                                    gap:6,
                                    // justifyContent: "space-around"
                                }}
                            >
                                <Box display={"flex"} flexDirection={"column"}>
                                    <Text light>
                                        {t('shipped')}
                                    </Text>
                                    <Subheading bold>
                                        {relation.shipped}
                                    </Subheading>
                                </Box>

                                <Box display={"flex"} flexDirection={"column"}>
                                    <Text light>
                                        {t('open')}
                                    </Text>
                                    <Subheading bold>
                                        {relation.open}
                                    </Subheading>
                                </Box>

                                <Box display={"flex"} flexDirection={"column"}>
                                    <Text noBreak variant="red">
                                        {t('overdue')}
                                    </Text>
                                    <Subheading  variant="red">
                                        {relation.overdue}
                                    </Subheading>
                                </Box>
                            </Box>
                        </Box>
                    ))}

                </OverflowRow>

                <TabView3
                    tabConfig={warehouseOutboundOrdersConfig}
                    // ref={ref}
                    sx={{
                        background:"green",
                        minHeight:"calc(100% - 190px)",
                        background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        position:"relative",
                        zIndex:2,
                        display:"flex",
                        flexDirection:"column",

                        "&:before" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            left:0,
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            top:"40px",
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transition:"250ms"
                        },

                        "&:after" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            top:"40px",
                            right:0,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            transition:"250ms"
                        },

                        "@media screen and (max-width: 47.9375em)" : {
                            "&:after, &:before" : {
                                top:"-0px"
                            }
                        }
                    }}
                >
                    <>
                        <OutboundOrdersView config={warehouseOutboundOrdersConfig.orders}/>
                        {/* <ActionRq */}
                        <OutboundActionRequiredView config={warehouseOutboundOrdersConfig.actionRequired} />
                        <OutboundReplenishView config={warehouseOutboundOrdersConfig.replenish} />
                        <OutboundPriorityView config={warehouseOutboundOrdersConfig.priority}/>
                    {/* <ActionReq */}
                    </>
                </TabView3>
                {/* </TabView> */}
            </>
            :null}
        </Box>
    );
};

export default OutboundOrders;