import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import Text from "../../../../theme/text/Text";

import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import Subheading from "../../../../theme/text/Subheading";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from "react-i18next";
import { useEditShipperMutation } from "../../../../../newapi/shipper/shipperSlice";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import * as yup from "yup";
import { yupRequired } from "../../../../../utils/validation";
import ShTimePicker from "../../../../theme/ShTimePicker";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

// import classNames from "classnames";
function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

dayjs.extend(utc);

const Pickup = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    
    const [shipperInfo, setShipperInfo] = useState({
        state: "",
        id: 0,
        name: "",
        reference: null,
        type: null,
        standard_time: "",
        standard_time_saturday: "",
        standard_time_sunday: "",
        maxWeight: 0,
        maxLength: 0,
        maxWidth: 0,
        maxHeight: 0,
        maxVolume: 0,
        alternativeAddress: null,
        street: null,
        house_nr: null,
        house_nr_extra: null,
        postal: null,
        city: null,
        country: null,
        company: null,
        contactperson: null,
        public_key: null,
        private_key: null
    });

    useEffect(() => {
        if(props.data){
            let myData  = {...props.data};
            myData["standard_time"] = dayjs.utc(myData["standard_time"]);  // This keeps the time as is, parsed as-is.
            myData["standard_time_saturday"] = dayjs.utc(myData["standard_time_saturday"]);
            myData["standard_time_sunday"] = dayjs.utc(myData["standard_time_sunday"]);
            setShipperInfo(myData);
        }
    },[props.data]);
    
    const handleChange = (key, value) => {
        setShipperInfo({
          ...shipperInfo,
          [key]: value
        });
      };

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        company: yupRequired('company', t),
        contactperson: yupRequired('contactperson', t),
    };
    
    const schema = yup.object().shape(rules);
    
    const [EditShipper,{isLoading}] = useEditShipperMutation();
    const handleSaveClick = async () => {
        console.log(shipperInfo);
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                setFieldErrors({});
                await schema.validate(shipperInfo, { abortEarly: false });
                const data = await EditShipper(shipperInfo).unwrap()
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                if (err.name === 'ValidationError') {
                    // Create an errors object where keys are field names and values are error messages
                    const errors = err.inner.reduce((acc, currentError) => ({
                        ...acc,
                        [currentError.path]: currentError.message
                    }), {});
                
                    // Set the validation errors in the state
                    setFieldErrors(errors);
                } else {
                    // Handle other errors, such as network errors
                    showToastMessageRequestError(t("update_failed"), t, {success: false, error: err.error});
                }
            }
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
          }   
        }        
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions></GridActions>,
        },false);

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
                <Dropdown title={t("basic")} open>
                    <Box sx={{
                        display:"flex",
                        justifyContent:"space-between",
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column"
                        }
                    }}>
                        
                        {/* Two columns */}
                        
                        <Box flex={4}
                            sx={{
                                display:"flex",
                                gap:4,
                                justifyContent:"flex-start"
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                <Box lineHeight={"38px"}>
                                    <Text>{t("company")}</Text>
                                </Box>
                            </Box>
                            <Box>
                                <Box display="flex"
                                    height="32px"
                                    // maxWidth="400px"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[100]}
                                    sx={{
                                        marginTop:0.5,
                                        marginBottom:0.5,
                                        justifyContent:"space-between",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flexDirection:"column"
                                        }
                                    }}
                                >
                                    <ShValidatedInput
                                        name="company"
                                        value={shipperInfo?.company}
                                        onChange={handleChange}
                                        error={fieldErrors.company}
                                    />
                                </Box>
                                
                            </Box>
                        </Box>

                        <Box flex={4}
                            sx={{
                                display:"flex",
                                gap:4,
                                justifyContent:"flex-start"
                            }}
                        > 
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1,
                                        // width:1
                                    }
                                }}
                            >
                                <Box lineHeight={"38px"}>
                                    <Text>{t("contactperson")}</Text>
                                </Box>
                               
                            </Box>
                            <Box>
                                <Box display="flex"
                                    height="32px"
                                // maxWidth="400px"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[100]}
                                    sx={{
                                        marginTop:0.5,
                                        marginBottom:0.5,
                                        justifyContent:"space-between",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flexDirection:"column"
                                        }
                                    }}
                                >
                                    <ShValidatedInput
                                        name="contactperson"
                                        value={shipperInfo?.contactperson}
                                        onChange={handleChange}
                                        error={fieldErrors.contactperson}
                                    />
                                </Box>
                               
                            </Box>
                        </Box>
                    </Box>
                </Dropdown>
                <Dropdown title={t("alternative_address")} open>
                <Box paddingX={1.5}
                    sx={{
                        "@media screen and (max-width: 47.9375em)" : {
                            display:"none"
                        }
                    }}
                >
                    <Subheading variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{t("alternativeaddress_onoff")}</Subheading>
                    <ShSwitch></ShSwitch>
                </Box>
                {/* Details */}
                <Box 
                    sx={{
                        display:"flex",
                        borderRadius:3,
                        overflow:"hidden",
                        marginTop:3,
                        marginBottom:1,
                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                        "@media screen and (max-width: 47.9375em)" : {
                            marginTop:0,
                            background:"none",
                            flexDirection:"column",
                            overflow:"unset"
                        }
                    }}
                >
                    {/* Editable content */}
                    <Box padding={1.5} paddingRight={4} paddingBottom={3} flex={1}
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                paddingY:0,
                                paddingRight:1.5,
                            }
                        }}
                    >
                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:3,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column",
                                    gap:0.5,
                                    alignItems:"flex-start",
                                }
                            }}
                        >
                            <Subheading>{t("details")}</Subheading>
                            <Text>{props.data?.deliveryName}</Text>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                marginTop:3,
                                gap:3,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column",
                                    gap:1,
                                    alignItems:"flex-start",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("street")}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text" value={props.data?.deliveryStreet} sx={{px: 2, flex:1, height:32,boxSizing:"border-box"}} />
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("house_nr")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        backgroundColor={colors.grey[200]}
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text" 
                                        value={props.data?.deliveryHousenumber}
                                            sx={{
                                                px: 2, 
                                                width:56, 
                                                height:32,
                                                boxSizing:"border-box",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flex:1,
                                                    width:"unset"
                                                }
                                            }}/>
                                        <Box 
                                            sx={{
                                                marginY:0.5,
                                                height:24,
                                                width:"1px",
                                                background:theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[300]
                                            }}
                                        />
                                        <InputBase type="text"value={props.data?.deliveryHousenumberAddition} sx={{px: 2, width:56, height:32,boxSizing:"border-box"}} />
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("postal_code")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text" 
                                        value={props.data?.deliveryPostalCode}
                                            sx={{
                                                px: 2, 
                                                width:96, 
                                                height:32,
                                                boxSizing:"border-box",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flex:1,
                                                    width:"unset"
                                                }
                                            }}/>
                                        {/* <Box 
                                            sx={{
                                                marginY:0.5,
                                                height:24,
                                                width:"1px",
                                                background:theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[300]
                                            }}
                                        />
                                        <InputBase type="text" sx={{px: 2, width:56, height:32,boxSizing:"border-box"}} /> */}
                                    </Box>
                                </Box>
                            </Box>

                            
                        </Box>


                        <Box
                            sx={{
                                display:"flex",
                                marginTop:3,
                                gap:3,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column",
                                    gap:1,
                                    alignItems:"flex-start",
                                    marginTop:4
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("city")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text"
                                        value={props.data?.deliveryCity}
                                            sx={{
                                                px: 2, 
                                                flex:1, 
                                                height:32,
                                                boxSizing:"border-box",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flex:1,
                                                    width:"unset"
                                                }
                                            }}/>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("country")}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text" sx={{px: 2, flex:1, height:32,boxSizing:"border-box"}}
                                        value={props.data?.deliveryCountry} />
                                    </Box>
                                </Box>
                            </Box>

                            
                        </Box>
                    </Box>
                
            </Box>
            </Dropdown>
            <Dropdown title={t("pickup_times")} subTitle={t("pickup_times_description")} open={window.innerWidth > 767} isLast sx={{paddingBottom:"0px"}}>
                {/* <Divider></Divider> */}
                    <Box sx={{
                        display:"flex",
                        flexDirection:"column"
                    }}>
                        {/* Two columns */}
                        <Box
                            sx={{
                                display:"flex",
                                // gap:1,
                                justifyContent:"flex-start",
                                flexDirection:"column",
                                gap:1.5,
                            }}
                        >
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                                <Box 
                                sx={{
                                    width:1,
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                    gap:7
                                }}
                            >

                                <Box
                                    sx={{
                                        width:120,
                                    }}
                                >
                                    <Text>{t("mon_fri")}</Text>&nbsp;
                                </Box>
                                
                                <ShTimePicker
                                    value={shipperInfo.standard_time}
                                    onChange={(e) => {
                                        handleChange('standard_time', dayjs(e))
                                    }}
                                />

                                {/* <Box    
                                    sx={{
                                        flex:1,
                                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                        borderRadius:4,
                                        display:"flex",
                                        height:32
                                    }}
                                >
                                    <TimePicker btnSize={"24"} 
                                    showTimeSelect showTimeSelectOnly
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    format="HH:mm"
                                    value={data.mon_fri}
                                    onChange={(e) => handleChange('standard_time', e)}/>
                                </Box> */}
                                </Box>
                                <Box 
                                    sx={{
                                        width:1,
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"space-between",
                                        gap:7,
                                    }}
                                >

                                    <Box
                                        sx={{
                                            width:120,
                                        }}
                                    >
                                        <Text>{t("saturday")}</Text>&nbsp;
                                    </Box>
                                    
                                    <ShTimePicker
                                        value={shipperInfo.standard_time_saturday}
                                        // value={dayjs(data.standard_time_saturday, "HH:mm")}
                                        onChange={(e) => {
                                            handleChange('standard_time_saturday', dayjs(e))
                                        }}
                                    />
                                    
                                </Box>
                                <Box 
                                    sx={{
                                        width:1,
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"space-between",
                                        gap:7
                                    }}
                                >

                                    <Box
                                        sx={{
                                            width:120,
                                        }}
                                    >
                                        <Text>{t("sunday")}</Text>&nbsp;
                                    </Box>
                                    <ShTimePicker
                                        value={shipperInfo.standard_time_sunday}
                                        // value={dayjs(data.standard_time_sunday, "HH:mm")}
                                        onChange={(e) => {
                                            handleChange('standard_time_sunday', dayjs(e))
                                        }}
                                    />
                                    
                                </Box>
                            {/* </LocalizationProvider>               */}
                        </Box>
                    </Box>
                </Dropdown>
                <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
            <GridActions
                id={props.data?.id}
                 action={handleSaveClick}
                // active={active}
                // blocked={blocked}
                // remark={remark}
                // carrier={carrier}
                // quarantine={quarantine}
                // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
            />
            </Box>
        </Box>
        
    );
}


export default Pickup;