import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import ShLabel from "../../../../../components/theme/label/ShLabel";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import {useGetWarehouseLocationQuery,useGetWarehouseLocationsQuery} from "../../../../../newapi/warehouse/warehouselocationSlice"
import SPLocationDetails from "../../../../../components/global/Sidepanels/SpLocationDetails/SpLocationDetails";
import WarehouseLocationCard from "../../../../../components/theme/cards/stock/WarehouseLocationCard";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import MAddWarehouseLocation from "../../../../../components/global/Modals/MAddWarehouseLocation";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('new_location')}
            </Shbutton>
        </ButtonGroup>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const WHLocations = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // const [data, setData] = useState([]);
    // const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [locationIsExtended, setLocationIsExtended] = useState(false);


    // const [locationId, setLocationId] = useState();
    // const locationIdRef = useRef(locationId);
    // useEffect(() => {
    //     locationIdRef.current = locationId;
    // }, [locationId]);

    // const { data: locationData, isLoading: isLoadingLocation } = useGetWarehouseLocationQuery(locationId,
    //     {
    //         skip: !locationId
    //     });
        
    // const getLocationDetails = (params) => {
    //     if (!params) return;
    //     if (typeof locationIdRef.current !== "undefined" &&  parseInt(locationIdRef.current) === parseInt(params.id)) {
    //         setLocationIsExtended(true);
    //     } else {
    //         setLocationId(params.id);
    //     }
    // };

    const [locationId, setLocationId] = useState();
    const locationIdRef = useRef(locationId);
    
    const getLocationDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.id);
        // Only update state if the productId has actually changed
        if (locationIdRef.current !== newProductId) {
            setLocationId(newProductId);
            locationIdRef.current = newProductId; // Update the ref to the new value
        }
        setLocationIsExtended(true);
    };
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    

    const handleOpen = () => {
        setAddModalOpen(true);
    }

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions handleOpen={handleOpen}></GridActions>
        });

        return () => {
            
        }
    },[]);

    const columns = [
        {
            field: "location",
            headerName: t("location"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton variant={"yellow"}/>
                }
                return (
                    <ShLabel clickable={true} size={32} fitted variant={"yellow"}>
                        {params.row.location}
                    </ShLabel>
                );
            }
        },
        {
            field: "level",
            headerName: t("level"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.warehouseLevel?.description
            },
        },
        {
            field: "warehouseLocationType",
            headerName: t("location_type"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.warehouseLocationType?.description
            },
        },
        {
            field: "warehouseZone",
            headerName: t("zone"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.warehouseZone?.description
            }
        },
        {
            field: "reachType",
            headerName: t("reachtype"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.reachType.description
            },
        },
        {
            field: "availableStock",
            headerName: t("available_stock"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return  params.row.availableStock//cellValues.row.product.relation.name
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            // hide: true,
            renderCell: (params) => {
                // console.log(cellValues.row);
                return (
                    <WarehouseLocationCard skeleton={params.row.skeleton ?? false} {...params.row}/>
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >

                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetWarehouseLocationsQuery}
                gridActions={<GridActions handleOpen={handleOpen}></GridActions>} 
                title={t('overview')}
                gridOptions={gridOptions} 
                onRowClick={getLocationDetails}
                columns={columns}>
            </ShDataGrid2>
            <SPLocationDetails
                isExtended={locationIsExtended}
                setIsExtended={setLocationIsExtended}
                locationId={locationId}
            ></SPLocationDetails>
            <MAddWarehouseLocation onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}

// const WarehouseLocations = (apiRef) => {
//     return {
//         title: "Warehouse locations",
//         icon: <WarehouseIcon/>,
//         view: <WarehouseLocationsBody apiRef={apiRef}/>,
//         isSingleDataGrid: true,
//         apiRef: apiRef,
//         gridOptions:gridOptions,
//         mobileActions:<GridActions></GridActions>
//     }
// }

 export default WHLocations;