import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Drawer, Typography, useTheme } from '@mui/material';
import { tokens } from "../../../theme";
import cx from 'classnames';

import Shbutton from "../buttons/Shbutton";
import Subheading from "../text/Subheading";
import Text from "../text/Text";
import Spheadingrow from "../sidepanel/Spheadingrow";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import Progressbar from "../Progressbar";
import ShLabel from "../label/ShLabel";

import GridFilter2 from "../datagrid2/GridFilter2";
import { GridFilterProvider } from "../../../contexts/GridFilterContext";

const TabViewBodyContent = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const activeTab = props.activeTab;
    const breakPoint = props.breakPoint ?? "phone";
    
    // State to track if the app is displayed in standalone mode
    const [isStandAlone, setIsStandAlone] = useState(
        window.matchMedia('(display-mode: standalone)').matches
    );

    useEffect(() => {
        const matcher = window.matchMedia('(display-mode: standalone)');
        const onChange = (e) => setIsStandAlone(e.matches);

        // Listen for changes
        matcher.addListener(onChange);

        // Cleanup
        return () => {
            matcher.removeListener(onChange);
        };
    }, []);
    
    useEffect(() => {
        return () => {
        }
    },[]);


    const getEmFromBreakPoint = () => {
        if(breakPoint === "phone") {
            return 47.9375
        } else if(breakPoint === "tablet") {
            return 63.9375
        }
    }


    const getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
          if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
              return;
            }
            seen.add(value);
          }
          return value;
        };
    };

    const subTitleBottomPadding = ((
            typeof activeTab?.isSingleDataGrid !== "undefined" && activeTab?.isSingleDataGrid) &&
            (activeTab?.gridOptions?.hide === "undefined" || !activeTab?.gridOptions?.hide)) ||
            (typeof activeTab?.options !== "undefined" && activeTab?.options.length > 0 ) ? 1 :0

    const handleClose = useCallback(() => props.setSubpanelActive(false), [props.setSubpanelActive]);
    return (
        <Box
            className="sp-bodyview-content-box"
            sx={{
                height:1,
                display:"flex",
                flexDirection:"column",
                alignItems:"flex-start",
                [`@media screen and (max-width: ${getEmFromBreakPoint()}em)`] : {
                    zIndex:1058,
                    background:`${colors.bg["tertiary"]}`,
                    // overflow:"hidden"
                }
            }}
        >
            {!props.asTab ?
                <Box
                    component="header"
                    sx={{
                        display:"none",
                        [`@media screen and (max-width: ${getEmFromBreakPoint()}em)`] : {
                            display:"block",
                            paddingTop:1.5,
                            paddingBottom:4,
                            background:`${colors.bg["tertiary"]}`,
                            width:1,
                            position:"relative"
                        }
                    }}
                >
                    <Box
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:3,
                        }}
                    >
                        {theme.palette.mode === "dark" ? 
                            <Shbutton variant="text" onClick={() => props.setSubpanelActive(false)} color="blue">Go back</Shbutton> 
                            : 
                            <Shbutton variant="text" onClick={() => props.setSubpanelActive(false)} color="primary">Go back</Shbutton>
                        }


                        {typeof activeTab?.progressBar !== "undefined" || activeTab?.status !== "undefined" || activeTab?.topRight !== "undefined" ? 
                            <Box
                                sx={{
                                    flex:1,
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"flex-end"
                                }}
                            >
                                {typeof activeTab?.progressBar !== "undefined" ?
                                    <Progressbar 
                                        ref={activeTab?.progressBar.ref}
                                        progress={activeTab?.progressBar.progress}
                                        status={activeTab?.progressBar.status ?? {variant:"green",value:""}}
                                        size={32}
                                        isHeader
                                        ></Progressbar>
                                    // <Box></Box>
                                :null}
                                {typeof activeTab?.status !== "undefined" ?
                                    <>
                                        <ShLabel
                                            isHeader
                                            fitted
                                            type={activeTab?.status.type ?? "light"}
                                            variant={activeTab?.status.variant ?? "blue"}
                                            palette={activeTab?.status.palette}
                                            size={32}
                                        >{activeTab?.status.value}</ShLabel>
                                    </>
                                :null}
                                {typeof activeTab?.topRight !== "undefined" ?
                                    activeTab?.topRight
                                :null}
                            </Box>
                        :null}
                    </Box>
                    {/* <Shbutton variant="text" color="blue">Go back</Shbutton> */}
                    
                    <Box
                        sx={{
                            paddingTop:2,
                            paddingBottom:subTitleBottomPadding,
                            paddingX:3,
                        }}>
                        <Subheading>{activeTab?.title}</Subheading>
                        {typeof activeTab?.subTitle !== "undefined" ? 
                            <Text light>{activeTab?.subTitle}</Text> 
                        : null}
                    </Box>

                    {typeof activeTab?.isSingleDataGrid !== "undefined" && activeTab?.isSingleDataGrid ? 
                        activeTab?.gridOptions?.hide === "undefined" || !activeTab?.gridOptions?.hide ?
                            <Box marginX={3}>
                                {/* {console.log("actapir",activeTab?.apiRef.current)} */}
                                <GridFilter2
                                    gridOptions={activeTab?.gridOptions}>
                                </GridFilter2>
                            </Box>
                        : null
                    : null}

                    {/* In case tehre are headings */}
                    {typeof activeTab?.options !== "undefined" && activeTab?.options.length > 0 ?  
                        <Box marginX={3}>
                            <Spheadingrow isSubRow options={activeTab?.options}></Spheadingrow>
                        </Box> 
                    : null}
                
                </Box>
            : null }

            <Box 
                component="main"
                sx={{
                    flex:1,
                    width:1,
                    background:`${theme.palette.mode === "dark" ? props.lighterBody ? colors.grey[300] : colors.grey[200] : colors.grey[0]}`,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    overflow:"auto",
                    [`@media screen and (max-width: ${getEmFromBreakPoint()}em)`] : {
                        // overflow:!(typeof activeTab?.isSingleDataGrid !== "undefined" && activeTab?.isSingleDataGrid) ? "hidden" : "inherit",
                        // paddingBottom:(typeof activeTab?.isSingleDataGrid !== "undefined" && activeTab?.isSingleDataGrid) ? "204px" : null,
                        background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    }
                }}>
                    {props.children}
            </Box>

            {/* Footer */}
            {!props.hideFooter ? 
                <Box
                    component="footer"
                    sx={{
                        
                        display:"none",
                        [`@media screen and (max-width: ${getEmFromBreakPoint()}em)`] : {
                            display:"block",
                            width:1,
                            paddingBottom: isStandAlone ? "32px" : "16px",
                            display:"flex",
                            // paddingRight:3,
                            zIndex:1058,
                            position:"relative",
                            paddingTop:2,
                            // background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            backdropFilter:"blur(12px)",
                            // marginTop:"-80px"
                            alignItems:"center",
                            position:"fixed",
                            bottom:0,
                        }
                    }}
                    >

                        <Shbutton isCancel={true} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>Cancel</Shbutton>
                        {typeof activeTab?.mobileActions !== "undefined" ? 
                            activeTab?.mobileActions
                        : <Box flex={1}></Box>}
                        {/* <Shbutton isSidepanel={true} color="primary" variant="contained" endIcon={<ArrowRightAltIcon/>}>Edit</Shbutton> */}
                </Box>
            :null}
        </Box>
    );
}

const TabViewBody= (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const toggleDrawer = (open) => (event) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event).key === 'Tab' ||
          (event).key === 'Shift')
      ) {
        return;
      }

      props.setSubpanelActive(open);
    };
    
    const breakPoint = props.breakPoint ?? "phone";

    const getEmFromBreakPoint = () => {
        if(breakPoint === "phone") {
            return 47.9375
        } else if(breakPoint === "tablet") {
            return 63.9375
        }
    }
    
    return (
        <Box
            className="tv-body"
            sx={props.viewType === "tabs" ?{
                width:1,
                "@media screen and (max-width: 47.9375em)" : {
                    height: "unset"
                }
            }: props.viewType === "bodytabs" ? {
                // background:"green",
                flex:1
            } : {
                width:1,
            }}
        >
            {!props.asTab ?
                <>
                <Drawer
                    anchor="right"
                    open={props.subpanelActive}

                    onClose={toggleDrawer(false)}
                    // onOpen={toggleDrawer(true)}
                    transitionDuration={{appear: 500,enter:500,exit:500}}
                    sx={{
                        "& .MuiPaper-elevation" : {
                            width:1
                        },
                        "@media screen and (min-width: 63.9375em)" : {
                            display:"none"
                        }
                    }}
                >
                    <TabViewBodyContent {...props}>
                        <GridFilterProvider activeChild={props.activeChild}>
                            {props.children}
                        </GridFilterProvider>
                    </TabViewBodyContent>
            
                </Drawer>
                </>
            : null }

            <Box
                sx={{
                    height:"100%",
                    "@media screen and (max-width: 47.9375em)" : {
                        display:`${!props.asTab ? "none" : null }`
                    }
                }}
            >
                <TabViewBodyContent {...props}>
                    <GridFilterProvider activeChild={props.activeChild}>
                        {props.children}
                    </GridFilterProvider>
                </TabViewBodyContent>
            </Box>
               
        </Box>
        // </SHTransition>
    );
};
export default TabViewBody;