import { Box, Button, IconButton, InputBase, TextField, Typography, useTheme } from "@mui/material";
import { Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useAuthHeader, useSignIn } from "react-auth-kit";
import axios, { AxiosError } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../../components/theme/text/Heading";
import Text from "../../components/theme/text/Text";
import Shbutton from "../../components/theme/buttons/Shbutton";

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';

import { tokens } from "../../theme";
import Subheading from "../../components/theme/text/Subheading";
import cx from 'classnames';
import { useLoginMutation } from "../../newapi/user/authentication/authApiSlice";
import { useTranslation } from "react-i18next";
import { selectCustomers, selectRelations } from "../../newapi/user/authentication/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { resetSideBar, setSelectedEnvironment } from "../../newapi/global/settingsSlice";
import { ENV_TYPES } from "../../components/global/Sidebar";
import {persistor} from "../../newapi/store";
import { CheckOutlined, ConstructionOutlined } from "@mui/icons-material";
import { motion } from 'framer-motion';
import { useRequestPasswordResetMutation } from "../../newapi/user/passwordresetSlice";

// import { login } from "../../api/global/user";
// import { useAuth } from "../../authentication/AuthState";
const initialValues = {
    email: "",
}

const userSchema = yup.object().shape({
    email: yup
    .string()
    .email("Ongeldig e-mailadres")
    .required("required"),
});

const Forgotpassword = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    // const signIn = useSignIn();
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [passwordType, setPasswordType] = useState("password");

    const [RequestPasswordReset, { isSuccess, isLoading, isUninitialized, isError, error }] = useRequestPasswordResetMutation();


    const dispatch = useDispatch();

    const handleFormSubmit = async (values) => {
        setErrorMessage("");

        let resetVals = {email: values.email};

        try {
            await RequestPasswordReset(resetVals);
        } catch (error) {
            console.error('Reset request failed:', error.message);
        }

    }

    useEffect(() => {
        if(isError) { 
            setErrorMessage("Reset request failed");
            return;
        }
        

        
    },[isSuccess, isError]);


    return (
        <Box width={1} 
            sx={{
                overflow:"hidden",
                background:"#fff",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                flexDirection:"column",
                background:isSuccess ? "rgba(10,178,189,.05)" : "#E5EEFF" ,
                transition:"500ms",
                "@media screen and (max-width: 47.9375em)" : {
                    alignItems:"flex-end"
                }
            }}
        >

            <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
                style={{
                    padding: "32px",
                    borderRadius: "24px",
                    background: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    boxShadow: "0px 4px 8px rgba(49,65,95,.25)",
                    paddingTop:"40px",
                }}
            >

                {!isSuccess ? (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.5 }}
                        transition={{ duration: 0.5 }}
                        style={{
                            width: 64,
                            height: 64,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 16,
                            marginBottom: 32,
                        }}
                    >
                        <img src={require('../../styles/img/logo.png')} alt="Dashboard" style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: 16 }} />
                    </motion.div>
                ) : (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.5 }}
                        transition={{ duration: 0.5 }}
                        style={{
                            width: 64,
                            height: 64,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 16,
                            marginBottom: 32,
                            background: tokens("light").green[100],
                            color: tokens("light").green[400],
                        }}
                    >
                        <CheckOutlined />
                    </motion.div>
                )}
                
                <Typography variant="h1" fontWeight="1000" fontFamily="Roboto, sans-serif"
                    sx={{
                        color:"#31415f",
                        textOverflow:"ellipsis",
                        whiteSpace:"nowrap",
                        overflow:"hidden",
                        fontSize:"32px",
                        "@media screen and (max-width: 47.9375em)" : {
                            fontSize:21
                        }
                    }}
                >
                    {isSuccess ? 
                    t("forgot_password_request_success")
                    :
                    t("forgot_password")
                    }
                </Typography>
                <Typography variant="p" fontWeight="400" fontFamily="Roboto, sans-serif"
                    sx={{
                        color:"#88869D",
                        textOverflow:"ellipsis",
                        whiteSpace:"nowrap",
                        overflow:"hidden",
                        fontSize:"17px",
                        marginBottom:4,
                        "@media screen and (max-width: 47.9375em)" : {
                            fontSize:14,
                            marginBottom:3,
                        }
                    }}
                >
                    {isSuccess ? 
                    t("forgot_password_request_success_description")
                    :
                    t("forgot_password_description")
                    }
                </Typography>
                {/* <Heading>Nieuwe gebruiker</Heading> */}
                {/* <Text light>Nieuwe gebruikersprofiel</Text> */}
                {/* <Header title="NIEUWE GEBRUIKER" subtitle="Nieuwe gebruikersprofiel"></Header> */}
                <Box 
                    sx={{
                        transition:"350ms",
                        // background:"white",
                        // padding:3,
                        // borderRadius:4,
                    }}
                >
                    {!isSuccess ?
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={userSchema}>
                        {({values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                            <form className="login-form" onSubmit={handleSubmit}>
                                 <Box 
                                    display="flex"
                                    sx={{
                                        width:"450px",
                                        borderRadius:6,
                                        display:"flex",
                                        alignItems:"center",
                                        background:"#ecf0f5",
                                        border:"1px solid rgba(255,255,255,.15)",
                                        height:48,
                                        pl:2,
                                        "&.error": {
                                            background:colors.red[100],
                                            border: `1px solid ${colors.red[400]}`,
                                            color: colors.red[400]
                                        },
                                        "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active" : {
                                            backgroundClip:"#31415f",
                                            "webkit-text-fill-color":"#31415f",
                                            transition: "background-color 5000s ease-in-out 0s",
                                            boxShadow:"inset 0 0 20px 20px #ecf0f5",
                                            WebkitTextFillColor: "#31415f",
                                            fontSize:16
                                        },
                                        "@media screen and (max-width: 64em)" : {
                                            height:40,
                                            borderRadius:5,
                                            px:1,
                                        },
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:1,
                                        }
                                    }}

                                    className={cx({
                                        'error': !!touched.email && !!errors.email
                                    })}
                                >
                                    <Box
                                        sx={{
                                            height:32,
                                            width:32,
                                            textAlign:"center",
                                            color: "rgba(49,65,95,.55)",
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                        }}
                                    >
                                        <MailOutlineIcon
                                            // color={!!touched.email && !!errors.email ? "red" : "primaryGrey"}
                                        
                                        />

                                    </Box>
                                    <InputBase 
                                        autoFocus
                                        type={"email"}
                                        sx={{
                                            pl:1.5,
                                            flex:1,
                                            color:"#31415f",
                                            fontSize:16
                                        }} 
                                        placeholder={t("email")} 
                                        value={values.email}
                                        onBlur={handleBlur}
                                        onChange={(e, event) => {
                                            handleChange({ ...event, target: { name: 'email', value: e.target.value } })
                                        }}
                                        error={!!touched.email && !!errors.email}
                                        
                                        />
                                </Box>


                                {/* <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                label="Wachtwoord"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                name="password"
                                error={!!touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                /> */}
                                <Box
                                
                                    sx={{
                                        display:"flex",
                                        justifyContent:"flex-end",
                                        marginTop:2,
                                        width:1,
                                        "@media screen and (max-width: 47.9375em)" : {
                                            marginTop:4
                                        }

                                    }}
                                >
                                    <Shbutton type="submit" block color="primary" variant="contained">
                                        {t("request_password_reset")}
                                    </Shbutton>
                                    {/* <Button type="submit" color="primary" variant="contained">
                                        Create new user
                                    </Button> */}
                                </Box>
                                <Box
                                
                                    sx={{
                                        display:"flex",
                                        justifyContent:"flex-end",
                                        marginTop:2,
                                        width:1,
                                        "@media screen and (max-width: 47.9375em)" : {
                                            marginTop:4
                                        }

                                    }}
                                >
                                    <Shbutton link="/login" block color="primaryGrey" variant="text">
                                        {t("back_to_login")}
                                    </Shbutton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                    : 
                    <React.Fragment>
                          <Box
                                sx={{
                                    width:"450px",
                                    display:"flex",
                                    justifyContent:"flex-end",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        marginTop:4
                                    }

                                }}
                            >
                                <Shbutton link="/login" block color="green" variant="contained">
                                    {t("back_to_login")}
                                </Shbutton>
                                {/* <Button type="submit" color="primary" variant="contained">
                                    Create new user
                                </Button> */}
                            </Box>
                        
                    </React.Fragment>
                    }
                </Box>
            </motion.div>

            {/* <Box
                sx={{
                    flex:1,
                    position:"absolute",
                    background:"orange",
                    overflow:"hidden",
                    top:"80%",
                    "& img" : {
                        // width:"100%",
                        height:1,
                        zIndex:2,
                        position:"relative",
                    },
                    "&:after" : {
                        content:'""',
                        display:"block",
                        filter:"blur(300px)",
                        background:"rgba(141,181,255,.45)",
                        position:"absolute",
                        height:"50%",
                        width:"60%",
                        left:"-40%",
                        top:"25%",
                        zIndex:0
                    },
                    "@media screen and (max-width: 85.5em)" : {
                        display:"none",
                    }
                }}
            >
                <img src={require('../../styles/img/dashboard.png')} alt="Dashboard" />
            </Box> */}
        </Box>
    )
}

export default Forgotpassword;