import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const RELATIONINVOICEPRODUCT_BASE = "/relationinvoiceproducts";
const buildUrl = (endpoint) => RELATIONINVOICEPRODUCT_BASE + endpoint;
const API_TAG = 'RelationInvoiceProducts';

const relationInvoiceProductSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRelationInvoiceProducts: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: API_TAG }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getRelationInvoiceProducts', API_TAG)
        }),
        getRelationInvoiceProduct: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            // providesTags: (id) => [{ type: `CountingStrategyTemplateSingle`,id }]
            providesTags: (result, error, arg) => [{ type: `${API_TAG}Single`, id: arg }]
        }),
        getRelationInvoiceProductsFromInvoiceProduct: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/invoiceproduct/${args.id}?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: API_TAG }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getRelationInvoiceProducts', API_TAG)
        }),
        addRelationInvoiceProduct: builder.mutation({
            query: relationInvoiceProductInfo => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: relationInvoiceProductInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG}],
        }),
        updateRelationInvoiceProduct: builder.mutation({
            query: relationInvoiceProductInfo => ({
                url: buildUrl(`/${relationInvoiceProductInfo.id}`),
                method: 'PUT',
                body: relationInvoiceProductInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }],
        }),
        deleteRelationInvoiceProduct: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }],
        }),
    })
});

export const {
    useGetRelationInvoiceProductsQuery,
    useGetRelationInvoiceProductQuery,
    useAddRelationInvoiceProductMutation,
    useUpdateRelationInvoiceProductMutation,
    useDeleteRelationInvoiceProductMutation,
    useGetRelationInvoiceProductsFromInvoiceProductQuery
  } = relationInvoiceProductSlice;
