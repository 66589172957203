// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, InputBase, InputLabel, Select, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { tokens } from '../../../../../theme';
import { t } from 'i18next';
import { useGetShippersSelectQuery } from '../../../../../newapi/shipper/shipperSlice';
import { MenuItem } from 'react-pro-sidebar';
import { InputDropdown } from '../../../../theme/dropdowns/InputDropdown';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInputDropdown from '../../../../theme/dropdowns/ShValidatedInputDropdown';
import { yupNumberRequired } from '../../../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import cx from "classnames"

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const colors = tokens(theme.palette.mode);
    const { data: shippers, isLoading } = useGetShippersSelectQuery();
    const [active, setActive] = useState("false");

    const data = {...props.data};
    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    const handleChangeBool = (
        event,
        active
      ) => {
        if(active !== null) {
        setActive(active);
        props.handleDataUpdate({ "deliverytimePriority": active });
        }
        // setAlignment(newAlignment);
      };


    return (
        <>
        <ShValidatedInputDropdown
            name="shipper"
            displayName="name"
            changeField="id"
            selected={shippers?.findIndex(shipper => shipper.id === data.shipper) ?? -1}
            noSelection={t("choose_default_shipper")} //Custom text when nothing is selected
            options={shippers}
            onChange={handleChange}
            error={props.errors.shipper}
        />
    <Box 
            sx={{
                background: colors["blue"][100],
                color: colors["blue"][400],
                padding:2,
                width:1,
                marginTop:5,
            }}
        >
            <Typography fontWeight={"bold"} fontSize={"17"}>
                {t("mp_add_stream_shipper_rules_title")}
            </Typography>
            <Box sx={{paddingTop:0.5}}>
                <Typography fontSize={"14"}>
                {t("mp_add_stream_shipper_rules_text")}
                </Typography>
            </Box>
        </Box>
        <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            gap:1,
                            paddingTop:3,
                            maxWidth: 160,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                        >
                        <Text light>
                        {t('deliverytime_priority')}
                        </Text>
                        <ToggleButtonGroup
                            color={active === "true" ? "green" : "red"}
                            value={active}
                            exclusive
                            onChange={handleChangeBool}
                            aria-label="Active"

                            className={cx(`${active}`, {
                                'active': active === "true"
                            })}
                            sx={{
                                background: colors.red[100],
                                padding:0.5,
                                borderRadius:5,
                                transition:"250ms",
                                "&.active" : {
                                    background:colors.green[100],
                                    transition:"250ms",
                                },
                            }}
                        >
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.green[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.red[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="false">
                                {t('inactive')}
                            </ToggleButton>
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.red[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.green[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="true"
                            >
                                {t('active')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
        </>
    );
};

const StreamShipper = (props) => {
    const { t } = useTranslation();

    const schema = yup.object({
        shipper: yupNumberRequired('standard_shipper',t)
    });

    return (
        <ProgressionTab
            tabId="tabShipper"
            subtitle={t("choose_shipper")}
            nextTab={"tabReachType"}
            step={3}
            maxSteps={4}
            schema={schema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default StreamShipper;

