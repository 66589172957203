import { Box, useTheme } from "@mui/material"
import { tokens } from "../../../../theme";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PopoverModal from "../PopoverModal";
import { useState } from "react";
import Subheading from "../../text/Subheading";
import { useTranslation } from "react-i18next";

const ExplainerModal = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    // Separate touch event handler for mobile devices
    const handleTouch = (event) => {
        if (anchorEl) {
            handlePopoverClose();
        } else {
            handlePopoverOpen(event);
        }
    };

    return (
        <>
            <Box
                aria-owns={anchorEl ? 'simple-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                  onTouchStart={handleTouch} 
                sx={{
                    color:colors.txt['secondary'],
                    display:'inline-flex',
                    ml:1,
                    height:24,
                    width:24,
                    borderRadius:3,
                    alignItems:"center",
                    justifyContent:"center",
                    "@media screen and (max-width:47.9375em)" : {
                        ml:0.5
                    }
                }}
            >
                <HelpOutlineOutlinedIcon fontSize="small"/>
            </Box>
            
            <PopoverModal
                id="simple-popover"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                triangle={{position:"center",color:theme.palette.mode === "dark" ? colors.grey[300] : "#fff"}}
                hover={true} // This prop is used to indicate hover functionality
            >
                <Box
                    sx={{
                        maxWidth:450,
                        background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                        px:3,
                        py:3,
                        "@media screen and (max-width:47.9375em)" : {
                            maxWidth:"unset"
                        }
                    }}
                >
                    <Box
                        sx={{
                            display:"none",
                            flexDirection:"column",
                            gap:2,
                            mb:4,
                            mt:1,
                            alignItems:"center",
                            "@media screen and (max-width:47.9375em)" : {
                                maxWidth:"unset",
                                display:"flex"
                            }
                            
                            
                        }}
                    >
                        <Box
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                width:40,
                                height:40,
                                borderRadius:3,
                                background:theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100],
                                color:theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400],
                            }}
                        >
                            <HelpOutlineOutlinedIcon/>
                        </Box>
                        <Subheading>{t("help_centre")}</Subheading>
                    </Box>
                    {props.children}
                </Box>
            </PopoverModal>
        </>
    )
}

export default ExplainerModal;