import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const BASE = "/warehouses";
const ApiName = "Warehouses";
const ApiZoneName = "WarehouseZones";
const ApiLevelName = "WarehouseLevels";
const buildUrl = (endpoint) => BASE + endpoint;

const warehouseApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getWarehousesSelect: builder.query({
            query: () => ({
                url: buildUrl(`?select=true`),
                method: 'POST',
            }),
            providesTags: (result, error, arg) => [{ type: ApiName}],
        }),
        getWarehouses: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: ApiName}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getWarehouses', ApiName)
        }),
        getWarehouse: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: ApiName, id }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getWarehouse', 'Warehouse')
        }),
        addWarehouse: builder.mutation({
            query: warehouseInfo => ({
                url: buildUrl(`/add`),
                method: 'POST',
                body: warehouseInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiName}],
        }),
        updateWarehouse: builder.mutation({
            query: ({ id, data }) => ({
                url: buildUrl(`/${id}`),
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: ApiName, id }, { type: ApiName }, { type: `${ApiName}Select` }],
        }),
        deleteWarehouse: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: ApiName, arg }],
        }),
        getReplenishOrder: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/replenishorder`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}ReplenishOrder` }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getReplenishOrder', `${ApiName}ReplenishOrder`)
        }),
        updateReplenishOrder: builder.mutation({
            query: warehouseLevelInfo => ({
                url: buildUrl(`/${warehouseLevelInfo.warehouseId}/replenishorder/index/${warehouseLevelInfo.id}`),
                method: 'PUT',
                body: warehouseLevelInfo,
            }),
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}ReplenishOrder` }],
        }),
        getWarehouseZones: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/zones`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: ApiZoneName }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getWarehouseZones', ApiZoneName)
        }),
        getWarehouseZonesSelect: builder.query({
            query: () => ({
                url: buildUrl(`/0/zones/select`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: ApiZoneName }],
        }),
        editWarehouseZone: builder.mutation({
            query: warehouseZoneInfo => ({
                url: buildUrl(`/${warehouseZoneInfo.warehouseId}/zones/${warehouseZoneInfo.id}`),
                method: 'PUT',
                body: warehouseZoneInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiZoneName}],
        }),
        addWarehouseZone: builder.mutation({
            query: warehouseZoneInfo => ({
                url: buildUrl(`/${warehouseZoneInfo.warehouseId}/zones/add`),
                method: 'POST',
                body: warehouseZoneInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiZoneName}],
        }),
        deleteWarehouseZone: builder.mutation({
            query: args => ({
                url: buildUrl(`/${args.warehouseId}/zones/${args.id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiZoneName}],
        }),
        getWarehouseLevels: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/levels`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: ApiLevelName }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getWarehouseLevels', ApiLevelName)
        }),
        getWarehouseLevelsSelect: builder.query({
            query: () => ({
                url: buildUrl(`/0/levels/select`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: ApiLevelName }],
        }),
        editWarehouseLevel: builder.mutation({
            query: warehouseLevelInfo => ({
                url: buildUrl(`/${warehouseLevelInfo.warehouseId}/levels/${warehouseLevelInfo.id}`),
                method: 'PUT',
                body: warehouseLevelInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiLevelName}],
        }),
        editWarehouseLevelIndex: builder.mutation({
            query: warehouseLevelInfo => ({
                url: buildUrl(`/${warehouseLevelInfo.warehouseId}/levels/index/${warehouseLevelInfo.id}`),
                method: 'PUT',
                body: warehouseLevelInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiLevelName}],
        }),
        addWarehouseLevel: builder.mutation({
            query: warehouseLevelInfo => ({
                url: buildUrl(`/${warehouseLevelInfo.warehouseId}/levels/add`),
                method: 'POST',
                body: warehouseLevelInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiLevelName}],
        }),
        deleteWarehouseLevel: builder.mutation({
            query: args => ({
                url: buildUrl(`/${args.warehouseId}/levels/${args.id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiLevelName}],
        }),
    })
});

// console.log(apiSlice);
export const { 
    useGetWarehousesSelectQuery,
    useGetWarehousesQuery,
    useGetWarehouseQuery,
    useAddWarehouseMutation,
    useUpdateWarehouseMutation,
    useDeleteWarehouseMutation,
    useGetReplenishOrderQuery,
    useUpdateReplenishOrderMutation,
    useGetWarehouseZonesQuery,
    useGetWarehouseZonesSelectQuery,
    useEditWarehouseZoneMutation,
    useAddWarehouseZoneMutation,
    useDeleteWarehouseZoneMutation,
    useGetWarehouseLevelsQuery,
    useGetWarehouseLevelsSelectQuery,
    useEditWarehouseLevelMutation,
    useEditWarehouseLevelIndexMutation,
    useAddWarehouseLevelMutation,
    useDeleteWarehouseLevelMutation
} = warehouseApiSlice;
