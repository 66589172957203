import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useEditShipperMutation } from "../../../../../newapi/shipper/shipperSlice";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import * as yup from "yup";
import { yupRequired } from "../../../../../utils/validation";

import { useTranslation } from "react-i18next";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const AuthorisationPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [shipperInfo, setShipperInfo] = useState({
        state: "",
        id: 0,
        name: "",
        reference: "",
        type: null,
        standard_time: "",
        standard_time_saturday: "",
        standard_time_sunday: "",
        maxWeight: "",
        maxLength: 0,
        maxWidth: 0,
        maxHeight: 0,
        maxVolume: 0,
        alternativeAddress: null,
        street: null,
        house_nr: null,
        house_nr_extra: null,
        postal: null,
        city: null,
        country: null,
        company: null,
        contactperson: null,
        public_key: null,
        private_key: null
    });
    useEffect(() => {
        setShipperInfo(props.data);
    },[props.data]);
    

    const handleChange = (key, value) => {
        setShipperInfo({
          ...shipperInfo,
          [key]: value
        });
      };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={shipperInfo}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const [fieldErrors, setFieldErrors] = useState({});

    const getValidationSchema = () => {
        const schema = yup.object().shape({
            private_key: yup.string().when('type', {
                is: (type) => ['postnl', 'dpd', 'dhl', 'bol'].includes(type),
                then: () => yupRequired('private_key', t),
                otherwise: () => yup.string().notRequired(),
            }),
            public_key: yup.string().when('type', {
                is: (type) => ['dpd', 'dhl'].includes(type),
                then: () => yupRequired('public_key', t),
                otherwise: () => yup.string().notRequired(),
            }),
        });

        return schema;
    };

    const [EditShipper,{isLoading}] = useEditShipperMutation();
    const handleSaveClick = async () => {
        console.log(shipperInfo);
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                setFieldErrors({});
                const schema = getValidationSchema();
                await schema.validate(shipperInfo, { abortEarly: false });
                const data = await EditShipper({
                    ...shipperInfo,
                    reference: shipperInfo?.reference === "" ? null : shipperInfo?.reference,
                  }).unwrap()
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                if (err.name === 'ValidationError') {
                    // Create an errors object where keys are field names and values are error messages
                    const errors = err.inner.reduce((acc, currentError) => ({
                        ...acc,
                        [currentError.path]: currentError.message
                    }), {});
                
                    // Set the validation errors in the state
                    setFieldErrors(errors);
                } else {
                    // Handle other errors, such as network errors
                    showToastMessageRequestError(t("update_failed"), t, {success: false, error: err.error});
                }
            }
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
          }   
        }        
    };
    return (
       <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("api")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    
                    {/* Two columns */}
                    {(["dpd", "dhl", "parcel"].includes(shipperInfo?.type)) && (
                        <>
                            <Box flex={4}
                                sx={{
                                    display:"flex",
                                    gap:4,
                                    justifyContent:"flex-start"
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box lineHeight={"38px"}>
                                        <Text>{t("public_key")}</Text>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box display="flex"
                                        height="32px"
                                        borderRadius="20px"
                                        backgroundColor={colors.grey[100]}
                                        sx={{
                                            marginTop:0.5,
                                            marginBottom:0.5,
                                            justifyContent:"space-between",
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flexDirection:"column"
                                            }
                                        }}
                                    >
                                        <ShValidatedInput
                                            name="public_key"
                                            value={shipperInfo?.public_key}
                                            onChange={handleChange}
                                            error={fieldErrors.public_key}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}
                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("api_key")}</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <ShValidatedInput
                                    name="private_key"
                                    value={shipperInfo?.private_key}
                                    onChange={handleChange}
                                    error={fieldErrors.private_key}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dropdown>

            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
                    <GridActions
            id={props.data?.id}
            action={handleSaveClick}
            // active={active}
            // blocked={blocked}
            // remark={remark}
            // carrier={carrier}
            // quarantine={quarantine}
            // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
        />
            </Box>
        </Box>
    );
}

export default AuthorisationPanel;