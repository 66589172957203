import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const INVOICEPRODUCT_BASE = "/invoiceproducts";
const buildUrl = (endpoint) => INVOICEPRODUCT_BASE + endpoint;
const API_TAG = 'InvoiceProducts';

const invoiceProductSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInvoiceProducts: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: API_TAG }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getInvoiceProducts', API_TAG)
        }),
        getInvoiceProduct: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            // providesTags: (id) => [{ type: `CountingStrategyTemplateSingle`,id }]
            providesTags: (result, error, arg) => [{ type: `${API_TAG}Single`, id: arg }]
        }),
        addInvoiceProduct: builder.mutation({
            query: invoiceProductInfo => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: invoiceProductInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG}],
        }),
        updateInvoiceProduct: builder.mutation({
            query: invoiceProductInfo => ({
                url: buildUrl(`/${invoiceProductInfo.id}`),
                method: 'PUT',
                body: invoiceProductInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }],
        }),
        deleteInvoiceProduct: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }],
        }),
    })
});

export const {
    useGetInvoiceProductsQuery,
    useGetInvoiceProductQuery,
    useAddInvoiceProductMutation,
    useUpdateInvoiceProductMutation,
    useDeleteInvoiceProductMutation
  } = invoiceProductSlice;
