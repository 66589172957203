import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
// import Warehouse from "./Views/Warehouse";
import { useGridApiRef } from "@mui/x-data-grid";
import Info from "./Views/Info";
import SizeAndWeight from "./Views/SizeAndWeight";
import AvailableStock from "./Views/AvailableStock";
import Batches from "./Views/Batches";
import Instructions from "./Views/Instructions";
import Efullfillment from "./Views/Efullfillment";
import Logs from "./Views/Logs";
import { useTranslation } from 'react-i18next';
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import Warehouse from "./Views/Warehouse";
import InventoryIcon from '@mui/icons-material/Inventory';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spStockDetailsConfig } from "./spStockDetailsConfig";
import { useGetProductQuery } from "../../../../newapi/warehouse/productSlice";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import Text from "../../../theme/text/Text";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SPStockDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: productData,isFetching, isLoading,isError } = useGetProductQuery(props.productId,
    {
        skip: !props.productId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <InventoryIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t('location')} /> },
        { component: <ShRowSkeleton heading={t('barcode')} /> },
        { component: <ShRowSkeleton heading={t('total_available')} /> }
    ], [t]);

    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        options: defaultOptions,
        actions: <SidePanelActions setIsExtended={setIsExtendedCallback} />
    }), [defaultIcon, defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.productId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && productData) {
            let locations = productData.stocks.map(stock => stock.warehouseLocation.location);
            let combinedLocations = locations.join(", ");

            setPanel(prevPanel => ({
                ...prevPanel,
                title: productData.description,
                subTitle: productData.sku,
                options: [
                    { heading: t('location'), content: combinedLocations || t('no_location') },
                    { heading: t('barcode'), content: productData.barcode || t('barcode') },
                    { heading: t('total_available'), content: productData.totalStockAvailable },
                ],
            }));
        }
    }, [productData, isLoading, t]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spStockDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} >
                <>
                    {/* <Warehouse config={spStockDetailsConfig.warehouse} productId={props.data ? props.data.id : -1} />
                    <Info config={spStockDetailsConfig.info} data={props.data}/>
                    <SizeAndWeight config={spStockDetailsConfig.sizeAndWeight} data={props.data} />
                    <AvailableStock config={spStockDetailsConfig.stock} data={props.data}/>
                     <Batches apiRef={batchesApiRef} /> 
                    <Instructions config={spStockDetailsConfig.instructions} data={props.data}/>
                    <Efullfillment config={spStockDetailsConfig.efullfillment}/>
                    <Logs config={spStockDetailsConfig.logs} data={props.data}/> */}

                    <Warehouse config={spStockDetailsConfig.warehouse} productId={props.productId} />
                    <Info config={spStockDetailsConfig.info} data={productData}/>
                    <SizeAndWeight config={spStockDetailsConfig.sizeAndWeight} data={productData} />
                    <AvailableStock config={spStockDetailsConfig.stock} data={productData}/>
                    <Instructions config={spStockDetailsConfig.instructions} data={productData}/>
                    {/* <Efullfillment config={spStockDetailsConfig.efullfillment} data={productData}/> */}
                    <Logs config={spStockDetailsConfig.logs} data={productData}/>

                </>
        </Sidepanel2>
    );
};

export default SPStockDetails;