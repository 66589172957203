import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import Status from "./Views/Status";
import { useGridApiRef } from "@mui/x-data-grid";
import Replenish from "./Views/Replenish";
import ShipOrder from "./Views/ShipOrder";
import OrderLines from "./Views/OrderLines";
import Shipments from "./Views/Shipments";
import SplitOrder from "./Views/SplitOrder";
import ParcelInvestigation from "./Views/ParcelInvestigation";
import WriteOffs from "./Views/WriteOffs";
import Jokers from "./Views/Jokers";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import Logs from "./Views/Logs";
import {useTranslation} from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../utils";
import PickingLines from "./Views/PickingLines";
import MSplitOrder from "../../Modals/MSplitOrder";
import ButtonGroup from "../../../theme/buttons/ButtonGroup";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spOrderDetailsConfig,spOrderDetailsConfigContent } from "./spOrderDetailsConfig";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import { useGetOrderQuery } from "../../../../newapi/order/ordersSlice";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import { t } from "i18next";

const SidePanelActions = (props) => {
    const {t} = useTranslation();
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }
    const handleClose =(split, ie) => {
        addModal.setOpen(false);
    }

    
    return (
        <SidepanelHeaderActiongroup>
            <ButtonGroup version={2}>
                <Box
                    sx={{
                        width:1,
                        flex:1,
                        mx:"auto",
                    }}
                >
                    <Shbutton
                    
                        onClick={() => addModal.setOpen(true)}
                        className={"group-btn"}
                        // onClick={props.action}
                        color={"primaryGrey"}
                        variant="contained"
                        // endIcon={<SaveIcon />}
                    >
                        {t('split_order')}
                    </Shbutton>
                </Box>
            </ButtonGroup>
            
            <MSplitOrder orderId={props.orderId} onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
        </SidepanelHeaderActiongroup>
    )
}

const HeaderDateBox = (props) => {
    const { t, ready, i18n } = useTranslation();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [planned, setPlanned] = useState({});
    const [shipped, setShipped] =  useState({});
    
    useEffect(() => {
        setPlanned(getTranslatedDateAndTime(props.data?.date_planned, props.t));
        setShipped(getTranslatedDateAndTime(props.data?.date_shipped, props.t));
    },[props.data])


    return (
        <>
            {/* <Text light>Date</Text> */}
            <Box display={"flex"} alignItems={"center"}>
                <Box 
                    sx={{
                        width:66,
                        flexShrink:0,
                        mr:1,
                    }}
                >
                    <Text light>{t("scheduled")}</Text>
                </Box>
                {props.skeleton ?
                    <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
                : 
                    <>
                        <Box marginRight={1}><Text bold>{planned?.date}</Text></Box>
                        <Text>{planned?.time}</Text>
                    </> 
                }
            </Box>
            <Box  display={"flex"} alignItems={"center"}>
                <Box 
                    sx={{
                        width:66,
                        flexShrink:0,
                        mr:1,
                    }}
                >
                    <Text light>{t("planned")}</Text>
                </Box>
                {props.skeleton ?
                    <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
                : 
                    <>
                        <Box marginRight={1}><Text bold>{shipped?.date}</Text></Box>
                        <Text>{shipped?.time}</Text>
                    </>
                }
            </Box>
        </>
    )

}

const getOrderStatusColor = (data)  => {
    let labelcolor = "grey";
    let planned = new Date(data?.date_planned);
    let sent = data?.date_sent ? new Date(data?.date_sent) : null;

    if(sent) {
        // when sent is filled and is equal to planned: green
        if(sent.getTime() === planned.getTime()) {
            labelcolor = "green";
        }
        // when sent is filled but later than planned: orange
        else if(sent.getTime() > planned.getTime()) {
            labelcolor = "orange";
        }
    }
    else {
        // When sent is not filled and planned >= now: blue
        if(planned.getTime() >= Date.now()) {
            labelcolor = "blue";
        }
        // When sent is not filled and planned < now: red
        else if(planned.getTime() < Date.now()) {
            labelcolor = "red";
        }
    }

    return labelcolor;
}

const SpOrderDetails = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const { data } = props;

    const { t, ready, i18n } = useTranslation();


    const { data: orderData, isLoading,isFetching,isError } = useGetOrderQuery(props.orderId,
    {
        skip: !props.orderId
    });
        

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        // content: <ImportExportIcon/>
        content: <Inventory2Icon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <HeaderDateBox skeleton t={i18n}/> },
        { component: <ShRowSkeleton heading={t("shipment_days")} /> },
        { component: <ShRowSkeleton heading={t("pickstream")} /> },
        { component: <ShRowSkeleton heading={t("orderlines")} /> },
        { component: <ShRowSkeleton heading={t("reference")} /> },
    ], [t]);

    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        options: defaultOptions,
        // actions: <SidePanelActions setIsExtended={setIsExtendedCallback} />,
        actions:<SidePanelActions orderId={props.orderId} setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon, defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.orderId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && orderData) {
            // let locations = productData.stocks.map(stock => stock.warehouseLocation.location);
            // let combinedLocations = locations.join(", ");

            setPanel(prevPanel => ({
                // setPanel((prevPanel) => ({
                ...prevPanel,
                title: t("order") + " " + orderData.id,
                subTitle: orderData.webshopcredentials?.description,
                icon :{
                    theme: getOrderStatusColor(orderData), 
                    content: <Inventory2Icon/>
                },
                // actions:<SidePanelActions orderId={props.orderId} setIsExtended={props.setIsExtended} />,
                actions:<SidePanelActions orderId={props.orderId} setIsExtended={setIsExtendedCallback} />,
                options : [
                    {
                        component: <HeaderDateBox data={orderData} t={i18n}/>
                    },
                    {
                        heading: t("shipment_days"),
                        content:'0'
                    },
                    {
                        heading: t("pickstream"),
                        content: orderData.stream?.description || "-"
                    },
                    {
                        heading: t("orderlines"),
                        content: orderData.orderlines?.length
                    },
                    {
                        heading: t("reference"),
                        content: orderData.webshopreference
                    }
                ],
                //   }));
            }));
        }
    }, [orderData, isLoading,isFetching, t,theme.palette.mode]);

    return (
        props.relation &&
        <>
            {/* {ready ?? */}
                <Sidepanel2
                    skeleton={isLoading || isFetching}
                    tabConfig={spOrderDetailsConfig(props.relation)}
                    content={panel} 
                    isExtended={props.isExtended} 
                    setIsExtended={props.setIsExtended} >
                    <>
                        <Status config={spOrderDetailsConfigContent.status} data={orderData}/>
                        <ShipOrder config={spOrderDetailsConfigContent.shipOrder} data={orderData}/>
                        <OrderLines  config={spOrderDetailsConfigContent.orderLines} canReserveOrderLines={props.canReserveOrderLines} data={orderData?.id}/>
                        <WriteOffs  config={spOrderDetailsConfigContent.writeOffs} data={orderData}/>
                        <Shipments config={spOrderDetailsConfigContent.shipments}  data={orderData}/>
                    </>
                </Sidepanel2>
            {/* } */}
        </>
        ||
        <>
            <Sidepanel2
                skeleton={isLoading || isFetching}
                tabConfig={spOrderDetailsConfig(props.relation)}
                content={panel} 
                isExtended={props.isExtended} 
                setIsExtended={props.setIsExtended} >
                <>
                    <OrderLines config={spOrderDetailsConfigContent.orderlines}  data={props.orderId}/>
                    <PickingLines config={spOrderDetailsConfigContent.pickingLines} data={orderData} />
                    <Replenish config={spOrderDetailsConfigContent.replenish}  data={orderData}/> 
                    <ShipOrder config={spOrderDetailsConfigContent.shipOrder}  data={orderData}/>
                    <WriteOffs config={spOrderDetailsConfigContent.writeOffs} data={orderData}/>
                    <Shipments config={spOrderDetailsConfigContent.shipments}  data={orderData}/>
                    <Jokers config={spOrderDetailsConfigContent.jokers} actions data={orderData}/>
                    <Logs config={spOrderDetailsConfigContent.logs} data={orderData}/>
                </>
            </Sidepanel2>
        </>
    );
};

export default SpOrderDetails;