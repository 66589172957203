import { Box, useTheme,List, ListItem, ListItemButton, ListItemText, } from "@mui/material"
import { tokens } from "../../../../../theme";
import Shbutton from "../../../buttons/Shbutton";
import Subheading from "../../../text/Subheading";
import Text from "../../../text/Text";

import SortIcon from '@mui/icons-material/Sort';
import CheckIcon from '@mui/icons-material/Check';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useEffect, useState } from "react";

import ShSwitch from "../../../../theme/inputs/ShSwitch";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { ReactComponent as PinAlt } from '../../../../../styles/svg/pin.svg';
import { useDispatch, useSelector } from "react-redux";
import { updateShGridColumn } from "../../../../../newapi/global/filter/gridFilterSlice";
import { useGridFilter } from "../../../../../contexts/GridFilterContext";

const FilterSettingsView = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const {index,id} = useGridFilter();
    const filterObject = useSelector((state) => state.gridFilter.viewInstances[index][id]);
    

    const [view, setView] = useState({
        title: <SettingsOutlinedIcon/>,
        position:"right",
        icon: <SettingsOutlinedIcon/>,
        tabClasses: "icon s40",
    })
    

    useEffect(() => {
        props.addView({
            ...view,
            apiRef: props.apiRef
        },false);

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const isPinned = (column) => {
        console.log("column",column);
        return column.pinned;
    }
    const changePin = (column) => {
        console.log("logged",column);
        dispatch(updateShGridColumn({
            index,
            id,
            column: column.column,
            pinned: !column.pinned,
        }));
    }
    
    const changeVisibility = (column) => {
        dispatch(updateShGridColumn({
            index,
            id,
            column: column.column,
            visible: !column.visible,
        }));
    }

    return (
        props.isActive ?
        <Box
            sx={{
                // padding:1.5,
                width: "520px",
                maxHeight:"400px",
                display:"flex",
                flexDirection:"column",
                overflow:"auto",
                "@media screen and (max-width: 47.9375em)" : {
                    width:1,
                }
            }}
        >
            <Box 
            sx={{
                display:"flex",
                justifyContent:"space-between",
                alignItems:"center",
                position:"relative",
                textAlign:"center",
                paddingTop:3,
                paddingLeft:3,
                paddingRight:1.75
            }}>

                <Subheading>Kolommen</Subheading>
                <Box
                    sx={{
                        display:"inline-flex",
                        alignItems:"center",
                        gap:3,
                        "& .pin-icon path" : {
                            fill: colors.txt["primary"]
                        }
                    }}
                >
                    <Box
                        sx={{
                            width:58,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center"
                        }}
                    >
                        <RemoveRedEyeOutlinedIcon/>
                    </Box>
                    <Box
                        sx={{
                            width:58,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center"
                        }}
                    >
                        <PinAlt className="pin-icon"/>
                    </Box>
                </Box>
            </Box>
            <Box
                >
                    <List
                        sx={{
                            padding:0,
                            paddingBottom:2.5,
                        }}
                    >
                    {filterObject.columns.map((column, index) => (
                            <Box 
                            key={column.column}
                            sx={{
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:"center",
                                position:"relative",
                                textAlign:"center",
                                paddingTop:0.5,
                                paddingLeft:3,
                                paddingRight:1.25,
                            }}>
                
                                <Text light>{column.column}</Text>
                                <Box
                                    sx={{
                                        display:"inline-flex",
                                        alignItems:"center",
                                        gap:3,
                                        "& .pin-icon path" : {
                                            fill: colors.txt["primary"]
                                        }
                                    }}
                                >
                                    {/* Visibility switch */}
                                    <Box
                                        sx={{
                                            width:58,
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center"
                                        }}
                                    >
                                        <ShSwitch onChange={() => changeVisibility(column)} checked={column.visible}/>
                                    </Box>

                                    {/* Pin switch */}
                                    <Box
                                        sx={{
                                            width:58,
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center"
                                        }}
                                    >
                                        <ShSwitch onChange={() => changePin(column)} checked={column.pinned}/>
                                    </Box>
                                </Box>
                            </Box>

                        // <ListItem key={column.field + "index"} disablePadding onClick={() => props.sort(column)}>
                        //     <ListItemButton 
                        //         sx={{
                        //             color:`${props.sortedBy !== null && props.sortedBy.field === column.field ? theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400] : colors.txt["secondary"]}`,
                        //             fontWeight:`${props.sortedBy !== null && props.sortedBy.field === column.field ? 500 : 400}`,
                        //             display:"flex",
                        //             justifyContent:"space-between",
                        //             fontSize:"14px",
                        //             paddingX:1.5,
                        //             height:40,
                        //             borderRadius:5,
                        //         }}
                        //     >
                        //         {column.headerName}

                        //         {props.sortedBy !== null && props.sortedBy.field === column.field 
                        //             ?
                        //             <CheckIcon fontSize="small"/>
                        //             :
                        //             null
                        //         }
                        //     </ListItemButton>
                        // </ListItem>
                        // <Box>
                        //     {column.headerName}
                        // </Box>
                    ))}
                    </List>
                </Box>
                
        </Box>
        : null
    )
}
export default FilterSettingsView;