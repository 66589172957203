import { Box, ButtonBase, IconButton,InputBase, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Subheading from "../../../components/theme/text/Subheading";
import Heading from "../../../components/theme/text/Heading"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShLabel from "../../../components/theme/label/ShLabel";
import { tokens } from "../../../theme";
import Text from "../../../components/theme/text/Text";
import Circle from "../../../components/theme/Seperators/Circle";
import Inventory2Icon from '@mui/icons-material/Inventory2';
// import SidepanelHeader from "../../../components/theme/sidepanel/SidepanelHeader";
import ButtonGroup from "../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../components/theme/buttons/Shbutton";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShSwitch from "../../../components/theme/inputs/ShSwitch";
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import { useStartFindOrdersQuery, useFinalizeOrderMutation, useSetJokerMutation } from "../../../newapi/global/echeckSlice";
import { useGetCarrierBarcodeQuery, useGetCarriersSelectQuery } from "../../../newapi/carrier/carrierSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify"; 
import MConfirmationWithValue from "../../../components/global/Modals/MConfirmationWithValue";
import SidepanelHeader2 from "../../../components/theme/sidepanel/SidepanelHeader";
import cx from 'classnames';
import { useGetShippersSelectQuery } from "../../../newapi/shipper/shipperSlice";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { DropdownBase } from "../../../components/theme/dropdowns/Dropdown";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import { useSelector } from "react-redux";
import { useGetDeliveryOptionsQuery } from "../../../newapi/global/echeckSlice";
import { StarOutlined, StarOutlineOutlined } from "@mui/icons-material";

const Echeck = () => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [initialized, setInitialized] = useState(false);
    const [data, setData] = useState();
    const [currentOrder, setCurrentOrder] = useState();
    const [currentOrderId, setCurrentOrderId] = useState();
    const [showOptions, setShowOptions] = useState(false);
    const [showOrders, setShowOrders] = useState(false);
    const [showEcheck, setShowEcheck] = useState(false);
    const [finalizeCheck, setFinalizeCheck] = useState(false);
    const [toScanRows, setToScanRows] = useState();
    const [selectedShipper, setSelectedShipper] = useState('');
    const selectedWorkSpace = useSelector((state) => state.settings.workSpace);
    
    const [carriers, setCarriers] = useState([{
        "carrierId" : null,
        "carrierName" : "",
        "totalProducts" : 0,
        "totalWeight" : 0,
        "deliveryOption": null,
        "rows": [],
    }]);
    const [finalizeData, setFinalizeData] = useState();
    // const [carrierRows, setCarrierRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [paModalOpen, paModalSetOpen] = useState(false);
    const [modalData, setModalData] = useState(0);
    const [paModalData, setPaModalData] = useState(0);

        //Handle scan
        const [inputValue, setInputValue] = useState('');
        const [scannedValue, setScannedValue] = useState('');
        const [scannedProductValue, setProductScannedValue] = useState('');
        const [checkCarrierValue, setCheckCarrierValue] = useState('');
        const { data : incData, isLoading } = useStartFindOrdersQuery(scannedValue,{
            skip: !scannedValue || !initialized
        });
        const [incCarrier, setIncCarrier] = useState("dummy");
        const {data: fetchedIncCarrier,isError, isLoading: isLoadingC, refetch: refetchCarrier} = useGetCarrierBarcodeQuery(checkCarrierValue, {
            skip: !checkCarrierValue || !initialized || checkCarrierValue === 'skip'
        });

        const { data: shippers } = useGetShippersSelectQuery();
        const { data: carriersSelect } = useGetCarriersSelectQuery();
        const [finalizeResponse, {isLoading: finalizeLoading}] = useFinalizeOrderMutation();
        const [setJokerResponse, {isLoading: setJokerLoading}] = useSetJokerMutation();
        const [header2, setHeader2] = useState(null);
        const [deliveryOptions, setDeliveryOptions] = useState({});
        const [carrierForDeliveryToFetch, setCarrierForDeliveryToFetch] = useState(null);
        const [deliveryOptionsFetchData, setDeliveryOptionsFetchData] = useState(null);
        const { data: deliveryOptionsData } = useGetDeliveryOptionsQuery(deliveryOptionsFetchData, {
            skip: !deliveryOptionsFetchData
        });

        useEffect(() => {
          //  console.log("fetchedIncCarrier", fetchedIncCarrier);
            if(!initialized || isLoadingC) setIncCarrier("dummy");
            //if(fetchedIncCarrier && fetchedIncCarrier.barcode !== checkCarrierValue) setIncCarrier("dummy");
            if(checkCarrierValue === '' && fetchedIncCarrier){
                setIncCarrier("dummy");
            }
            if (isError) {
                setIncCarrier("dummy");
            } else if (checkCarrierValue === '') {
                setIncCarrier("dummy");
            } else if (fetchedIncCarrier && fetchedIncCarrier.barcode === checkCarrierValue) {
                setIncCarrier(fetchedIncCarrier);
            } else {
            }
            
            
        }, [fetchedIncCarrier, isError, isLoadingC]);

        const confirmModalOrder = (id) => {
           setCurrentOrderId(id);
        }

        const [matchedProduct, setMatchedProduct] = useState(null);

        const confirmProductAmount = (amount) => {
            if (amount === 0) {
                return;
            }
            
            updateCarrierAndScanRows(parseInt(amount));
        }

        const handleInputChange = (e) => {
            setInputValue(e.target.value);
        }

        const confirmModal = {
            open: open,
            setOpen: setOpen,
            data:modalData,
            confirm: confirmModalOrder
        }

        const productAmountModal = {
            open: paModalOpen,
            setOpen: paModalSetOpen,
            data:paModalData,
            confirm: confirmProductAmount
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                setInitialized(true);
                if(showEcheck){
                    setProductScannedValue(inputValue)
                } else {
                    setScannedValue(inputValue);
                }
                setInputValue(''); // Clear input
                
            }
        }

        useEffect(() => {
            if (!isLoading && incData) {
                // setCheckCarrierValue('');
                setInitialized(false);
                setData(incData);
                setShowOrders(incData.multipleOrders);
                setCurrentOrderId(0);
                setToScanRows(incData.orders[0].orderlines);
                setShowEcheck(true);
            } else if(!isLoading && !incData && initialized){
                setHighlightColor('red');
                toast.error(`Scan een geldige krat of pickbon`, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }, [incData, isLoading]);

        const fetchDeliveryOptions = async (carrier) => {
            if(carriers?.length === 0) return;
            if (selectedShipper?.type === "bol" && carrier.rows.length > 0) {
                const requestBody = {
                    "shipperId": selectedShipper.id,
                    "items": {
                        "orderItems": carrier.rows.map(row => ({
                            "orderItemId": row.product.externalId,
                            "quantity": row.amount
                        }))
                    }
                };

                
                //get index of carrier in carriers
                const carrierIndex = carriers.length === 1 ? 0 : carriers.findIndex(c => c.carrierId === carrier.carrierId);

                setCarrierForDeliveryToFetch(carrierIndex);
                setDeliveryOptionsFetchData(requestBody);
            }
        };

        useEffect(() => {
           // console.log("delivery options data", deliveryOptionsData);
            if (deliveryOptionsData) {
                setDeliveryOptions(prev => ({
                    ...prev,
                    [carrierForDeliveryToFetch]: deliveryOptionsData.deliveryOptions
                }));
                setCarriers(prev => {
                    const updatedCarriers = [...prev];
                    updatedCarriers[carrierForDeliveryToFetch].deliveryOption = deliveryOptionsData.deliveryOptions?.find(option => option.recommended) || deliveryOptionsData.deliveryOptions[0];
                    return updatedCarriers;
                });
                setCarrierForDeliveryToFetch(null); // Reset carrierToFetch after fetching
            }
        }, [deliveryOptionsData]);

        useEffect(() => {
            if(currentOrderId != null )
            {
                setCurrentOrder(incData?.orders[currentOrderId]);
                setToScanRows(incData.orders[currentOrderId].orderlines);
                //console.log("shippers", shippers);
                //console.log("selected", shippers.filter(shipper => shipper.id === incData?.orders[currentOrderId]?.shipper?.id));
                console.log("Setting shipper to:",shippers.filter(shipper => shipper.id === incData?.orders[currentOrderId]?.shipper?.id)[0])
                setSelectedShipper(shippers.filter(shipper => shipper.id === incData?.orders[currentOrderId]?.shipper?.id)[0])
                //reset carriers
                setCarriers([{
                    "carrierId" : null,
                    "carrierName" : "",
                    "totalProducts" : 0,
                    "totalWeight" : 0,
                    "deliveryOption": null,
                    "rows": [],
                }]);
            }
        }, [currentOrderId])

        useEffect(() => {
            if(!initialized || incCarrier === "dummy") return;
            console.log("inccarrier set to:", incCarrier)
            if (incCarrier) {
                setCarriers(prevCarriers => {
                    // Copy the previous carriers array
                    const updatedCarriers = [...prevCarriers];
                    
                    // If there are no previous carriers, just return the initial list
                    if (updatedCarriers.length === 0) return updatedCarriers;
        
                    // Update the last carrier
                    const lastCarrier = updatedCarriers[updatedCarriers.length - 1];
                    if(lastCarrier.carrierId === null){
                        lastCarrier.carrierId = incCarrier.id; 
                        lastCarrier.carrierName = incCarrier.name;
                    }

                    
                    fetchDeliveryOptions(lastCarrier); // Fetch delivery options if shipper type is "bol" and there are products
        
                    // If the last carrier has rows, add a new carrier
                    if (lastCarrier.rows && lastCarrier.rows.length > 0 && toScanRows.length !== 0) {
                        // Add a new empty carrier
                        updatedCarriers.push({
                            carrierId: null,
                            carrierName: "",
                            totalProducts: 0,
                            totalWeight: 0,
                            deliveryOption: null,
                            rows: [],
                        });
                    }
            
                    // Return the updated carriers array
                    setHighlightColor('green');
                    
                    return updatedCarriers;
                });
            } else {
                setHighlightColor('red');
                toast.error(`Onjuiste scan`, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            setIncCarrier("dummy");
            setCheckCarrierValue('skip');
        }, [incCarrier]);
        
        useEffect(() => {
                if(carriers[carriers.length - 1].carrierId !== null && toScanRows.length === 0 && showEcheck){
                    if(selectedShipper.type === "bol"){
                        if(carriers.every(carrier => carrier.deliveryOption !== null)){
                            finalize();
                        }
                    } else {
                        finalize();
                    }
                } 
        }, [carriers]); 

        const FinalizeClick = () => {
            if(carriers[carriers.length - 1].carrierId !== null && toScanRows.length === 0 && showEcheck){
                if(selectedShipper.type === "bol"){
                    if(carriers.every(carrier => carrier.deliveryOption !== null)){
                        finalize();
                    }
                } else {
                    finalize();
                }
            } else {
                setModalData({
                    title: "Afronden niet mogelijk",
                    content: `Je kunt deze order nog niet afronden omdat nog niet alle producten zijn gescant`,
                    value: 0,
                    confirm: (e) => {}
                });
                confirmModal.setOpen(true);
            }
        }

        const JokerClick = () => {
            if(carriers[carriers.length - 1].carrierId !== null && toScanRows.length === 0){
                setModalData({
                    title: "Joker een voltooide order",
                    content: `Deze order is volledig doorlopen voor de eCheck. Weet je zeker dat je deze alsnog wilt jokeren?`,
                    value: 0,
                    confirm: (e) => {}
                });
                confirmModal.setOpen(true);
            } else {
                setJoker();
            }
        }

        const updateCarrierAndScanRows = (mutation) => {
            setCarriers(prev => {
                let updatedCarriers = [...prev];
                
                if (updatedCarriers.length > 0) {
                    let lastCarrier = { ...updatedCarriers[updatedCarriers.length - 1] };
                    const productIndexInCarrier = lastCarrier.rows.findIndex(row => row.product.barcode === matchedProduct.product.barcode);
                    
                    if (productIndexInCarrier !== -1) {
                        const updatedProduct = { ...lastCarrier.rows[productIndexInCarrier], amount: lastCarrier.rows[productIndexInCarrier].amount + mutation };
                        lastCarrier.rows[productIndexInCarrier] = updatedProduct;
                    } else {
                        let productToAdd = { ...matchedProduct, amount: mutation };
                        lastCarrier.rows.push(productToAdd);
                    }
                    
                    fetchDeliveryOptions(lastCarrier);
                
                    updatedCarriers[updatedCarriers.length - 1] = lastCarrier;
                }
                
                return updatedCarriers;
            });

            setToScanRows(prevToScanRows => 
                prevToScanRows.map(orderline => 
                    orderline.product.barcode === matchedProduct.product.barcode 
                    ? { ...orderline, amount: orderline.amount - mutation } 
                    : orderline
                ).filter(orderline => orderline.amount > 0)
            );
            setHighlightColor('green');
        }

        const setJoker = async () => {
            const response = await setJokerResponse(currentOrder.id).unwrap();
            NextOrder(true);
        }

        const NextOrder = (joker = false) => {
            if(showOrders){
                setCurrentOrder({ ...currentOrder, finalized: true });
                if(joker){
                    setCurrentOrder({ ...currentOrder, joker: true });
                }
                const nextOrder = incData?.orders.find(order => !order.finished);
                if (nextOrder) {
                    const nextOrderId = incData.orders.indexOf(nextOrder);
                    setCurrentOrderId(nextOrderId);
                }
            } else {
                setInitialized(false);
                    setCheckCarrierValue(false);
                    setIncCarrier("dummy");
                    setShowEcheck(false);
                    setCurrentOrderId(null);
                    setData(null);
                    
                    
                    setShowOrders(false);
                    setShowOptions(false);
                    setFinalizeCheck(false);
                    setSelectedShipper('');
                    setToScanRows([]);
                    setCarriers([{
                        "carrierId" : null,
                        "carrierName" : "",
                        "totalProducts" : 0,
                        "totalWeight" : 0,
                        "deliveryOption": null,
                        "rows": [],
                    }]);
                      
                    
                
                    setModalData({});
                    confirmModal.setOpen(false);
                    setInitialized(true);
                
            }
        }

        const OwnCarrierClick = () => {
            var carrier = {id: -1,
            name: t("own_carrier")};
            setIncCarrier(carrier);
        }

        const selectManualCarrier = (selected) => {
            var carrier = carriersSelect[selected];
            setIncCarrier(carrier);
        }
    
        const selectShipper = (selected) => {
            var shipper = shippers[selected];
            setSelectedShipper(shipper);
        }

        useEffect(() => {
            //fetchDeliveryOptions(lastCarrier); for each carrier
            carriers.forEach(carrier => {
                fetchDeliveryOptions(carrier);
            });
        }, [selectedShipper]);

        useEffect(() => {
            if (!matchedProduct) return;
        
            if (matchedProduct.amount > 16) {
                setPaModalData({
                    title: "Hoeveel van dit product wil je scannen?",
                    content: `${matchedProduct.amount} x ${matchedProduct.product.description}`,
                    value: "",
                    hasInput: true,
                    inputLabel: "amount",
                    inputType: "number",
                    confirm: confirmProductAmount
                });
                productAmountModal.setOpen(true);
            } else {
                updateCarrierAndScanRows(1);
            }
        }, [matchedProduct]);

        useEffect(() => {
            if(scannedProductValue === '' || !initialized) return;
            
            const foundProduct = toScanRows.find(orderline => orderline.product.barcode === scannedProductValue);
            if (foundProduct) {
                setMatchedProduct(foundProduct);
            } else {
                setCheckCarrierValue(scannedProductValue);
            }
            setProductScannedValue('');
        }, [scannedProductValue]);

    // useEffect(() => {
    //     if (checkCarrierValue !== '' && fetchedIncCarrier) {
    //         console.log("refetching carrier");
    //         refetchCarrier();
    //     }
    //     console.log("checkCarrierValue", checkCarrierValue);
    // }, [checkCarrierValue]);


    const orderClick = (index) => {
        if((carriers[0]?.rows?.length ?? 0) > 0){
            setModalData({
                title: 'confirm_switch_order_title',
                content: t("confirm_switch_order_question"),
                value: index,
                confirm: confirmModalOrder
            });
            setOpen(true);
        } else {
            setCurrentOrderId(index);
        }
    }

    const [totalWeight, setTotalWeight] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    // A utility function to compute updated carriers with totalWeight and totalProducts
const computeUpdatedCarriers = (prevCarriers) => {
    return prevCarriers.map(carrier => {
        let newTotalWeight = 0;
        let newTotalAmount = 0;

        carrier.rows.forEach(productRow => {
            newTotalWeight += (productRow.amount * productRow.product.weight);
            newTotalAmount += productRow.amount;
        });

        return {
            ...carrier,
            totalWeight: newTotalWeight,
            totalProducts: newTotalAmount
        };
    });
};

useEffect(() => {;
    const updatedCarriers = computeUpdatedCarriers(carriers);
    
    // Check if the new calculated values differ from the current carriers
    if (JSON.stringify(updatedCarriers) !== JSON.stringify(carriers) && showEcheck) {
        setCarriers(updatedCarriers);
    }

    const overallTotalWeight = updatedCarriers.reduce((acc, curr) => acc + curr.totalWeight, 0);
    const overallTotalAmount = updatedCarriers.reduce((acc, curr) => acc + curr.totalProducts, 0);

    setTotalWeight(overallTotalWeight);
    setTotalAmount(overallTotalAmount);

   // console.log("carriers", carriers);
}, [carriers]);

useEffect(() => {
  //  console.log("data", currentOrder);
}, [currentOrder]);

const selectLabel = (carrierIndex, index) => {
    setCarriers(prev => {
        const updatedCarriers = prev.map((carrier, idx) => 
            idx === carrierIndex ? { ...carrier, deliveryOption: deliveryOptions[carrierIndex][index] } : carrier
        );
        return updatedCarriers;
    });
};

const CustomComponent = (props) => {
    return (
      <Box sx={{display:"flex", justifyContent:"flex-start", alignItems:"flex-start", gap:1}}>
          {props.option.labelDisplayName}
          (€{props.option.labelPrice.totalPrice})
          {props.option.recommended ? <StarOutlineOutlined width={16} height={16}/> : <></>}
      </Box>
    );
};

const finalize = async () => {
    const response = await finalizeResponse({
        "order": currentOrder,
        "carriers": carriers,
        "shipper": selectedShipper.id,
        "workspace": selectedWorkSpace.id
    }).unwrap();

    if(response.openFiles){
        response.labelContent.forEach(content => {
            const decodedData = atob(content);
            const arrayBuffer = new ArrayBuffer(decodedData.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < decodedData.length; i++) {
                uint8Array[i] = decodedData.charCodeAt(i);
            }
            const blob = new Blob([uint8Array], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(blob);
            window.open(pdfUrl, '_blank');
        });
    }

    NextOrder();
}

    const header = {
        title: `Order ${currentOrder?.webshopreference}`,
        subTitle: [currentOrder?.relation.name, `(${currentOrder?.webshopcredentials.description})`],
        icon :{
            theme: "green",
            content: <Inventory2Icon/>
        }, 
        topRight: <>
        <Box
    sx={{
        height: 36,
        display: "flex",
        alignItems:"center",
        boxShadow: ' 0px 1px 2px 0px rgba(49, 65, 95, 0.25)',
        pr:0.5,
        pl:3,
        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
        borderRadius:6,
        "@media screen and (max-width: 47.9375em)" : {
            display:"none"
        }

        
        // flexDirection:"column",
        // gap:0.5,
    }}
>
    <Box
        sx={{
            mr:3,
            display:"flex",
            alignItems:"center",
            gap:2
        }}
    >
        <Text light>{t("carrier_advice")}:</Text>
        <Subheading>
            {currentOrder?.suggestedCarrier?.length ? 
                Object.values(currentOrder.suggestedCarrier.reduce((acc, { name }) => {
                    acc[name] = (acc[name] || 0) + 1;
                    return acc;
                }, {}))
                .map((count, name) => `${count}x ${name}`)
                .join(' / ') : 
                "Geen drageradvies"
            }
        </Subheading>
    </Box>
</Box>
        <Box
    sx={{
        height: 36,
        display: "flex",
        alignItems:"center",
        boxShadow: ' 0px 1px 2px 0px rgba(49, 65, 95, 0.25)',
        pr:0.5,
        pl:3,
        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
        borderRadius:6,
        "@media screen and (max-width: 47.9375em)" : {
            display:"none"
        },
        marginRight: 3,
        marginLeft: 3
    }}
>
    <Box
        sx={{
            mr:3,
            display:"flex",
            alignItems:"center",
            gap:2,
        }}
    >
        <Text light>{t("shipper")}:</Text>
        <Subheading>{selectedShipper?.name}</Subheading>
    </Box>
    <ButtonBase
        sx={{
            width:32,
            height:32,
            background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
            color:"#fff",
            borderRadius:5,
        }}
    >
        <DropdownBase
            transformOrigin={{
                vertical: "bottom"
            }}
            anchorOrigin={{
                vertical:"top"
            }}
            displayName={"name"} 
            onChange={(selected) => selectShipper(selected)}
            noSelection={""} //Custom text when nothing is selected
            options={shippers?.filter(shipper => shipper.type !== 'bol' || currentOrder?.webshopcredentials?.webshop_type === 'bol')}
            selected={-1}
            closeOnSelection
            resetOnSelection
        >
            <EditOutlinedIcon/>
        </DropdownBase>
    </ButtonBase>
</Box>
        </>                       
    }

    const settings = {
        background: theme.palette.mode === "dark" ? colors.grey[300] : colors.bg["tertiary"],
        paddingBottom:3
    }

    const PickedView = () => {
        return (
            <>
                {carriers.map((carrier, carrierIndex) => ContainerView(carrier, carrierIndex))}
            </>
        );
    };
    
    const ContainerView = (carrier, carrierIndex) => {
        
        return (
            <Box
                sx={{
                    flex: 1,
                    background: theme.palette.mode === "dark" ? colors.grey[300] : colors.bg["tertiary"],
                    borderRadius: 4,
                    marginTop: 3,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: 3,
                    postion:"relative"
                }}
            >
                
                <SidepanelHeader2
                    relative
                    settings={settings}
                    setHeader={setHeader2}
                    content={{
                        title: carrier.carrierName === '' ? "Scan drager ..." : carrier.carrierName,
                        subTitle: [
                            <>
                                <Text bold>{carrier.totalProducts}</Text> <Text light>Producten</Text>
                            </>, 
                            <>
                                <Text bold>{carrier.totalWeight}</Text> <Text light>kg</Text>
                            </>
                        ],
                        beforeChild: selectedShipper?.type === "bol" && carrier.rows.length > 0 && (
                        <Box
                            sx={{
                                height: 36,
                                display: "flex",
                                alignItems:"center",
                                justifyContent:"space-between",
                                boxShadow: ' 0px 1px 2px 0px rgba(49, 65, 95, 0.25)',
                                pr:0.5,
                                pl:3,
                                background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                                borderRadius:6,
                                "@media screen and (max-width: 47.9375em)" : {
                                    display:"none"
                                },
                                mb:3,
                            }}
                        >
                            <Box
                                sx={{
                                    mr:3,
                                    display:"flex",
                                    alignItems:"center",
                                    gap:2,
                                }}
                            >
                                <Text light>{t("label")}:</Text>
                                <Text semibold>{carrier.deliveryOption ? `${carrier.deliveryOption.labelDisplayName} (€ ${carrier.deliveryOption.labelPrice.totalPrice})` : t("no_label")}</Text>
                            </Box>
                            <ButtonBase
                                sx={{
                                    width:32,
                                    height:32,
                                    background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
                                    color:"#fff",
                                    borderRadius:5,
                                }}
                            >
                                {/* <DropdownBase
                                    transformOrigin={{
                                        vertical: "bottom"
                                    }}
                                    anchorOrigin={{
                                        vertical:"top"
                                    }}
                                    displayName={"labelDisplayName"} 
                                    onChange={(selected) => selectLabel(carrierIndex,selected)}
                                    noSelection={"no_label"} //Custom text when nothing is selected
                                    options={deliveryOptions[carrierIndex] || []}
                                    selected={carrier.deliveryOption ? carrier.deliveryOption.id : -1}
                                    closeOnSelection
                                    resetOnSelection
                                >
                                    <EditOutlinedIcon/>
                                </DropdownBase> */}
                                
                                <DropdownBase
                                    displayName={"labelDisplayName"} 
                                    options={deliveryOptions[carrierIndex] || []}
                                    onChange={(selected) => selectLabel(carrierIndex,selected)}
                                    selected={carrier.deliveryOption ? deliveryOptions[carrierIndex].indexOf(carrier.deliveryOption) : -1}
                                    noSelection={t("no_label")} 
                                    maxHeight={"unset"}
                                    useTranslation 
                                    closeOnSelection
                                    disallowDeselection
                                    defaultStyling
                                    shComponent={<CustomComponent/>} // new
                                >
                                        
                                    <EditOutlinedIcon/>
                                </DropdownBase>
                            </ButtonBase>
                        </Box>
                        ),
                        icon: {
                            theme: "green",
                            content: <Inventory2Icon/>
                        }
                    }}
                />
                
                {carrier.rows.map(row => (
                    <Box
                        sx={{
                            paddingLeft: 10,
                            marginBottom: 2
                        }}
                    >
                        {ProductRow(row.amount, row.product.description,row.product.sku, true)}
                    </Box>
                ))}
            </Box>
        );
    }

    // const CarriersView = () => {
    //     return (
    //         <Box
    //             sx={{
    //                 flex: 1,
    //                 background: theme.palette.mode === "dark" ? colors.grey[300] : colors.bg["tertiary"],
    //                 borderRadius: 4,
    //                 marginTop: 3,
    //                 overflow: "hidden",
    //                 display: "flex",
    //                 flexDirection: "column",
    //                 paddingBottom: 3,
    //             }}
    //         >
    //             <SidepanelHeader
    //                 settings={settings}
    //                 content={{
    //                     title: "Dragers",
    //                     subTitle: [
    //                         <>
    //                             <Text bold>{totalCarriersAmount}</Text> <Text light>dragers</Text>
    //                         </>, 
    //                         <>
    //                             <Text bold>{totalCarriersWeight}</Text> <Text light>g</Text>
    //                         </>
    //                     ],
    //                     icon: {
    //                         theme: "green",
    //                         content: <Inventory2Icon/>
    //                     },                            
    //                 }}
    //             />
                
    //             {carrierRows.map(row => (
    //                 <Box
    //                     sx={{
    //                         paddingLeft: 10,
    //                         marginBottom: 2
    //                     }}
    //                 >
    //                     {ProductRow(row.Amount, row.Description)}
    //                 </Box>
    //             ))}
    //         </Box>
    //     );
    // }
    
    const ProductRow = (amount, description, sku, isPulse) => {
      //  console.log(sku);
        // let scanlabel = "";
        // if(scanned){
        //     scanlabel = (<Box sx={{ width: '100px' }}><ShLabel fitted size={24} sx={{marginLeft: 2}}>
        //         Scanned
        //     </ShLabel></Box>)
        // }
        let pulse = "";
        if(isPulse){
            pulse = "1s pulsate"
        }
        return (<Box
            sx={{
                flex:1,
                display:"flex",
                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                border: theme.palette.mode === "light" ? `1px solid ${colors.grey[100]}` :null,
                boxShadow: theme.palette.mode === "light" ? "0 2px 4px rgba(49,65,95,.15)" : null,
                borderRadius:4,
                padding:1,
                alignItems:"center",
                justifyContent: "space-between",
                animation: pulse
            }}>
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",

                    }}
                >
                    <ShLabel size={32} fitted palette="normal" variant={"orange"}>
                        {amount}
                    </ShLabel>
                    <Text semibold sx={{marginLeft: 2,}}>
                        {description}
                    </Text>
                </Box>
                    <Text light>
                        {sku}
                    </Text>
            {/* {scanlabel} */}
        </Box>);
    }


    const OrdersView = () => {
        if(showOrders)
        return (<Box
            sx={{
                display:"flex",
                flexDirection:"column",
                "@media screen and (max-width: 47.9375em)" : {
                    width:1
                }
            }}
        >
            <Box display={"flex"} alignItems={"center"} gap={1.5}
            
                sx={{
                    marginBottom:3,
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"none"
                    }
                }}
            >
                <Subheading>{t("orders")}</Subheading>
                <ShLabel fitted size={32} variant="orange" bold>{incData?.orders?.length ?? 0}</ShLabel>
            </Box>
            
            {/* Overflowrow */}
            <Box paddingRight={3}
                sx={{
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0
                    }
                }}
            >
                <Box className={"overflow-inner"}
                    sx={{
                        display:"flex",
                        flexDirection:"column"
                    }}
                >
                    {/* Card */}
                    {incData?.orders.map((order,index) => (
                        <ButtonBase 
                            className={cx({
                                'active': index === currentOrderId,             
                            })}
                            onClick={() => orderClick(index)}
                            component="div"
                            TouchRippleProps={{
                                style: {
                                    color: "#31415f",
                                }
                            }}
                        sx={{
                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                            width:400,
                            // height:300,
                            borderTop:`2px solid ${colors.pastel.light.green[600]}`,
                            boxShadow:"0px 1px 2px rgba(49, 65, 95, 0.25)",
                            paddingX:3,
                            paddingY:2,
                            borderRadius:3,
                            marginBottom:2,
                            position:"relative",
                            overflow:"hidden",

                            "&.active" : {
                                background:"orange",
                                background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.grey[100]
                            },

                            "@media screen and (max-width: 47.9375em)" : {
                                width:1
                            }
                        }}
                    >
                            {/* {currentOrder} */}
                            {/* Number status */}
                            <Box
                                sx={{
                                    paddingX:2,
                                    paddingY:1,
                                    position:"absolute",
                                    right:0,
                                    top:0,
                                    fontSize:14,
                                    borderRadius:"8px 8px 8px 16px",
                                    display:"inline-flex",
                                    fontWeight:700,
                                    background: order.finished && !order.joker ? colors.green[100] : order.finished && order.joker ? colors.orange[100] : colors.blue[100],
                                    color: order.finished && !order.joker ? colors.green[600] : order.finished && order.joker ? colors.orange[600] : colors.blue[600],
                                }}
                            >
                            {order.finished && !order.joker && <CheckOutlinedIcon />}
                            {order.finished && order.joker && <NewReleasesOutlinedIcon />}
                            {!order.finished && <IndeterminateCheckBoxOutlinedIcon />}
                            </Box>

                            {/* Header */}

                            {/* Inner */}

                                <Box display={"flex"} flexDirection={"column"}>
                                    <Text bold>{order.webshopreference}</Text>
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Text>{order.deliveryName}</Text>
                                         <Circle/>
                                        {/*<Text>{order.suggestedCarrier?.name ?? ""}</Text> */}
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        marginTop:3,
                                        display:"flex",
                                        alignItems:"center"
                                    }}
                                >
                                    <Box>{order.orderlines.reduce((total, row) => total + row.amount, 0)} Producten</Box>
                                    <Circle/>
                                    <Box>{order.relation?.name}</Box>
                                </Box>

                        </ButtonBase>
                    ))}
                </Box>
            </Box>
        </Box>);
    }

    const OptionsView = () => {
        if(showOptions)
        return (<Box
                    sx={{
                        // height:56
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        paddingBottom:2,
                        gap:4,
                        overflow:"hidden"
                    }}
                >
                    <Box
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:2
                        }}
                    >
                        <Text noBreak>Print pakbon per drager</Text>
                        <ShSwitch/>
                    </Box>

                    <Box
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:2
                        }}
                    >
                        <Text noBreak>Print pallet pakbonnen</Text>
                        <ShSwitch/>
                    </Box>

                    <Box
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:2
                        }}
                    >
                        <Text noBreak>Print pakbon met draagnummers na voltooien orderr</Text>
                        <ShSwitch/>
                    </Box>
                </Box> );      
    }
    
    

    const eCheckView = () => {
        if(showEcheck)
        return(<Box
            sx={{
                flex:1,
                background:theme.palette.mode === "dark" ? colors.grey[300] : colors.bg["tertiary"],
                borderTopRightRadius:16,
                borderTopLeftRadius:16,
                overflow:"hidden",
                display:"flex",
                flexDirection:"column",
                paddingBottom:3,
            }}
        ><SidepanelHeader2
            settings={settings}
            content={header}
            setHeader={setHeader2}
        />

        <Box
            sx={{
                flex:1,
                borderRadius:4,
                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                marginX:3,
                marginBottom:3,

                position:"relative",
                display:"flex",
                overflow:"hidden"
            }}
        >
            <Box
                sx={{
                    flex:1,
                    overflow:"auto",
                    padding:2
                }}
            >
                <Box
                    sx={{
                        display:"flex",
                        mb:3,
                    }}
                >
                    <Subheading>{toScanRows.reduce((total, row) => total + row.amount, 0)}</Subheading>&nbsp;
                    <Subheading light>Producten in krat</Subheading>&nbsp;
                    {/* <Subheading>M</Subheading> */}
                </Box>
                {toScanRows.map(row => (
                    <Box>
                        {ProductRow(row.amount, row.product.description,row.product.sku)}
                    </Box>
                ))}
                {barcodeView(false)}
            </Box>
            
            <Box
                width={120}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: 3,
                    justifyContent: 'center',  // To center contents vertically
                    alignItems: 'center',
                    color: colors.grey[300],
                    
                }}
            >
                {/* <DoubleArrowTwoToneIcon sx={{fontSize:'72px', opacity:'30%'}}/> */}
                <img 
            src="/img/arrow.svg" 
            alt="Barcode" 
            style={{
                width: '40%', 
                height: '40%', 
                objectFit: 'contain'
            }} 
        />
            </Box>

            <Box
                sx={{
                    flex:1,
                    overflow:"auto",
                    padding:2
                }}
            >
                    
                <Box
                    sx={{
                        display:"flex"
                    }}
                >
                    <Subheading>{totalAmount}</Subheading>&nbsp;
                    <Subheading light>producten in</Subheading>&nbsp;
                    <Subheading>{carriers.length}</Subheading>&nbsp;
                    <Subheading light>dragers</Subheading>
                </Box>
                {PickedView()}
                {/* {CarriersView()} */}
            </Box>
        </Box>
        
        {/* Footer */}
        <Box
            sx={{
                display:"flex",
                justifyContent:"space-between",
                alignItems:"center",
                paddingX:3,
            }}
        >
            <ButtonGroup
                version={2}
            >
                <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained">Print pakbon</Shbutton>
                
                <DropdownBase
                    transformOrigin={{
                        vertical: "bottom"
                    }}
                    anchorOrigin={{
                        vertical:"top"
                    }}
                    displayName={"name"} 
                    onChange={(selected) => selectManualCarrier(selected)}
                    noSelection={t("choose_carrier")} //Custom text when nothing is selected
                    options={carriersSelect}
                    selected={-1}
                    closeOnSelection
                    resetOnSelection
                >
                    <Shbutton className={"group-btn"} color={"primaryGrey"} variant="contained">{t("choose_carrier")}</Shbutton>
                </DropdownBase>

                <Shbutton className={"group-btn"} color={"primaryGrey"} variant="contained" onClick={() => OwnCarrierClick()}>{t("own_carrier")}</Shbutton>
            </ButtonGroup>
            
                <IconButton type="button" color={theme.palette.mode === "dark" ? "primaryGreyOpposite" : "primaryGrey"} sx={{p: 1}}>
                    <SettingsOutlinedIcon />
                </IconButton>

            <ButtonGroup version={2}>
                <Shbutton className={"group-btn"} color={"primaryGrey"} variant="contained" onClick={() => JokerClick()}>Joker</Shbutton>
                <Shbutton className={"group-btn"} color={"green"} variant="contained" onClick={() => FinalizeClick()}>Afronden</Shbutton>
            </ButtonGroup>
        </Box>
        </Box>)
    }

    const barcodeView = (large) => {
        
        const content = (<>
        {/* <Heading sx={{paddingTop:"10px"}}>
            Scan
        </Heading> */}

        <img 
            src="/img/barcode.svg" 
            alt="Barcode" 
            style={{
                width: '30%', 
                height: '30%', 
                objectFit: 'contain',
                animation: 'pulsate 1.5s infinite'
            }} 
        />

        <Box display="flex"
            height="32px"
            // maxWidth="400px"
            borderRadius="20px"
            backgroundColor={colors.grey[100]}
            sx={{
                marginTop:0.5,
                marginBottom:0.5,
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}
        >
            <InputBase sx={{ml: 2, flex:1, width: 248, textAlign: 'center', justifyContent: 'center'}} placeholder={t("scan_type_barcode")}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            autoFocus={true}
            //onKeyDown={handleKeyDown}
            />
        </Box></>)
        if(showEcheck && !large){
            return (<Box
                sx={{
                    flex: 1,
                    background: theme.palette.mode === "dark" ? colors.grey[300] : colors.bg["tertiary"],
                    borderRadius: 4,
                    marginTop: 3,
                    paddingTop:4,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: 3,
                    justifyContent: 'center',  // To center contents vertically
                    alignItems: 'center',      // To center contents horizontally
                }}
            >
                {content}
            </Box>)
        } else if(!showEcheck && large){
            return(<Box
                sx={{
                    flex: 1,
                    background: theme.palette.mode === "dark" ? colors.grey[300] : colors.bg["tertiary"],
                    borderTopRightRadius: 16,
                    borderTopLeftRadius: 16,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: 3,
                    justifyContent: 'center',  // To center contents vertically
                    alignItems: 'center',      // To center contents horizontally
                    gap: 2 // Gap between items
                }}
            >
                {content}
            </Box>)
        }

        
    }

    const [highlightColor, setHighlightColor] = useState('none');

    useEffect(() => {
        if (highlightColor !== 'none') {
        setTimeout(() => setHighlightColor('none'), 500); // Duration to stay colored
        }
    }, [highlightColor]);

    const getBackgroundColor = () => {
        switch (highlightColor) {
        case 'green':
            return 'green';
        case 'red':
            return 'red';
        default:
            return 'initial'; // or your default color
        }
    };

    return (
        <Box height={1} display={"flex"} width={1} gap={4} paddingX={4} paddingTop={13} overflow={"auto"}
        sx={{
            // overflow:"hidden",
            "@media screen and (max-width: 47.9375em)" : {
                
            },
            background: getBackgroundColor(), // You can use your original color instead of 'initial' if needed
            transition: 'background-color 0.5s'
        }}>
            {OrdersView()}

            {/* List content body */}
            <Box
                sx={{
                    flex:1,
                    display:"flex",
                    flexDirection:"column",
                    position:"sticky",
                    top:0,
                    minWidth:0,
                    // minHeight:"700px",
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"none"
                    },
                }}
            >
                {OptionsView()}

                
                    {barcodeView(true)}
                    {eCheckView()}

            </Box>
            <MConfirmationWithValue
                open={confirmModal.open}
                handleClose={() => confirmModal.setOpen(false)}
                data={confirmModal.data}
                confirm={confirmModal.confirm}
            />
            <MConfirmationWithValue
                open={productAmountModal.open}
                handleClose={() => productAmountModal.setOpen(false)}
                data={productAmountModal.data}
                confirm={productAmountModal.confirm}
            />
        </Box>
        
    );
    
};

export default Echeck;