
import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const CARRIERS_BASE = "/carriers";
const buildUrl = (endpoint) => CARRIERS_BASE + endpoint;
const ApiName = "Carriers";


const carriersApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCarriers: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}` }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getCarriers', `${ApiName}`)
        }),
        getCarriersSelect: builder.query({
            query: () => ({
                url: buildUrl(`/select`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
        getCarriersTypes: builder.query({
            query: () => ({
                url: buildUrl(`/types`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Types`, id: arg }]
        }),
        getCarrier: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
        getCarrierBarcode: builder.query({
            query: id => ({
                url: buildUrl(`/barcode/${id}`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}SingleBarcode`}],
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}SingleBarcode`}],
            keepUnusedDataFor: 0,
        }),
        addNewCarrier: builder.mutation({
            query: carrierInfo => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: carrierInfo,
            }),
            invalidatesTags: [`${ApiName}`],
        }),
        editCarrier: builder.mutation({
            query: carrierInfo => ({
                url: buildUrl(`/${carrierInfo.id}`),
                method: 'PUT',
                body: carrierInfo,
            }),
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg.id }, {type: `${ApiName}`}],
        }),
        deleteCarrier: builder.mutation({
            query: prop => ({
                url: buildUrl(`/${prop.id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg.id }, {type: `${ApiName}`}],
        }),
    }),
    extraReducers: (builder) => {
        builder.addCase(useGetCarrierBarcodeQuery.rejected, (state, action) => {
            // Capture the error in the state
            state.error = action.error;
            
            if (state[useGetCarrierBarcodeQuery.name]) {
              state[useGetCarrierBarcodeQuery.name].data = null;
            }
            
            // Check for 404 specifically if needed
            if (action.error.status === 404) {
              console.log('Carrier not found!');
            }
          })
    }
});

export const {
    useGetCarriersQuery,
    useGetCarriersSelectQuery,
    useGetCarriersTypesQuery,
    useGetCarrierQuery,
    useGetCarrierBarcodeQuery,
    useAddNewCarrierMutation,
    useEditCarrierMutation,
    useDeleteCarrierMutation
  } = carriersApiSlice;
