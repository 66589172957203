
import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const PRODUCT_FIELDS_BASE = "/productfields";
const buildUrl = (endpoint) => PRODUCT_FIELDS_BASE + endpoint;
const ApiName = "ProductFields";

const productFieldApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProductFields: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}` }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getProductFields', `${ApiName}`)
        }),
        getProductField: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
        addProductField: builder.mutation({
            query: productFieldInfo => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: productFieldInfo,
            }),
            invalidatesTags: [`${ApiName}`],
        }),
        editProductField: builder.mutation({
            query: productFieldInfo => ({
                url: buildUrl(`/${productFieldInfo.id}`),
                method: 'PUT',
                body: productFieldInfo,
            }),
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg.id }, {type: `${ApiName}`}],
        }),
        deleteProductField: builder.mutation({
            query: prop => ({
                url: buildUrl(`/${prop.id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg.id }, {type: `${ApiName}`}],
        }),
    }),
});

export const {
    useGetProductFieldsQuery,
    useGetProductFieldQuery,
    useAddProductFieldMutation,
    useEditProductFieldMutation,
    useDeleteProductFieldMutation
  } = productFieldApiSlice;
