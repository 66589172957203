import { Box, useTheme } from "@mui/material"
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { useAddInvoiceProductMutation } from "../../../newapi/financial/invoiceProductSlice";
import { showToastMessagePostRequest, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired } from "../../../utils/validation";
import { useGetEnumsQuery } from "../../../newapi/global/generalApiSlice";

const MAddInvoiceProduct = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [addInvoiceProduct,{isLoading}] = useAddInvoiceProductMutation();

    const [invoiceProductInfo, setInvoiceProductInfo] = useState({
        name: "",
        type: "",
        subType: "",
        invoiceline: "",
        ledger: ""
    });

    const { data: globalEnums, enumIsLoading } = useGetEnumsQuery("invoiceproduct_type,invoiceproduct_subtype");

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        type: yupRequired('type', t),
        subType: yupRequired('sub_type', t),
        invoiceline: yupRequired('invoiceline', t)
    };
    
    const schema = yup.object().shape(rules);
    
    const SaveInvoiceProductInfo = async (invoiceProductInfo, props) => {
        try {
            setFieldErrors({});
            await schema.validate(invoiceProductInfo, { abortEarly: false });
            const data = await addInvoiceProduct(invoiceProductInfo).unwrap()
            showToastMessagePostRequest(t, data);

            // reset object
            setInvoiceProductInfo({
                name: "",
                type: "",
                subType: "",
                invoiceline: "",
                ledger: ""
            });

            props.handleClose();
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("create_failed"), t, { success: false, error: err.error });
              }
        }
    };
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        setInvoiceProductInfo({
          ...invoiceProductInfo,
          [key]: value
        });
        setFieldErrors({
            ...fieldErrors,
            [key]: ''
          });
      };

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_invoiceproduct")}</Subheading>
            </Box>

            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7,
                        alignItems:"flex-start"
                    }}
                >
                    
                    <Box
                        sx={{
                            minWidth:210,
                            pt:1,
                        }}
                    >
                        <Text>{t("name")}</Text>
                    </Box>
                    
                    
                    <ShValidatedInput
                            name="name"
                            value={invoiceProductInfo.name}
                            onChange={handleChange}
                            error={fieldErrors.name}
                        />
                            
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7,
                        alignItems:"flex-start"
                    }}
                >
                    
                    <Box
                        sx={{
                            minWidth:210,
                            pt:1,
                        }}
                    >
                        <Text>{t("invoiceline")}</Text>
                    </Box>
                    
                    
                    <ShValidatedInput
                            name="invoiceline"
                            value={invoiceProductInfo.invoiceline}
                            onChange={handleChange}
                            error={fieldErrors.invoiceline}
                        />
                            
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7,
                        alignItems:"flex-start"
                    }}
                >
                    
                    <Box
                        sx={{
                            minWidth:210,
                            pt:1,
                        }}
                    >
                        <Text>{t("type")}</Text>
                    </Box>
                    
                    
                    <ShValidatedInputDropdown
                        name="type"
                        displayName="displayName"
                        changeField="name"
                        selected={globalEnums ? globalEnums["invoiceproduct.type"].findIndex(item => item === invoiceProductInfo.type) ?? -1 : -1}
                        options={globalEnums ? globalEnums["invoiceproduct.type"].map((item, index) => ({ id: index, name: item, displayName: t(item) })) : []}
                        noSelection={t("choose_option")} 
                        onChange={handleChange}
                        error={fieldErrors.type}
                    />
                            
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        justifyContent:"space-between",
                        gap:7,
                        alignItems:"flex-start"
                    }}
                >
                    
                    <Box
                        sx={{
                            minWidth:210,
                            pt:1,
                        }}
                    >
                        <Text>{t("sub_type")}</Text>
                    </Box>
                    
                    <ShValidatedInputDropdown
                        name="subType"
                        displayName="displayName"
                        changeField="name"
                        selected={globalEnums ? globalEnums["invoiceproduct.subtype"].findIndex(item => item === invoiceProductInfo.subType) ?? -1 : -1}
                        options={globalEnums ? globalEnums["invoiceproduct.subtype"].map((item, index) => ({ id: index, name: item, displayName: t(item) })) : []}
                        noSelection={t("choose_option")} 
                        onChange={handleChange}
                        error={fieldErrors.subType}
                    />
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7,
                        alignItems:"flex-start"
                    }}
                >
                    
                    <Box
                        sx={{
                            minWidth:210,
                            pt:1,
                        }}
                    >
                        <Text>{t("ledger")}</Text>
                    </Box>
                    
                    
                    <ShValidatedInput
                            name="ledger"
                            value={invoiceProductInfo.ledger}
                            onChange={handleChange}
                            error={fieldErrors.ledger}
                        />
                            
                </Box>
            </Box>
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"green"}
                    variant="contained" 
                    onClick={() => SaveInvoiceProductInfo(invoiceProductInfo, props)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddInvoiceProduct;
