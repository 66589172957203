
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
export const settingsWarehouseCountingStrategiesConfig = {
    all:  {
        title: 'counting_strategies',
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
    },
    
};
