
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';


export const settingsWarehouseWorkFlowTemplatesConfig = {
    all:  {
        title: 'workflow_templates',
        icon: <Inventory2OutlinedIcon />,
        isSingleDataGrid: true,
    },
};
