import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const TEMPLATE_BASE = "/countingstrategytemplates";
const buildUrl = (endpoint) => TEMPLATE_BASE + endpoint;

const countingStrategyTemplateSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCountingStrategyTemplates: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'CountingStrategyTemplates' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getCountingStrategyTemplates', 'CountingStrategyTemplates')
        }),
        getCountingStrategyTemplate: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            // providesTags: (id) => [{ type: `CountingStrategyTemplateSingle`,id }]
            providesTags: (result, error, arg) => [{ type: `CountingStrategyTemplateSingle`, id: arg }]
        }),
        addCountingStrategyTemplate: builder.mutation({
            query: countingStrategyTemplateInfo => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: countingStrategyTemplateInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'CountingStrategyTemplates'}],
        }),
        updateCountingStrategyTemplate: builder.mutation({
            query: countingStrategyTemplateInfo => ({
                url: buildUrl(`/${countingStrategyTemplateInfo.id}`),
                method: 'PUT',
                body: countingStrategyTemplateInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'CountingStrategyTemplates' }, { type: `CountingStrategyTemplateSingle`, id: arg.id }],
        }),
        deleteCountingStrategyTemplate: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'CountingStrategyTemplates' }, { type: `CountingStrategyTemplateSingle`, id: arg.id }],
        }),
    })
});

export const {
    useGetCountingStrategyTemplatesQuery,
    useGetCountingStrategyTemplateQuery,
    useAddCountingStrategyTemplateMutation,
    useUpdateCountingStrategyTemplateMutation,
    useDeleteCountingStrategyTemplateMutation
  } = countingStrategyTemplateSlice;
