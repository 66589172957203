// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { tokens } from '../../../../../theme';
import * as yup from "yup";
import { yupNumberRequired } from '../../../../../utils/validation';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { useDispatch } from 'react-redux';
import ShValidatedNumberInput from "../../../../theme/inputs/ShValidatedNumberInput";


/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    
    const data = {...props.data};
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    return (
        <>
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:210,
                    }}
                >
                    <Text>{t("max_weight")}</Text>&nbsp;
                    <Text italic light>(kg)</Text>
                </Box>
                
                
                    <ShValidatedNumberInput
                            btnSize={"24"}
                            name="weight"
                            value={data.weight ?? 0}
                            onChange={handleChange}
                            error={props.errors.weight}
                        />
                        
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:210,
                    }}
                >
                    <Text>{t("max_length")}</Text>&nbsp;
                    <Text italic light>(cm)</Text>
                </Box>
                
                
                    <ShValidatedNumberInput
                            btnSize={"24"}
                            name="length"
                            value={data.length ?? 0}
                            onChange={handleChange}
                            error={props.errors.length}
                        />
                        
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:210,
                    }}
                >
                    <Text>{t("max_width")}</Text>&nbsp;
                    <Text italic light>(cm)</Text>
                </Box>
                
                
                    <ShValidatedNumberInput
                            btnSize={"24"}
                            name="width"
                            value={data.width ?? 0}
                            onChange={handleChange}
                            error={props.errors.width}
                        />
                        
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:210,
                    }}
                >
                    <Text>{t("max_height")}</Text>&nbsp;
                    <Text italic light>(cm)</Text>
                </Box>
                
                
                <ShValidatedNumberInput
                        btnSize={"24"}
                        name="height"
                        value={data.height ?? 0}
                        onChange={handleChange}
                        error={props.errors.height}
                    />
            </Box>
                
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:210,
                    }}
                >
                    <Text>{t("max_volume")}</Text>&nbsp;
                    <Text italic light>(cm3)</Text>
                </Box>
                
                
                <ShValidatedNumberInput
                        btnSize={"24"}
                        name="volume"
                        value={data.volume ?? 0}
                        onChange={handleChange}
                        error={props.errors.volume}
                    />
            </Box>
        </>
    );
};

const ShipperMeasurements = (props) => {
    const { t } = useTranslation();

    const rules = {
        weight: yupNumberRequired('max_weight', t),
        length: yupNumberRequired('max_length', t),
        width: yupNumberRequired('max_width', t),
        height: yupNumberRequired('max_height', t),
        volume: yupNumberRequired('max_volume', t),
    };
    
    const schema = yup.object().shape(rules);

    return (
        <ProgressionTab 
            tabId="tabMeasurements" 
            subtitle={t("measurements")} 
            step={3}
            nextTab={"tabShipperSender"}
            maxSteps={6}
            schema={schema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default ShipperMeasurements;

