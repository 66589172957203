import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { createFilterInstance, createViewInstance, removeFilterInstance, removeViewInstance } from '../newapi/global/filter/gridFilterSlice';
// import { createFilterInstance, removeFilterInstance } from '../newapi/global/filter/gridFilterSlice';
const TabContext = createContext("");

export const useTab = () => useContext(TabContext);

export const TabContextProvider = (props) => {
  const dispatch = useDispatch();
  
  const currentLength = useSelector(state => state.gridFilter.viewInstances.length);
  const [index, setIndex] = useState(null);

  useEffect(() => {
    const newIndex = currentLength; // This is the index the view instance will have
    setIndex(newIndex); // save it locally

    dispatch(createViewInstance());
  }, []); 

  
  return (
    <TabContext.Provider value={{ index }}>
      {props.children}
    </TabContext.Provider>
  );
};
