import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from 'react-i18next';
import DetailsPanel from "./Views/Details";
import { spWorkspaceDetailsConfig } from "./spWorkspaceDetailsConfig";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import { useFindWorkspaceByIdQuery } from "../../../../newapi/warehouse/workspaceSlice";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpWorkspaceDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;
    console.log("workspaceId",props.workspaceId);
    
    // useGetWarehouseLocationTypeQuery

    const { data: workspaceData, isLoading,isFetching,isError } = useFindWorkspaceByIdQuery(props.workspaceId, { skip: !props.workspaceId });
    // const { data: workspaceData, isLoading,isFetching,isError } = useFindWorkspaceByIdQuery(props.workspaceId,
    // {
    //     skip: !props.workspaceId
    // });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <WorkspacesOutlinedIcon/>
    }), [theme.palette.mode]);

    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "Workspace",
        // options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.workspaceId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading &&!isFetching && workspaceData) {
            setPanel(prevPanel => ({
                ...prevPanel,
                title: workspaceData.name,
                icon:defaultIcon,
                // subTitle: [""],
            }));
        }
    }, [workspaceData, isLoading,isFetching, t,theme.palette.mode]);

    // const [panel, setPanel] = useState({
    //     title: props.data?.description ?? "Workspace",
    //     subTitle: props.data?.internal_name,
    //     icon :{
    //         theme: theme.palette.mode === "dark" ? "blue" : "primary",
    //         content: <WarehouseIcon/>
    //     },
    //     //Heading row for default shit
    //     options : [],
    //     actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    // });

    // useEffect(() => {
    //     if (typeof data !== "undefined" && typeof data.id !== "undefined") {
    //       setPanel((prevPanel) => ({
    //         ...prevPanel,
    //         title: data.name,
    //         subTitle: [""],
    //         options: [
    //         ],
    //       }));
    //     }
    //   }, [data]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spWorkspaceDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <DetailsPanel config={spWorkspaceDetailsConfig.details} id={props.workspaceId} data={workspaceData} />
                  
                </>
        </Sidepanel2>
    );
};

export default SpWorkspaceDetails;