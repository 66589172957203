import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTab, setCurrentStep, updateData, setProgressionInfo, setPreviousTabActive, setTabErrors } from '../../../newapi/global/progressionTabSlice';
import { Box, useTheme } from '@mui/material';
import Shbutton from '../buttons/Shbutton';
import { tokens } from '../../../theme';
import ButtonGroup from '../buttons/ButtonGroup';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useTranslation } from "react-i18next";

/**
 * TabView Component
 * 
 * Props:
 * - tabId: String, unique identifier for the tab.
 * - step: Number, the current step in the progression.
 * - nextTab: String, identifier for the next tab.
 * - title: String, title to display in the progression wrapper.
 * - subtitle: String, subtitle to display in the progression wrapper.
 * - maxSteps: Number, the total number of steps in the progression.
 * 
 * Children:
 * - React component(s) that represent the content of the tab.
 */
const ProgressionTab = (props) => {
  if(props.step > 1 && typeof props.maxSteps === "undefined") throw new Error("Missing maxsteps prop for tab with tabid " + props.tabId);
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const { currentTab, title, subtitle, maxSteps, currentStep,previousTabs, errors, data,query } = useSelector((state) => state.progressiontabs);

  const tabData = useSelector(state => state.progressiontabs.data);


  let isStandAlone = false;
  if (window.matchMedia('(display-mode: standalone)').matches) {
      isStandAlone = true;
  }

  useEffect(() => {
    if(props.step === 1){
      if(!currentTab) {
        dispatch(setCurrentTab(props.tabId));
      }
    }
  },[]);

  useEffect(() => {
    //console.log("currentTabbie",currentTab);
    //console.log("props.tabId",props.tabId);
    if(currentTab === props.tabId) {
      if (props.title || props.subtitle || typeof props.maxSteps !== 'undefined') {
        dispatch(setProgressionInfo({ title: props.title, subtitle: props.subtitle, maxSteps: props.maxSteps }));
      }
      if (typeof props.step !== 'undefined') {
      //  console.log(currentTab,props.step);
        dispatch(setCurrentStep(props.step));
      }

      //First step needs to set max steps to 1 if there is no max step in the first option given.
      if(props.step === 1 && typeof props.maxSteps === "undefined") {
        dispatch(setProgressionInfo({maxSteps:1}))
      }
    }
  },[currentTab]);

  const goBack = () => {
    dispatch(setPreviousTabActive());
  }

  const handleDataUpdate = (newData) => {
    dispatch(updateData(newData));
  };

  const handleNext = async (tabId) => {
    if(props.schema){
      try {
          dispatch(setTabErrors({}));
          // Validate the data using the Yup schema
          await props.schema.validate(tabData, { abortEarly: false });
          // If validation is successful, navigate to the next tab
          dispatch(setCurrentTab(tabId || props.nextTab));
      } catch (err) {
          if (err.name === 'ValidationError') {
              // Create an errors object where keys are field names and values are error messages
              const errors = err.inner.reduce((acc, currentError) => ({
                ...acc,
                [currentError.path]: currentError.message
              }), {});
              
              // Set the validation errors in the state
              // Dispatch an action to store the validation errors
              dispatch(setTabErrors(errors));
          }
      }
    }else {
      dispatch(setCurrentTab(tabId || props.nextTab));
    }
  };

  const handleSave = () => {
    if(props.schema){
      try {
          dispatch(setTabErrors({}));
          // Validate the data using the Yup schema
          props.schema.validate(tabData, { abortEarly: false })
          .then((validatedData) => {
              // If validation is successful, save data
              dispatch(setProgressionInfo({ shouldSaveData: true}));
          })
          .catch((err) => {
              // Handle the validation error here
              if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                // Dispatch an action to store the validation errors
                dispatch(setTabErrors(errors));
            }
          });
      } catch (err) {
          if (err.name === 'ValidationError') {
              // Create an errors object where keys are field names and values are error messages
              const errors = err.inner.reduce((acc, currentError) => ({
                ...acc,
                [currentError.path]: currentError.message
              }), {});
        
              // Set the validation errors in the state
              // Dispatch an action to store the validation errors
              dispatch(setTabErrors(errors));
          }
      }
    } else {
      dispatch(setProgressionInfo({ shouldSaveData: true}));
    }
   // console.log("shud save");
    // props.handleSave();
  }
  
// data ophalen -> useSelector

  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, { handleDataUpdate, handleNext, errors: errors, data: tabData })
  );

  if (props.tabId !== currentTab) return null;

  return (
    <>
      <Box
        sx={{
          // background:"orange",
          paddingX:3,
          paddingY:3,
          background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
          borderTopLeftRadius:24,
          borderTopRightRadius:24,
          borderBottomLeftRadius:previousTabs.length > 1 || props.nextTab  ? 0 : 24,
          borderBottomRightRadius:previousTabs.length > 1 || props.nextTab  ? 0 : 24,
          paddingBottom: isStandAlone && !(previousTabs.length > 1 || props.nextTab) ? 4 : null
        }}
      >
        {childrenWithProps}
      </Box>
      {/* {previousTabs.length > 1 && <button onClick={() => goBack()}>Back</button>} */}
      {/* {props.nextTab && <button onClick={() => handleNext()}>Next</button>} */}

      {previousTabs.length > 1 || props.nextTab ? 
        <Box
            component="footer"
            sx={{
                
                display:"block",
                width:1,
                paddingBottom: isStandAlone ? "32px" : "16px",
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between",
                paddingRight:3,
                zIndex:1058,
                position:"relative",
                paddingTop:1.5,
                background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                borderBottomLeftRadius:24,
                borderBottomRightRadius:24,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingRight:0,
                }
            }}
        >
            {previousTabs.length > 1 ?
                <Shbutton 
                  isCancel={true} 
                  className={"always-visible"} 
                  onClick={() => goBack()} 
                  color="primary" 
                  variant="text" 
                  startIcon={<ArrowRightAltIcon/>}
                  tabindex={2}
                >
                  {t('back')}
                </Shbutton>
                :
                <Box></Box>
            }
            {(props.nextTab || (currentStep === maxSteps)) &&
              <ButtonGroup version={2}>
                  {props.nextTab ?
                    <Shbutton 
                      className={"group-btn"} 
                      color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                      onClick={() => handleNext()} 
                      variant="contained"
                      tabindex={1}
                    >
                      {t('next')}
                    </Shbutton>
                  : 
                    <Shbutton 
                      className={"group-btn"} 
                      color={"green"} 
                      onClick={() => handleSave()} 
                      variant="contained"
                      tabindex={1}
                    >
                      {t('save')}
                    </Shbutton>
                  }
              </ButtonGroup>
            }
        </Box>
      :null}
    </>
  );
};

export default ProgressionTab;