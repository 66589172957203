import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";

import { useTranslation } from "react-i18next";
import OrderLinesCard from "../../../../theme/cards/orders/OrderLinesCard";
import { useGetInvoiceLinesQuery } from '../../../../../newapi/financial/invoiceSlice';
import ShTextSkeleton from '../../../../theme/skeleton/ShTextSkeleton';
import ShLabelSkeleton from '../../../../theme/skeleton/ShLabelSkeleton';
import ShDataGrid2 from '../../../../theme/datagrid2/ShDataGrid2';

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    
    filter : {
        name: "productsFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Invoicelines = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    

    const columns = [
        {field: "amount", headerName: t("amount"), renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.amount;
        }},
        {field: "name", headerName: t("name"), flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.invoiceProduct.name;
        }},
        {field: "price", headerName: t("price"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return params.row.relationInvoiceProduct.price;
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <ShTextSkeleton/>
                )
            }
        },
    ];

    return (
        props.isActive ? (
          <>
            <Box height={1} display={"flex"} flexDirection={"column"} overflow={"auto"}>
                {props.invoiceId &&  
                    <ShDataGrid2 key={theme.palette.mode}
                        itemId={props.invoiceId}
                        content={useGetInvoiceLinesQuery}
                        // gridActions={<GridActions></GridActions>}
                        title={t("invoicelines")}
                        gridOptions={gridOptions}
                        isSidepanelGrid
                        columns={columns}
                    />
                }
            </Box>
          </>
        ) : null
      );
      
}

export default Invoicelines;