// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from '../../../../../theme';
import ShSwitch from "../../../../theme/inputs/ShSwitch";

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    
    const [newInboundLines, setNewInboundLines] = useState([{
        isDescriptionRow: false,
        description: "",
        sku: "",
        amountForecasted: 0,
        index: 1
    }]);

    const handleChange = (index, key, value) => {
        setNewInboundLines(currentLines => {
            return currentLines.map(line => {
            if (line.index === index) {
                return {
                ...line,
                [key]: value
                };
            }

            return line;
            });
        });

        if (newInboundLines[newInboundLines.length - 1].sku.length > 0 || newInboundLines[newInboundLines.length - 1].description.length > 0) {
            setNewInboundLines([...newInboundLines, {
                isDescriptionRow: false,
                description: "",
                sku: "",
                amountForecasted: 0,
                index: newInboundLines[newInboundLines.length - 1].index + 1
            }]);
        }
    };
    
    useEffect(() => {
        const mappedInboundLines = newInboundLines
        .filter(x => (x.sku.length > 0 && x.amountForecasted > 0) || x.description.length > 0)
        .map(x => ({
            isDescriptionRow: x.isDescriptionRow,
            description: x.description,
            sku: x.sku,
            amountForecasted: x.amountForecasted
        }));

        handleInput("inboundLines", mappedInboundLines);
    },[newInboundLines]);
      
    const handleDeleleteLineRow = (index) => {
        const updatedInboundLines = newInboundLines.filter(line => line.index !== index);
        setNewInboundLines(updatedInboundLines);
    }

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
    };

    return (
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                {newInboundLines.map((inboundLine, index) => (
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap:4,
                        justifyContent:"flex-start",
                    }}
                >
                <Box 
                    sx={{
                        width:128,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between"
                    }}
                >
                    <Box
                        sx={{
                            width:152,
                        }}
                    >
                        <Text light>{t("no_sku")}</Text>
                    </Box>
                    <Box
                    >
                        <ShSwitch checked={inboundLine.isDescriptionRow} onChange={(event) => handleChange(inboundLine.index, 'isDescriptionRow', event.target.checked)} color="green" />
                    </Box>
                </Box>
                    {inboundLine.isDescriptionRow ?
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:1
                        }}
                    >
                        <Box
                            sx={{
                                width:80,
                            }}
                        >
                            <Text light>{t("description")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32
                            }}
                        >
                            <InputBase 
                                sx={{
                                    px:2,
                                    flex:1, 
                                    color:colors.txt["primary"],
                                    lineHeight:"32px",
                                }}
                                value={inboundLine.description}
                                onChange={(e) => handleChange(inboundLine.index, 'description', e.target.value)}
                                />
                        </Box>
                    </Box>
                    : 
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:1
                        }}
                    >
                        <Box
                            sx={{
                                width:80,
                            }}
                        >
                            <Text light>{t("sku")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32
                            }}
                        >
                            <InputBase 
                                sx={{
                                    px:2,
                                    flex:1, 
                                    color:colors.txt["primary"],
                                    lineHeight:"32px",
                                }}
                                value={inboundLine.sku}
                                onChange={(e) => handleChange(inboundLine.index, 'sku', e.target.value)}
                                />
                        </Box>
                    </Box>
                    }
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:1
                        }}
                    >
                        <Box
                            sx={{
                                width:64,
                            }}
                        >
                            <Text light>{t("quantity")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                width:120,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32
                            }}
                        >
                            <NumberInput btnSize={"24"}
                                value={inboundLine.amountForecasted}
                            change={(e) => handleChange(inboundLine.index, 'amountForecasted', e)}/>
                        </Box>

                        { inboundLine.index > 1 ?
                        <>
                        <IconButton type="button" onClick={() => handleDeleleteLineRow(inboundLine.index)} sx={{p:1}}>
                            <DeleteIcon/>
                        </IconButton>
                        </> 
                        : null
                        }
                    </Box>
                </Box>
                ))}
            </Box>
    );
};

const InboundLines = (props) => {
    const {t} = useTranslation();
    return (
        <ProgressionTab 
        tabId="tabInboundLines" 
        subtitle={t("inboundlines")} 
        step={2}
        maxSteps={2}
    >
        <Content />
    </ProgressionTab>
    )
}

export default InboundLines;

