import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import Text from "../../../../../components/theme/text/Text";
import {useGetProductQuery, useGetProductsQuery} from "../../../../../newapi/warehouse/productSlice"
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import SpVirtualProductDetails from "../../../../../components/global/Sidepanels/SpVirtualProductDetails/SpVirtualProductDetails";
import WarehouseVirtualProductCard from "../../../../../components/theme/cards/products/warehouse/WarehouseVirtualProductCard";
import MpAddVirtualProduct from "../../../../../components/global/ModalsProgression/MpAddVirtualProduct/MpAddVirtualProduct";
import { useSelector } from "react-redux";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";

const GridActions = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);
    return (
        <ButtonGroup version={2}>
            {
            isFulfilment ?
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t("new")}
            </Shbutton>
            : <></>
            }
        </ButtonGroup>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const VirtualProductOverview = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [config, setConfig] = useState({isVirtual: true});
    const [isExtended, setIsExtended] = useState(false);
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    const handleOpen = () => {
        setAddModalOpen(true);
    }

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions handleOpen={handleOpen}></GridActions>,
        });

        return () => {
            
        }
    },[]);

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    // const [productId, setProductId] = useState();
    // const productIdRef = useRef(productId);
    // useEffect(() => {
    //     productIdRef.current = productId;
    // }, [productId]);

    // const { data: productData, isLoading: isLoadingProduct } = useGetProductQuery(productId,
    //     {
    //         skip: !productId
    //     });
        
    // const getProductDetails = (params) => {
    //     if (!params) return;
    //     if (typeof productIdRef.current !== "undefined" &&  parseInt(productIdRef.current) === parseInt(params.id)) {
    //         setIsExtended(true);
    //     } else {
    //         setProductId(params.id);
    //     }
    // };

    // useEffect(() => {
    //     if (!isLoadingProduct && productData) {
    //         setIsExtended(true);
    //     }
    // }, [productData, isLoadingProduct]);

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (params) => {
        if (!params) return;
    
        const newproductId = parseInt(params.row.id);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newproductId) {
            setProductId(newproductId);
            productIdRef.current = newproductId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {
            field: "sku",
            headerName: t("sku"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton palette={theme.palette.mode === "dark" ? "pastel" : "normal"} variant={theme.palette.mode === "dark" ? "blue" : "primary"} />
                }
                return (
                    <Box>
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.sku}</Text>
                    </Box>
                );
            }
        },
        {
            field: "description",
            headerName: t("description"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.description
            },
        },
        ...isFulfilment ? [{
            field: "relation",
            headerName: t("relation"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (params.row.relation ? params.row.relation.name : t('unknown'));
            },
        }] : [],
        {
            field: "products",
            headerName: t("products"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (params.row.childProducts ? params.row.childProducts.length : 0);
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
                return (
                    <WarehouseVirtualProductCard skeleton={cellValues.row.skeleton ?? false} {...cellValues.row}/>
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >

                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetProductsQuery}
                config={config}
                gridActions={<GridActions handleOpen={handleOpen}></GridActions>} 
                title={t('overview')} 
                onRowClick={getProductDetails}
                gridOptions={gridOptions} 
                 
                columns={columns}>
            </ShDataGrid2>
            {/* <SpVirtualProductDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                data={productData}
                actionButtons={false}
            ></SpVirtualProductDetails> */}

            <SpVirtualProductDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                virtualProductId={productId}
                // data={productData}
                actionButtons={false}
            ></SpVirtualProductDetails>

            <MpAddVirtualProduct onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}
export default VirtualProductOverview;