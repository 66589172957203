import { useTranslation } from "react-i18next";

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal";
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper";
import ChooseWebshopType from "./views/ChooseWebshopType";
import Credentials from "./views/Credentials";
import { useCreateWebshopCredentialsMutation } from "../../../../newapi/webshop/webshopSlice";
import MeasurementUnits from "./views/MeasurementUnits";
import Sync from "./views/Sync";

const MpAddWebshop = (props) => {
    const {t} = useTranslation();

    const [CreateWebshopCredentials,{isLoading}] = useCreateWebshopCredentialsMutation();
    const onSave = () => {
        props.handleClose();
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} query={useCreateWebshopCredentialsMutation} isModal title={t("add_webshop")}>
                <ChooseWebshopType/>
                <Credentials/>
                <MeasurementUnits/>
                <Sync/>
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddWebshop;