import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline, ThemeProvider } from "@mui/material";
import './styles/index.css';
import './styles/animations.css';
// import './styles/sh_daterangepicker.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { MenuStateContext, useToggle } from './contexts/MenuState';
// import { AxiosBackendWrapper, AxiosWrapper } from './api/Wrappers';
import './i18n';

import { Provider, useDispatch, useSelector } from 'react-redux';
import {store, persistor} from './newapi/store';
import { PersistGate } from 'redux-persist/integration/react';
import RmaApp from './environments/rmaportal/RmaApp';
import { getTheme, selectThemeMode, setThemeMode } from './newapi/global/settingsSlice';
import ThemeManager from './ThemeManager';

const hardTest = false;
const executeServiceWorker= process.env.NODE_ENV === "production" ? true : hardTest;

// Registering service worker
if ('serviceWorker' in navigator) {
  if(executeServiceWorker){
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(`${process.env.PUBLIC_URL}/service-worker.js`)
        .then((registration) => {
          console.log('SW registered: ', registration);

          // Listen for messages from the service worker
          navigator.serviceWorker.addEventListener('message', event => {
            // Check for a specific message from the service worker
            if (event.data && event.data.type === 'RELOAD') {
              // Perform the reload 
              window.location.reload();
            }
          });

        })
        .catch((registrationError) => {
          console.log('SW registration failed: ', registrationError);
        });
    });
  } else {
    window.addEventListener("load", () => {
      navigator.serviceWorker.ready.then(registration => {
        registration.unregister().then(() => {
          console.log('Service worker unregistered.');
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(async (names) => {
              await Promise.all(names.map(name => caches.delete(name)));
              console.log('Caches cleared after service worker unregistration.');
            });
          }
        });
      });
    });
  }
}

// import { refresh } from './api/global/user';
const Root = () => {
  // const dispatch = useDispatch();
  // const themeMode = useSelector(selectThemeMode);
  // const theme = getTheme(themeMode);
  // const [theme, colorMode, setColorMode] = useMode();
  // const colors = tokens(theme);

  const [collapsed, setCollapsed] = useToggle();

  // const [authTokens, auth] = useAuth();
  // console.log(auth);

  // const listenForThemeChange = (e) => {
  //   const colorScheme = e.matches ? "dark" : "light";
  //   // setColorMode.setColorMode(colorScheme);
    
  //   dispatch(setThemeMode(colorScheme));
  //   if(collapsed && window.innerWidth <=1366){
  //       document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#181820": "#ecf0f5"}`);
  //       document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#181820": "#ecf0f5"}`);
  //   } else {
  //       document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? colors.bg["secondary"]: colors.bg["primary"]}`);
  //       document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? colors.bg["secondary"]: colors.bg["primary"]}`);
  //   }
  // }
  
  // useEffect(() => {
  //   let query = window.matchMedia("(prefers-color-scheme: dark)");
  //   if (query.addEventListener) {
  //     query.addEventListener('change', listenForThemeChange);
  //   } else {
  //     query.addListener(listenForThemeChange);
  //   }

  //   return () => {
  //     if (query.removeEventListener) {
  //       query.removeEventListener('change', listenForThemeChange);
  //     } else {
  //       query.removeListener(listenForThemeChange);
  //     }
  //   }
  // }, []);



  const [portal, setPortal] = useState(window.location.hostname.includes("returns") ? <RmaApp/> : <App/>);

  return (
    // <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <MenuStateContext.Provider value={{collapsed, setCollapsed}}>
              <ThemeManager>
                <CssBaseline />
                {portal}
              </ThemeManager>
            </MenuStateContext.Provider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    // </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);
