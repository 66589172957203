import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import PickingLines from "./Views/PickingLines";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import {useTranslation} from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../utils";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spOrderPickingListConfig } from "./spOrderPickingListConfig";
import { useGetOrderPickingListQuery } from "../../../../newapi/order/ordersSlice";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Close
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}


const HeaderDateBox = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [planned, setPlanned] = useState({});
    const [shipped, setShipped] =  useState({});
    
    useEffect(() => {
        setPlanned(getTranslatedDateAndTime(props.data?.date_planned, props.t));
        setShipped(getTranslatedDateAndTime(props.data?.date_shipped, props.t));
    },[props.data])


    return (
        <>
            {/* <Text light>Date</Text> */}
            <Box display={"flex"} alignItems={"center"} width={"min-content"}>
                <Box 
                    sx={{
                        width:66,
                        flexShrink:0,
                        mr:1,
                    }}
                >
                    <Text light>Scheduled</Text>
                </Box>
                {props.skeleton ?
                    <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
                : 
                    <Box
                        sx={{
                            display:"flex",
                            gap:1,
                        }}
                    >
                        <Text noBreak bold>{planned?.date}</Text>
                        <Text>{planned?.time}</Text>
                    </Box> 
                }
            </Box>
            <Box  display={"flex"} alignItems={"center"} width={"min-content"}>
                <Box 
                    sx={{
                        width:66,
                        flexShrink:0,
                        mr:1,
                    }}
                >
                    <Text light>Shipped</Text>
                </Box>
                {props.skeleton ?
                    <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
                : 
                    <>
                        <Box
                            sx={{
                                display:"flex",
                                gap:1,
                            }}
                        >
                            <Text noBreak bold>{shipped?.date}</Text>
                            <Text>{shipped?.time}</Text>
                        </Box> 
                    </>
                }
            </Box>
        </>
    )

}

const getOrderStatusColor = (data,theme)  => {
    let labelcolor = "grey";
    let planned = new Date(data?.date_planned);
    let sent = data?.date_sent ? new Date(data?.date_sent) : null;

    if(sent) {
        // when sent is filled and is equal to planned: green
        if(sent.getTime() === planned.getTime()) {
            labelcolor = "green";
        }
        // when sent is filled but later than planned: orange
        else if(sent.getTime() > planned.getTime()) {
            labelcolor = "orange";
        }
    }
    else {
        // When sent is not filled and planned >= now: blue
        if(planned.getTime() >= Date.now()) {
            labelcolor = theme.palette.mode === "dark" ?  "blue" : "primary";
        }
        // When sent is not filled and planned < now: red
        else if(planned.getTime() < Date.now()) {
            labelcolor = "red";
        }
    }

    return labelcolor;
}

const SpOrderPickingList = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t, ready, i18n } = useTranslation();

    const { data: orderPickingList, isLoading,isFetching,isError } = useGetOrderPickingListQuery({orders: [props.orderId]},
    {
        skip: !props.orderId
    });
        

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        
        content: <FormatListNumberedIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <HeaderDateBox skeleton t={i18n}/> },
        { component: <ShRowSkeleton heading={t("shipment_days")} /> },
        { component: <ShRowSkeleton heading={t("pickstream")} /> },
        { component: <ShRowSkeleton heading={t("orderlines")} /> },
        { component: <ShRowSkeleton heading={t("reference")} /> },
    ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        options: defaultOptions,
        // actions: <SidePanelActions setIsExtended={setIsExtendedCallback} />,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon, defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.orderId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && orderPickingList) {
            console.log("orderPickingList",orderPickingList);
            setPanel(prevPanel => ({
                // setPanel((prevPanel) => ({
                ...prevPanel,
                title: "Order " + orderPickingList.order.id,
                subTitle: orderPickingList.order.webshopcredentials?.description,
                icon :{
                    theme: getOrderStatusColor(orderPickingList.order,theme), 
                    content: <FormatListNumberedIcon/>
                },
                options : [
                    {
                        component: <HeaderDateBox data={orderPickingList.order} t={i18n}/>
                    },
                    {
                        heading: t("shipment_days"),
                        content:'0'
                    },
                    {
                        heading: t("pickstream"),
                        content: orderPickingList.order.stream?.description
                    },
                    {
                        heading: t("orderlines"),
                        content: orderPickingList.order.orderlines?.length
                    },
                    {
                        heading: t("reference"),
                        content: orderPickingList.order.webshopreference
                    }
                ],
                //   }));
            }));
        }
    }, [orderPickingList, isLoading,isFetching, t,theme.palette.mode]);


   
    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spOrderPickingListConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} >
                <>
                    <PickingLines config={spOrderPickingListConfig.pickingLines} data={orderPickingList} /> 
                </>
        </Sidepanel2>
    );
};

export default SpOrderPickingList;