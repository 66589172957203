import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const TEMPLATE_BASE = "/workflowtemplates";
const buildUrl = (endpoint) => TEMPLATE_BASE + endpoint;

const workflowTemplateSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getWorkflowTemplates: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'WorkflowTemplates' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getWorkflowTemplates', 'WorkflowTemplates')
        }),
        getWorkflowTemplatesSelect: builder.query({
            query: type => ({
                url: buildUrl(`/select${type ? `?type=${type}` : ''}`),
                method: 'GET',
            }),
            providesTags: () => [{ type: 'WorkflowTemplates' }],
        }),
        getWorkflowTemplate: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            // providesTags: (id) => [{ type: `CountingStrategyTemplateSingle`,id }]
            providesTags: (result, error, arg) => [{ type: `WorkflowTemplateSingle`, id: arg }]
        }),
        addWorkflowTemplate: builder.mutation({
            query: workflowTemplateInfo => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: workflowTemplateInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'WorkflowTemplates'}],
        }),
        updateWorkflowTemplate: builder.mutation({
            query: workflowTemplateInfo => ({
                url: buildUrl(`/${workflowTemplateInfo.id}`),
                method: 'PUT',
                body: workflowTemplateInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'WorkflowTemplates' }, { type: `WorkflowTemplateSingle`, id: arg.id }],
        }),
        deleteWorkflowTemplate: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'WorkflowTemplates' }, { type: `WorkflowTemplateSingle`, id: arg.id }],
        }),
    })
});

export const {
    useGetWorkflowTemplatesQuery,
    useGetWorkflowTemplatesSelectQuery,
    useGetWorkflowTemplateQuery,
    useAddWorkflowTemplateMutation,
    useUpdateWorkflowTemplateMutation,
    useDeleteWorkflowTemplateMutation
  } = workflowTemplateSlice;
