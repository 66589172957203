import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";

import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from "react-i18next";
import Details from "./Views/Details";
import StreamShippers from "./Views/StreamShippers";
import ShipmentPanel from "./Views/Shipment";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spStreamDetailsConfig } from "./spStreamDetailsConfig";

import { ReactComponent as StreamIcon } from "../../../../styles/svg/stream.svg";
import { useGetStreamQuery } from "../../../../newapi/global/stream/streamSlice";

const SidePanelActions = (props) => {
  return (
    <SidepanelHeaderActiongroup>
      <Shbutton
        isCancel={true}
        onClick={() => props.setIsExtended(false)}
        color="primary"
        variant="text"
        startIcon={<ArrowRightAltIcon />}
      >
        Cancel
      </Shbutton>
    </SidepanelHeaderActiongroup>
  );
};

const SpStreamDetails = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { data } = props;

  
  const { data: streamData, isLoading,isFetching,isError } = useGetStreamQuery(props.streamId,
  {
      skip: !props.streamId
  });

  const defaultIcon = useMemo(() => ({
      theme: theme.palette.mode === "dark" ? "blue" : "primary",
      content: <StreamIcon/>
  }), [theme.palette.mode]);

  // const defaultOptions = useMemo(() => [
  //     { component: <ShRowSkeleton heading={t("order")} /> },
  //     { component: <ShRowSkeleton heading={t("reference")} /> },
  //     { component: <ShRowSkeleton heading={t("name")} /> },
  //     { component: <ShRowSkeleton heading={t("phone")} /> },
  //     { component: <ShRowSkeleton heading={t("email")} /> },
  //     { component: <ShRowSkeleton heading={t("relation")} /> },
  //     { component: <HeaderDateBox skeleton t={i18n}/> },
  //     { component: <ShRowSkeleton heading={t("warehouse")} /> },
  //     { component: <ShRowSkeleton heading={t("sku_amount")} /> },
  //     { component: <ShRowSkeleton heading={t("items")} /> },
  // ], [t]);
  
  const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

  // Memoize the default state to avoid recalculating it on every render
  const defaultState = useMemo(() => ({
      title: "",
      subTitle: "",
      // options: defaultOptions,
      icon: defaultIcon,
      actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
  }), [defaultIcon,/*defaultOptions,*/ setIsExtendedCallback]);

  const [panel, setPanel] = useState(defaultState);

  useEffect(() => {
      setPanel(defaultState);
  }, [isFetching,props.streamId,theme.palette.mode])
  
  useEffect(() => {
      if (!isLoading && !isFetching && streamData) {
        
          setPanel((prevPanel) => ({
              title: streamData.name,
              subTitle: streamData.reference ?? "",
              icon:defaultIcon,
              options: [
                  // {
                  //     heading: t('zone'),
                  //     content: data.warehouseZone?.description
                  // },
                  // {
                  //     component: <MultiProductBox 
                  //     multiproduct={data.multiproduct} 
                  //     multiproductzone={data.warehouseZone.multiproduct} 
                  //     multibatchzone={data.warehouseZone.multibatch}/>
                  // },
                  // {
                  //     heading: t('location_type'),
                  //     content: data.warehouseLocationType?.description
                  // },
                  // {
                  //     heading: t('reach_type'),
                  //     content: data.reachType?.description
                  // }
              ],
          }));
      }
  }, [streamData, isLoading,isFetching, t,theme.palette.mode]);

  // const [panel, setPanel] = useState({
  //   title: "",
  //   subTitle: "",
  //   icon: {
  //     theme: "green",
  //     content: <Inventory2Icon />,
  //   },
  //   options: [],
  //   actions: <SidePanelActions setIsExtended={props.setIsExtended} />,
  // });

  // useEffect(() => {
  //   if (
  //     typeof data !== "undefined" &&
  //     data !== null &&
  //     typeof data.id !== "undefined"
  //   ) {
  //     setPanel((prevPanel) => ({
  //       ...prevPanel,
  //       title: data.name,
  //       subTitle: data.reference ?? "",
  //       options: [],
  //     }));
  //   }
  // }, [data]);

  return (
    <Sidepanel2
      skeleton={isLoading || isFetching}
      tabConfig={spStreamDetailsConfig}
      content={panel}
      isExtended={props.isExtended}
      setIsExtended={props.setIsExtended}
    >
      <>
        <Details config={spStreamDetailsConfig.details} data={streamData}  setIsExtended={props.setIsExtended}/>
        <ShipmentPanel config={spStreamDetailsConfig.shipmentPanel} data={streamData} />
        <StreamShippers config={spStreamDetailsConfig.streamShippers} streamId={props.streamId}/>
        
        {/* <Pickup data={props.data} apiRef={pickupApiRef}/>
                    <Authorisation data={props.data} apiRef={authorisationApiRef} /> */}
      </>
    </Sidepanel2>
  );
};

export default SpStreamDetails;
