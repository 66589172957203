import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useTranslation } from 'react-i18next';
import Details from "./Views/Details";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spProductValueDetailsConfig } from "./spProductValueDetailsConfig";
import { useGetProductFieldQuery } from "../../../../newapi/product/productFieldSlice";
const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpCarrierDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;

    const { data: productFieldData, error, isLoading,isFetching } = useGetProductFieldQuery(
        props.productFieldId,
         {
             skip: !props.productFieldId
         }
     );


    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <LocalShippingIcon/>
    }), [theme.palette.mode]);

    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        actions: <SidePanelActions setIsExtended={setIsExtendedCallback} />
    }), [defaultIcon, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.productFieldId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && productFieldData) {
            // let locations = carrierData.stocks.map(stock => stock.warehouseLocation.location);
            // let combinedLocations = locations.join(", ");

            setPanel(prevPanel => ({
                ...prevPanel,
                title: productFieldData.name,
                subTitle: `${t(productFieldData.valueType)}`,
                icon: defaultIcon,
            }));
        }
    }, [productFieldData, isLoading, isFetching, t,theme.palette.mode]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spProductValueDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spProductValueDetailsConfig.details} data={productFieldData} setIsExtended={props.setIsExtended}/>
                </>
        </Sidepanel2>
    );
};

export default SpCarrierDetails;