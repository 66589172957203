import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import {  useDeleteRelationMutation, useEditRelationMutation } from "../../../../../newapi/global/relation/relationSlice";
import WidgetsIcon from '@mui/icons-material/Widgets';

import { useTranslation } from "react-i18next";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { showToastMessageDeleteRequest, showToastMessageUpdateRequest} from "../../../../../utils/toasts";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [relationInfo, setRelationInfo] = useState({
      id: 0,
      name: "",
      phone: "",
      email: "",
      website: "",
      street: "",
      house_nr: "",
      house_nr_extra: "",
      postal: "",
      city: ""
    });

    const [open, setOpen] = useState(false);
    
    
    const [EditRelation,{isLoading}] = useEditRelationMutation();
    const handleSaveClick = async () => {
        try {
            const response = await EditRelation(relationInfo).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };

    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions 
                id={relationInfo?.id}
                action={handleSaveClick}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[handleSaveClick,relationInfo]);

    

    useEffect(() => {
        setRelationInfo(props.data);
    },[props.data]);

    const handleChange = (key, value) => {
        setRelationInfo({
          ...relationInfo,
          [key]: value
        });
      };

      const [DeleteRelationMutation] = useDeleteRelationMutation();
      const confirmDelete = async () => {
        try {
            const response = await DeleteRelationMutation({id: props.data.id}).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };
    const handleDeleteClick = async () => {
        setOpen(true);
    };

    const confirmDeleteModal = {
        data: relationInfo?.name,
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    }

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
        <Dropdown title={t("contact")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 4,
                        justifyContent:"flex-start"
                    }}
                >
                    <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                flex:1
                            }
                        }}
                    >
                        <Box lineHeight={"40px"} width={"104px"}>
                            <Text>{t("name")}</Text>
                        </Box>
                        <Box lineHeight={"40px"} width={"104px"}>
                            <Text>{t("phone")}</Text>
                        </Box>
                        <Box lineHeight={"40px"} width={"104px"}>
                            <Text>{t("email")}</Text>
                        </Box>
                        <Box lineHeight={"40px"} width={"104px"}>
                            <Text>{t("website")}</Text>
                        </Box>
                    </Box>
                    <Box flex={1}>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:0.5,
                                marginBottom:0.5,
                                justifyContent:"space-between",
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <InputBase sx={{ml: 2, flex:1}} placeholder="" value={relationInfo?.name || ""} onChange={(e) => handleChange('name', e.target.value)}/>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:0.5,
                                marginBottom:0.5,
                                justifyContent:"space-between",
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <InputBase sx={{ml: 2, flex:1}} placeholder="" value={relationInfo?.phone || ""} onChange={(e) => handleChange('phone', e.target.value)}/>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:0.5,
                                marginBottom:0.5,
                                justifyContent:"space-between",
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <InputBase sx={{ml: 2, flex:1}} placeholder="" value={relationInfo?.email || ""} onChange={(e) => handleChange('email', e.target.value)}/>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:0.5,
                                marginBottom:0.5,
                                justifyContent:"space-between",
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <InputBase sx={{ml: 2, flex:1}} placeholder="" value={relationInfo?.website || ""} onChange={(e) => handleChange('website', e.target.value)}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
            
        </Dropdown>
        <Dropdown title={t("address_details")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 4,
                        justifyContent:"flex-start"
                    }}
                >
                    <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                flex:1
                            }
                        }}
                    >
                        <Box lineHeight={"40px"} width={"104px"}>
                            <Text>{t("street")}</Text>
                        </Box>
                        <Box lineHeight={"40px"} width={"104px"}>
                            <Text>{t("house_nr")}</Text>
                        </Box>
                        <Box lineHeight={"40px"} width={"104px"}>
                            <Text>{t("house_nr_extra")}</Text>
                        </Box>
                        <Box lineHeight={"40px"} width={"104px"}>
                            <Text>{t("postal")}</Text>
                        </Box>
                        <Box lineHeight={"40px"} width={"104px"}>
                            <Text>{t("city")}</Text>
                        </Box>
                    </Box>
                    <Box flex={1}>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:0.5,
                                marginBottom:0.5,
                                justifyContent:"space-between",
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <InputBase sx={{ml: 2, flex:1}} placeholder="" value={relationInfo?.street || ""} onChange={(e) => handleChange('street', e.target.value)}/>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:0.5,
                                marginBottom:0.5,
                                justifyContent:"space-between",
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <InputBase sx={{ml: 2, flex:1}} placeholder="" value={relationInfo?.house_nr || ""} onChange={(e) => handleChange('house_nr', e.target.value)}/>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:0.5,
                                marginBottom:0.5,
                                justifyContent:"space-between",
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <InputBase sx={{ml: 2, flex:1}} placeholder="" value={relationInfo?.house_nr_extra || ""} onChange={(e) => handleChange('house_nr_extra', e.target.value)}/>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:0.5,
                                marginBottom:0.5,
                                justifyContent:"space-between",
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <InputBase sx={{ml: 2, flex:1}} placeholder="" value={relationInfo?.postal || ""} onChange={(e) => handleChange('postal', e.target.value)}/>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:0.5,
                                marginBottom:0.5,
                                justifyContent:"space-between",
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <InputBase sx={{ml: 2, flex:1}} placeholder="" value={relationInfo?.city || ""} onChange={(e) => handleChange('city', e.target.value)}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
            
        </Dropdown>
            
        <Box
            sx={{
                position:"absolute",
                bottom:0,
                right:0,
                padding:3,
                width:1,
                background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                    padding:2,
                    paddingY:2.5,
                }
            }}
        >
        <GridActions
            id={relationInfo?.id}
            action={handleSaveClick}
            deleteAction={handleDeleteClick}
        />
            </Box>
            <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
        </Box>
    );
}

export default DetailsPanel;