import { Box, InputBase, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useEditRelationMutation } from "../../../../../newapi/global/relation/relationSlice";
import WidgetsIcon from '@mui/icons-material/Widgets';
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { showToastMessageRequestError, showToastMessageUpdateRequest} from "../../../../../utils/toasts";
import { useGetWarehousesSelectQuery, useUpdateWarehouseMutation } from "../../../../../newapi/warehouse/warehouseApiSlice";
import { useUpdatePrinterMutation } from "../../../../../newapi/warehouse/printerSlice";
import { yupRequired } from "../../../../../utils/validation";
// import { cx } from "classnames";
import NumberInput from "../../../../theme/inputs/NumberInput";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import MSelectPrinter from "../../../Modals/MSelectPrinter";
import cx from 'classnames';

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState(0);
    const [selectedPrinter, setSelectedPrinter] = useState(null);

    
    const { data: warehouses } = useGetWarehousesSelectQuery();

    const [printerInfo, setPrinterInfo] = useState({
        state: "active",
        description: "",
        internal_name: "",
        printnode_id: 0,
        zpl_support: false,
        format: "A6",
        custom_width: 0,
        custom_height: 0,
        warehouseId: 0
    });
    const handleSaveClick = async () => {
        try {
            if(!printerInfo?.printnode_id || !printerInfo?.internal_name){
                showToastMessageRequestError(t("no_printer_selected"), t, { success: false, error: t("no_printer_selected") });
                return;
            }
          // Reset field errors
          setFieldErrors({});
      
          await schema.validate(printerInfo, { abortEarly: false });
            const response = await UpdatePrinter({id: props.data?.id, data: printerInfo}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageUpdateRequest(t, {success: false, error: err.error});
              }
            }
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions 
                id={props.warehouseInfo?.id}
                action={handleSaveClick}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[handleSaveClick,props.warehouseInfo]);

    

    useEffect(() => {
        setPrinterInfo(props.data);
    },[props.data]);

    const [fieldErrors, setFieldErrors] = useState({});

const rules = {
    description: yupRequired('description', t),
    internal_name: yupRequired('internal_name', t),
    printnode_id: yup.number().nullable().required(t('required')),
    zpl_support: yup.boolean().required(t('required')),
    format: yup.string().required(t('required')),
    custom_width: yup.number().nullable().when('format', {
        is: 'custom',
        then: yup.number().required(t('required'))
    }),
    custom_height: yup.number().nullable().when('format', {
        is: 'custom',
        then: yup.number().required(t('required'))
    }),
    warehouseId: yup.number().nullable().required(t('wrequired')),
};
    
    const schema = yup.object().shape(rules);

    const formatOptions = [
        {key: "A6", name: "A6"},
        {key: "A4", name: "A4"},
        {key: "custom", name: t("custom")}
    ];

    const handleFormatChange = (e) => {
        handleChange("format", formatOptions[e].key);
    }

    const handleWarehouseChange = (e) => {
        handleChange("warehouseId", warehouses.data[e].id);
    }

    const handleChange = (key, value) => {
        setPrinterInfo(prevState => ({
          ...prevState,
          [key]: value
        }));
      };
    
    const [UpdatePrinter,{isLoading}] = useUpdatePrinterMutation();
 
    
    const OpenSelectPrinterModal = () => {
        setModalData({
            title: "Selecteer een printer",
            confirm: confirmModalPrinter
        });
        setOpen(true);
    }

    const confirmModalPrinter = (obj) => {
        setPrinterInfo(prevState => ({
            ...prevState,
            printnode_id: obj.id,
            internal_name: obj.name
          }));
        // handleChange("printnode_id", obj.id.toString());
        // handleChange("internal_name", obj.name );
        //setSelectedPrinter(obj);
    }

    const selectPrinterModal = {
        open: open,
        setOpen: setOpen,
        data:modalData,
        confirm: confirmModalPrinter
    }

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("general")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    gap:2,
                    flexDirection:"column",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        //alignItems:"flex-start",
                        //justifyContent:"space-between",
                        //flexDirection:"column",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("printer")}</Text>
                    </Box>
                    <Box
                        sx={{
                            flex:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                            gap:2,
                            pl:2,
                            pr:0.5,
                            pt:0.5,
                            pb:0.5,
                            borderRadius:8,
                        }}
                    >
                        <Text>{printerInfo?.internal_name ?? t("select_printer")}</Text>
                        <ButtonGroup version={2}>
                            <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "primaryGrey" : "primaryGrey"}
                            block
                            variant="contained" 
                            onClick={() => OpenSelectPrinterModal()}
                            >{t("select")}</Shbutton>
                        </ButtonGroup>
                    </Box>

                </Box>

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("name")}</Text>
                    </Box>
                    <ShValidatedInput
                        name="description"
                        value={printerInfo?.description || ""}
                        onChange={handleChange}
                        error={fieldErrors.description}
                    />
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("format")}</Text>
                    </Box>
                    <InputDropdown
                displayName={"name"} 
                onChange={(selected) => handleFormatChange(selected)}
                noSelection={t("choose_format")} //Custom text when nothing is selected
                options={formatOptions}
                selected={formatOptions.findIndex(option => option.key === printerInfo?.format)}
                closeOnSelection
            />
                </Box>
                {printerInfo?.format === "custom" && (
                    <>
                        <Box 
                            sx={{
                                width:1,
                                display:"flex",
                                alignItems:"flex-start",
                                justifyContent:"space-between",
                                gap:7
                            }}
                        >
                            <Box
                                sx={{
                                    width:120,
                                    flexShrink:0,
                                    height:32,
                                    display:"flex",
                                    alignItems:"center",
                                }}
                            >
                                <Text light>{t("custom_height")}</Text>
                            </Box>
                            <NumberInput btnSize={"24"}
                                change={(e) => handleChange('custom_height', e)}/>
                        </Box>
                        <Box 
                            sx={{
                                width:1,
                                display:"flex",
                                alignItems:"flex-start",
                                justifyContent:"space-between",
                                gap:7
                            }}
                        >
                            <Box
                                sx={{
                                    width:120,
                                    flexShrink:0,
                                    height:32,
                                    display:"flex",
                                    alignItems:"center",
                                }}
                            >
                                <Text light>{t("custom_width")}</Text>
                            </Box>
                            <NumberInput btnSize={"24"}
                                change={(e) => handleChange('custom_width', e)}/>
                        </Box>
                    </>
                )}
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        //justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("zpl_support")}</Text>
                    </Box>
                    <ToggleButtonGroup
                        color={printerInfo?.zpl_support === "true" || printerInfo?.zpl_support === true ? "green" : "red"}
                        value={printerInfo?.zpl_support === "true" || printerInfo?.zpl_support === true ? "true" : "false"}
                        exclusive
                        onChange={(e) => handleChange('zpl_support', e.target.value )}
                        aria-label="Active"

                        className={cx(`${printerInfo?.zpl_support}`, {
                            'active': printerInfo?.zpl_support === "true" || printerInfo?.zpl_support === true
                        })}
                        sx={{
                            background: colors.red[100],
                            padding:0.5,
                            borderRadius:5,
                            transition:"250ms",
                            "&.active" : {
                                background:colors.green[100],
                                transition:"250ms",
                            },  
                            "&.active" : {
                                background:colors.green[100],
                                transition:"250ms",
                            },
                        }}
                    >
                        <ToggleButton 
                            sx={{
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.green[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.red[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="false">
                            {t('no')}
                        </ToggleButton>
                        <ToggleButton 
                            sx={{
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.red[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.green[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="true"
                        >
                            {t('yes')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("warehouse")}</Text>
                    </Box>
                    <InputDropdown
                        displayName={"name"} 
                        onChange={(selected) => handleWarehouseChange(selected)}
                        noSelection={t("choose_warehouse")} //Custom text when nothing is selected
                        options={warehouses?.data}
                        selected={warehouses?.data?.findIndex(option => option.id === printerInfo?.warehouseId)}
                        
                        closeOnSelection
                    />
                </Box>
                    
                </Box>
                
            </Dropdown>

            
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                <GridActions
                    id={props.id?.id}
                    action={handleSaveClick}
                />
                <MSelectPrinter
                    open={selectPrinterModal.open}
                    handleClose={() => selectPrinterModal.setOpen(false)}
                    data={selectPrinterModal.data}
                    confirm={selectPrinterModal.confirm}
                />
            </Box>
        </Box>
    );
}

export default DetailsPanel;