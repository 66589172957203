import { Box, Stack} from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import { tokens } from "../../../../../theme";
import Text from "../../../../theme/text/Text";

import { useEffect, useState, useRef } from "react";
import {useTranslation} from 'react-i18next';
import { useGetReplenishLinesQuery } from "../../../../../newapi/order/ordersSlice";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import SPStockDetails from "../../SpStockDetails/SPStockDetails";
import SpLocationDetails from "../../SpLocationDetails/SpLocationDetails";
import ShDoubleTextSkeleton from "../../../../theme/skeleton/ShDoubleTextSkeleton";
function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 



const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "replenishFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Replenish = (props) => {
    const{t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const [isExtended, setIsExtended] = useState(false);
    const [locationIsExtended, setLocationIsExtended] = useState(false);
    
    const [locationId, setLocationId] = useState();
    const locationIdRef = useRef(locationId);
    
    const getLocationDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.stock?.warehouseLocation?.id);
        // Only update state if the productId has actually changed
        if (locationIdRef.current !== newProductId) {
            setLocationId(newProductId);
            locationIdRef.current = newProductId; // Update the ref to the new value
        }
        setLocationIsExtended(true);
    };

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);

    const getProductDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.product?.id);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {
            field: "location", 
            headerName: t("location"),
            flex:1,
            renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Box onClick={(event) => {
                    event.stopPropagation(); // Prevent click from propagating to the row
                    getLocationDetails(params);
                }}>
                    <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.stock?.warehouseLocation?.location}</Text>
                </Box>
                )
        }},
        {
            field: "sku", 
            headerName: t("sku"),
            flex:1,
            renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShDoubleTextSkeleton/>
            }
            return (
                <Box paddingLeft={0} onClick={(event) => {
                    event.stopPropagation();
                    getProductDetails(params);
                    }}>
                    <Text bold clickable={true} variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product?.sku}</Text><br/>
                    <Text clickable={true} variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product?.description}</Text>
                </Box>
            )
        }},
        {field: "amount", headerName: t("quantity"), renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box paddingLeft={0}>
                    <Text semibold>{params.row.amount}</Text>
                </Box>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                return (
                    <Box
                        sx={{
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            paddingX:2,
                            paddingY:1.5,
                            borderRadius:2
                        }}
                    >
                        {cellValues.row.amount + " x " + cellValues.row.product?.sku}
                        <br/>
                        {cellValues.row.product?.description}
                        <br/>
                        {cellValues.row.product?.barcode}
                    </Box>
                )
            }
        }
    ];


    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetReplenishLinesQuery}
                itemId={props.data.id}
                title={t('replenishlines')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                rows={data}>
            </ShDataGrid2>
            <SPStockDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                productId={productId}
                // data={productData} // Pass the productData as a prop
            ></SPStockDetails>
            <SpLocationDetails
                isExtended={locationIsExtended}
                setIsExtended={setLocationIsExtended}
                locationId={locationId}
                // data={locationData} // Pass the productData as a prop
            ></SpLocationDetails>
        </Box>
    );
}



export default Replenish;