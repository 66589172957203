import React, { useState, useEffect } from "react";
import { Box, useTheme, Drawer } from '@mui/material';
import { tokens } from "../../../theme";

import 'react-pro-sidebar/dist/css/styles.css';
import CloseOverlayBtn from "../buttons/CloseOverlayBtn";
import SidepanelBody from "./SidepanelBody";
import SidepanelHeader from "./SidepanelHeader";

const determineAnchorPosition = (windowWidth) => {
    if(windowWidth > 1023) {
        return "right";
    }
    return "bottom";
}

const Sidepanel2 = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [anchor, setAnchor] = useState(determineAnchorPosition(window.innerWidth));
    
    const [subpanelActive, setSubpanelActive] = useState(false);

    const [header, setHeader] = useState(null)

    const handleResize = () => {
      if(window.innerWidth <= 767 && props.isExtended) {
        // console.log("ho");
        setSubpanelActive(true);
      }
      setAnchor(determineAnchorPosition(window.innerWidth));
    }

    // Executed on mount
    useEffect(() => {
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      }
    },[]);
  
    //Executed when value of one of the array vars change
    useEffect(() => {

        if(subpanelActive && window.innerWidth <=1366){
            document.querySelector('meta[name="theme-color"]').setAttribute("content", `${colors.bg["tertiary"]}`);
            document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${colors.bg["tertiary"]}`);
        } else {
            if(props.isExtended){
                document.querySelector("body").style.overflow = "hidden";
                document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#0F0F12": "#606D85"}`);
                document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#0F0F12": "#606D85"}`);
            } else {
                document.querySelector("body").style.overflow = "auto";
                document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#181820": "#ecf0f5"}`);
                document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#181820": "#ecf0f5"}`);
            }
        }
        // alert("also called");
    },[props.isExtended,theme.palette.mode, subpanelActive])

    return (
        <Drawer
            anchor={anchor}
            open={props.isExtended}
            onClose={() => props.setIsExtended(false)}
            sx={{
                
                "& .MuiPaper-elevation": { 
                    background:"none",
                    boxShadow:"none",
                    overflow:"visible"
                },
                "& .MuiModal-backdrop" : {
                    backdropFilter:"blur(4px)",
                    background:`${theme.palette.mode === "dark" ? "rgba(14,14,17,.85)" : "rgba(46,65,95,.75)"}`
                },
                "@media screen and (max-width: 63.9375em)" : {
                    "& .MuiPaper-elevation": { 
                        width:"100% !important",
                        position:"absolute",
                        bottom:0
                    }
                }
            }}
        >
            <Box
                className={`${theme.palette.mode}`}
                sx={{
                    height:1,
                }}
            >
                <Box 
                    sx={{
                        height:1,
                    }}
                >
                    {props.isExtended ? 
                        <Box onClick={() => props.setIsExtended((extended) => !extended)}> 
                            <CloseOverlayBtn theme="sidepanel"></CloseOverlayBtn> 
                        </Box>
                    : null}
                            
                    <Box
                        // className="sp-wrapper-content"
                        sx={{
                            width:"936px",
                            borderTopLeftRadius:24,
                            borderBottomLeftRadius:24,
                            display: "flex",
                            flexDirection: "column",
                            overflow:"hidden",
                            height:1,
                            "@media screen and (max-width: 63.9375em)" : {
                                borderTopRightRadius:24,
                                borderBottomLeftRadius:!props.hideFooter ? 24 : 0,
                                width:1,
                            }
                        }}
                    >
                        {/* {React.Children.count(props.children)} */}
                        <SidepanelHeader skeleton={props.skeleton} setHeader={setHeader} {...props}>
                            {React.Children.count(props.children) > 1 ?
                                props.children[1] : null
                            }
                        </SidepanelHeader>
                        <SidepanelBody {...props} header={header} title={props.content.title}>
                            {React.Children.count(props.children) > 1 ? 
                                props.children[0]
                            : 
                                props.children
                            }
                        </SidepanelBody>
                    </Box>
                </Box>
            </Box>
        </Drawer>
    );
};

export default Sidepanel2;