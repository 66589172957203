import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";
import AddIcon from '@mui/icons-material/Add';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";

import { useTranslation } from "react-i18next";
import { useGetWorkflowLogsQuery, useCreateWorkflowLogMutation } from "../../../../../newapi/workflow/workflowSlice";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import MAddWorkflowLog from "../../../Modals/MAddWorkflowLog";

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('add_log')}
            </Shbutton>
        </ButtonGroup>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Warehouse = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };
      
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions />,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const columns = [
        {
            field: "createdAt",
            headerName: t("created_on"),
            flex:1,
            shFilter : {
                type: "date",
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                let created = new Date(params.row.createdAt);
                return `${created.toLocaleDateString()} ${created.toLocaleTimeString()}`;
            }
        },
        {
            field: "userName", 
            headerName: t('user_name'), 
            width:216,
            shFilter : {
                type: "text",
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                
                return params.row.userName;
              }
        },
        {
            field: "message",
            headerName: t('message'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.message;
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
              //  if(params.row.skeleton) {
                    return <ShTextSkeleton/>
               // }
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetWorkflowLogsQuery}
                
                itemId={props.data.id}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}>
            </ShDataGrid2>
            <MAddWorkflowLog workflowId={props.data.id} onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}
export default Warehouse;