import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import ChildProductOverview from "./Views/ChildProductOverview";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from 'react-i18next';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import {spVirtualProductDetailsConfig} from "./spVirtualProductDetailsConfig"
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import { useGetProductQuery } from "../../../../newapi/warehouse/productSlice";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpVirtualProductDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;


    const { data: virtualProductData, isLoading,isFetching,isError } = useGetProductQuery(props.virtualProductId,
    {
        skip: !props.virtualProductId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <Inventory2Icon/>
    }), [theme.palette.mode]);

    // const defaultOptions = useMemo(() => [
    //     { component: <ShRowSkeleton heading={t("phone")} /> },
    //     { component: <ShRowSkeleton heading={t("label_workspaces")} /> },
    //     { component: <ShRowSkeleton heading={t("packing_list_workspaces")} /> },
    // ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        // options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon, /*defaultOptions,*/ setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.virtualProductId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && virtualProductData) {
            setPanel(prevPanel => ({
                ...prevPanel,
                title: virtualProductData.description,
                subTitle: virtualProductData.sku,
                actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />,
                icon :defaultIcon,
                options: [
                    // {
                    //     heading: t('products'),
                    //     content: data.childProducts ? data.childProducts.length : 0
                    // },
                ],
            }));
        }
    }, [virtualProductData, isLoading, t,theme.palette.mode]);

    // const [panel, setPanel] = useState({
    //     title: "",
    //     subTitle: "",
    //     icon :{
    //         theme: theme.palette.mode === "dark" ? "blue" : "primary",
    //         content: <Inventory2Icon/>
    //     },
    //     //Heading row for default shit
    //     options : [],
    //     actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    // });

    // useEffect(() => {
    //     if (typeof data !== "undefined" && typeof data.id !== "undefined") {
    //       setPanel((prevPanel) => ({
    //         ...prevPanel,
    //         title: data.description,
    //         subTitle: [data.sku],
    //         icon :{
    //             theme: theme.palette.mode === "dark" ? "blue" : "primary",
    //             content: <Inventory2Icon/>
    //         },
    //         options: [
    //             // {
    //             //     heading: t('products'),
    //             //     content: data.childProducts ? data.childProducts.length : 0
    //             // },
    //         ],
    //       }));
    //     }
    //   }, [data,theme.palette.mode]);


    return (
        // spVirt
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spVirtualProductDetailsConfig}
        // tabConfig={spVirtualProductDetailsConf}
            // tabConfig={spVirt}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <ChildProductOverview config={spVirtualProductDetailsConfig.childProductOverview} actionButtons={props.actionButtons} productId={props.virtualProductId}/>
                </>
        </Sidepanel2>
    );
};

export default SpVirtualProductDetails;