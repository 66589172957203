import { Box, InputBase, List, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useEffect, useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import NumberInput from "../../theme/inputs/NumberInput";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify"; 
import cx from "classnames"
import {checkAllPropertiesTruthy } from "../../../utils"
import { useCreatePrinterMutation } from "../../../newapi/warehouse/printerSlice";
import { showToastMessage, showToastMessagePostRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../utils/validation";
import { InputDropdown } from "../../theme/dropdowns/InputDropdown";
import MSelectPrinter from "./MSelectPrinter";
import { useGetWarehousesSelectQuery } from "../../../newapi/warehouse/warehouseApiSlice";
// import { required } from '../../utils/validation';



const MAddPrinter = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);
    const [addPrinter,{isLoading}] = useCreatePrinterMutation();
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState(0);
    const [selectedPrinter, setSelectedPrinter] = useState(null);
    const select = (selected) => {
        setSelected(selected);
    }

    const { data: warehouses } = useGetWarehousesSelectQuery();


    const [printerInfo, setPrinterInfo] = useState({
        state: "active",
        description: "",
        internal_name: "",
        printnode_id: 0,
        zpl_support: false,
        format: "A6",
        custom_width: 0,
        custom_height: 0,
        warehouseId: 0
    });

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        description: yupRequired('description', t),
        internal_name: yupRequired('internal_name', t),
        printnode_id: yup.number().nullable().required(t('required')),
        zpl_support: yup.boolean().required(t('required')),
        format: yup.string().required(t('required')),
        custom_width: yup.number().nullable().when('format', {
            is: 'custom',
            then: yup.number().required(t('required'))
        }),
        custom_height: yup.number().nullable().when('format', {
            is: 'custom',
            then: yup.number().required(t('required'))
        }),
        warehouseId: yup.number().nullable().required(t('wrequired')),
    };
    
    const schema = yup.object().shape(rules);

    const formatOptions = [
        {key: "A6", name: "A6"},
        {key: "A4", name: "A4"},
        {key: "custom", name: t("custom")}
    ];

    const handleFormatChange = (e) => {
        handleChange("format", formatOptions[e].key);
    }

    const handleWarehouseChange = (e) => {
        handleChange("warehouseId", warehouses.data[e].id);
    }
   
    const SavePrinter = async () => {
        try {
            if(!printerInfo?.printnode_id || !printerInfo?.internal_name){
                showToastMessage("error", t("no_printer_selected"));
                return;
            }
          // Reset field errors
          setFieldErrors({});
      
          await schema.validate(printerInfo, { abortEarly: false });
      
          // If validation is successful, proceed with the addSupplier call
          const data = await addPrinter(printerInfo).unwrap();
          showToastMessagePostRequest(t, data);
        } catch (err) {
          if (err.name === 'ValidationError') {
            // Create an errors object where keys are field names and values are error messages
            const errors = err.inner.reduce((acc, currentError) => ({
              ...acc,
              [currentError.path]: currentError.message
            }), {});
      
            // Set the validation errors in the state
            setFieldErrors(errors);
          } else {
            // Handle other errors, such as network errors
            showToastMessageRequestError(t("create_failed"), t, { success: false, error: err.error });
          }
        }
    };

    const OpenSelectPrinterModal = () => {
        setModalData({
            title: "Selecteer een printer",
            confirm: confirmModalPrinter
        });
        setOpen(true);
    }

    const confirmModalPrinter = (obj) => {
        setPrinterInfo(prevState => ({
            ...prevState,
            printnode_id: obj.id,
            internal_name: obj.name
          }));
        // handleChange("printnode_id", obj.id.toString());
        // handleChange("internal_name", obj.name );
        setSelectedPrinter(obj);
    }

    const selectPrinterModal = {
        open: open,
        setOpen: setOpen,
        data:modalData,
        confirm: confirmModalPrinter
    }

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    const handleChange = (key, value) => {
        setPrinterInfo(prevState => ({
          ...prevState,
          [key]: value
        }));
        // Optionally clear the error for that field
        setFieldErrors(prevState => ({
          ...prevState,
          [key]: ''
        }));
      };
      

    return(
        <>
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                }}>
                <Subheading>{t("add_printer")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    pb:2,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:48,
                            display:"flex",
                            alignItems:"center",
                            "@media screen and (max-width: 47.9375em)" : {
                                width:78,
                            }
                        }}
                    >
                        <Text light>{t("printer")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            display:"flex",
                            alignItems:"center",
                            borderRadius:11,
                            pr:0.5,
                            py:0.5,
                            pl:2,
                            gap:2,
                            justifyContent:"space-between",
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.primary[100]}`,
                            width:1,
                            minWidth: 0 
                        }}
                    >
                        <Text noBreak>{selectedPrinter?.name ?? t("select_printer")}</Text>

                        <Shbutton color={theme.palette.mode === "dark" ? "blue" : "primary"}
                            variant="contained" 
                            onClick={() => OpenSelectPrinterModal()}
                        >
                            {t("select")}
                        </Shbutton>
                    </Box>

                </Box>

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                            "@media screen and (max-width: 47.9375em)" : {
                                width:78,
                            }
                        }}
                    >
                        <Text light>{t("name")}</Text>
                    </Box>
                    <ShValidatedInput
                        name="description"
                        value={printerInfo.description}
                        onChange={handleChange}
                        error={fieldErrors.description}
                    />
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                            "@media screen and (max-width: 47.9375em)" : {
                                width:78,
                            }
                        }}
                    >
                        <Text light>{t("format")}</Text>
                    </Box>
                    <InputDropdown
                displayName={"name"} 
                onChange={(selected) => handleFormatChange(selected)}
                noSelection={t("choose_format")} //Custom text when nothing is selected
                options={formatOptions}
                
                closeOnSelection
            />
                </Box>
                {printerInfo.format === "custom" && (
                    <>
                        <Box 
                            sx={{
                                width:1,
                                display:"flex",
                                alignItems:"flex-start",
                                justifyContent:"space-between",
                                gap:7
                            }}
                        >
                            <Box
                                sx={{
                                    width:120,
                                    flexShrink:0,
                                    height:32,
                                    display:"flex",
                                    alignItems:"center",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:78,
                                    }
                                }}
                            >
                                <Text light>{t("custom_height")}</Text>
                            </Box>
                            <NumberInput btnSize={"24"}
                                change={(e) => handleChange('custom_height', e)}/>
                        </Box>
                        <Box 
                            sx={{
                                width:1,
                                display:"flex",
                                alignItems:"flex-start",
                                justifyContent:"space-between",
                                gap:7
                            }}
                        >
                            <Box
                                sx={{
                                    width:120,
                                    flexShrink:0,
                                    height:32,
                                    display:"flex",
                                    alignItems:"center",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:78,
                                    }
                                }}
                            >
                                <Text light>{t("custom_width")}</Text>
                            </Box>
                            <NumberInput btnSize={"24"}
                                change={(e) => handleChange('custom_width', e)}/>
                        </Box>
                    </>
                )}
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                            "@media screen and (max-width: 47.9375em)" : {
                                width:78,
                            }
                        }}
                    >
                        <Text light>{t("zpl_support")}</Text>
                    </Box>
                    <ToggleButtonGroup
                            color={printerInfo.zpl_support === "true" ? "green" : "red"}
                            value={printerInfo.zpl_support === "true" ? "true" : "false"}
                            exclusive
                            onChange={(e) => handleChange('zpl_support', e.target.value)}
                            aria-label="Active"
                            className={cx(`${printerInfo.zpl_support}`, {
                                'active': printerInfo.zpl_support === "true"
                            })}
                            sx={{
                                background: colors.red[100],
                                padding:0.5,
                                borderRadius:5,
                                transition:"250ms",
                                "&.active" : {
                                    background:colors.green[100],
                                    transition:"250ms",
                                },
                            }}
                        >
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.green[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.red[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="false">
                                {t('no')}
                            </ToggleButton>
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.red[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.green[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="true"
                            >
                                {t('yes')}
                            </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                            "@media screen and (max-width: 47.9375em)" : {
                                width:78,
                            }
                        }}
                    >
                        <Text light>{t("warehouse")}</Text>
                    </Box>
                    <InputDropdown
                displayName={"name"} 
                onChange={(selected) => handleWarehouseChange(selected)}
                noSelection={t("choose_warehouse")} //Custom text when nothing is selected
                options={warehouses?.data}
                
                closeOnSelection
            />
                </Box>

                </Box>

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"green"}
                    block
                    variant="contained" 
                    onClick={() => SavePrinter()}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
        <MSelectPrinter
            open={selectPrinterModal.open}
            handleClose={() => selectPrinterModal.setOpen(false)}
            data={selectPrinterModal.data}
            confirm={selectPrinterModal.confirm}
        />
    </>
    )
}

export default MAddPrinter;

