import React, { useEffect } from 'react';
import { Box, useTheme } from "@mui/material";
import { tokens } from '../../../theme';
import NumberInput from "./NumberInput";
import cx from 'classnames';
import Text from '../text/Text';

const ShValidatedNumberInput = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box 
            sx={{width:1}}
        >
            <Box
                className={cx(props.className, {
                    'error': typeof props.error !== "undefined" && props.error.length > 0,
                })}
                sx={{
                    flex:1,
                    backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                    borderRadius:5,
                    display:"flex",
                    // "&.error" : {
                    //     background: colors.red[100],
                    //     border: `1px solid ${colors.red[400]}`
                    // }
                }}
            >
                <NumberInput btnSize={props.btnSize}
                value={props.value}
                change={(e) => props.onChange(props.name, e)}
                error={Boolean(props.error)}
                />
            </Box>
            {typeof props.error !== "undefined" && props.error.length > 0 &&
                <Box
                    sx={{
                        pl:2,
                    }}
                >
                    <Text bold variant="red">{props.error}</Text>    
                </Box>
            }
        </Box>
    );
};

export default ShValidatedNumberInput;