import { rootSlice } from "../createRootSlice";
import { muiDataGridStarted } from "../apiutils";
const USERGROUP_BASE = "usergroups";

export const usergroupSlice =  rootSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUsergroups: builder.query({
            query: ({ args, invalidate }) => ({
                url: `/${USERGROUP_BASE}?page=${args.page}&size=${args.size}`,
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'Usergroups', identifier: 'datatable' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getUsers', 'Usergroups')
        }),
        getUsergroup: builder.query({ 
            query: (id) => ({ 
                url: `/${USERGROUP_BASE}/${id}`, 
                method: 'GET', 
            }),
            providesTags: (id) => [{ type: `SingleUsergroup-${id}`}],
        }),
        createUsergroup: builder.mutation({ 
            query: (user) => ({ 
                url: `/${USERGROUP_BASE}/create`, 
                method: 'POST', 
                body: user
            }),
            invalidatesTags: (user) => [
                { type: 'Usergroups', identifier: 'datatable' },
                { type: 'Usergroups', identifier: 'select' },
                { type: `SingleUsergroup-${user.id}` }
            ],
        }),
        editUsergroup: builder.mutation({ 
            query: (user) => ({ 
                url: `/${USERGROUP_BASE}/${user.id}`, 
                method: 'PUT', 
                body: user
            }),
            invalidatesTags: (user) => [
                { type: 'Usergroups', identifier: 'datatable' },
                { type: 'Usergroups', identifier: 'select' },
                { type: `SingleUsergroup-${user.id}` }
            ],
        }),
        getUsergroupsSelect: builder.query({
            query: () => ({
                url: `/${USERGROUP_BASE}`,
                method: 'GET',
            }),
            providesTags: () => [{ type: 'Usergroups', identifier: 'select' }],
        }),
        deleteUsergroup: builder.mutation({ 
            query: (id) => ({ 
                url: `/${USERGROUP_BASE}/${id}`, 
                method: 'DELETE', 
            }),
            providesTags: (id) => [{ type: `SingleUsergroup-${id}`}],
        }),
    }),
    
});

export const { useGetUsergroupsQuery, useGetUsergroupQuery, useCreateUsergroupMutation, useEditUsergroupMutation, useGetUsergroupsSelectQuery, useDeleteUsergroupMutation} = rootSlice;
