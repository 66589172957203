
import { muiDataGridStarted } from "../../apiutils";
import { apiSlice } from "../../createApiSlice";

const RELATION_BASE = "/relations";
const buildUrl = (endpoint) => RELATION_BASE + endpoint;
const ApiName = "Relations";
const ApiContactName = "RelationContacts";


const relationsApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRelations: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}` , identifier: 'datatable'}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getRelations', `${ApiName}`)
        }),
        getRelationContacts: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/contacts`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: ApiContactName }]
        }),
        getRelation: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
        addNewRelation: builder.mutation({
            query: relationInfo => ({
                url: buildUrl(`/add`),
                method: 'POST',
                body: relationInfo,
            }),
            invalidatesTags: [`${ApiName}`],
        }),
        editRelation: builder.mutation({
            query: relationInfo => ({
                url: buildUrl(`/${relationInfo.id}`),
                method: 'PUT',
                body: relationInfo,
            }),
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg.id }, {type: `${ApiName}`}],
        }),
        deleteRelation: builder.mutation({
            query: args => ({
                url: buildUrl(`/${args.id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiContactName}],
        }),
        addRelationContact: builder.mutation({
            query: relationContactInfo => ({
                url: buildUrl(`/${relationContactInfo.relationId}/contacts`),
                method: 'POST',
                body: relationContactInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiContactName}],
        }),
        deleteRelationContact: builder.mutation({
            query: args => ({
                url: buildUrl(`/${args.relationId}/contacts/${args.id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiContactName}],
        }),
    })
});

export const {
    useGetRelationsQuery,
    useGetRelationQuery,
    useGetRelationContactsQuery,
    useAddNewRelationMutation,
    useEditRelationMutation,
    useAddRelationContactMutation,
    useDeleteRelationContactMutation,
    useDeleteRelationMutation
  } = relationsApiSlice;