// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useGetReachTypesSelectQuery } from '../../../../../newapi/warehouse/reachTypeSlice';
import NumberInput from "../../../../theme/inputs/NumberInput";
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInputDropdown from '../../../../theme/dropdowns/ShValidatedInputDropdown';
import { yupRequired, yupNumberRequired } from '../../../../../utils/validation';
import { useDispatch } from 'react-redux';
import { useGetPickingprocesstypesQuery } from '../../../../../newapi/global/stream/streamSlice';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const colors = tokens(theme.palette.mode);
    const { data: reachTypes, isLoading } = useGetReachTypesSelectQuery();
    const { data: pickingprocesstypes } = useGetPickingprocesstypesQuery();

    const data = {...props.data};
    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    return (<>
        <Box 
                display={"flex"} 
                flexDirection={"column"}
                sx={{
                    //maxWidth: 160,
                    width:1,
                    paddingTop:3,
                    "@media screen and (max-width:47.9375em)" : {
                        width:1,
                        flexDirection:"row",
                        gap:4,
                        alignItems:"center",
                        justifyContent:"space-between",
                    }
                }}
                >
                    <Box
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            mb:0.5,
                            "@media screen and (max-width:47.9375em)" : {
                                mb:0
                            }
                        }}
                    >
                        <Text semiBold>
                            {t('max_amount_orders_cart')}
                        </Text>
                        
                    </Box>
                            <NumberInput btnSize={"24"}
                                value={data.maxPickingOrderAmount ?? 0}
                            change={(e) => handleChange('maxPickingOrderAmount', e)}/>
    </Box>
    <Box 
            display={"flex"} 
            flexDirection={"column"}
            sx={{
                //maxWidth: 160,
                width:1,
                paddingTop:3,
                "@media screen and (max-width:47.9375em)" : {
                    width:1,
                    flexDirection:"row",
                    gap:4,
                    alignItems:"center",
                    justifyContent:"space-between",
                }
            }}
            >
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        mb:0.5,
                        "@media screen and (max-width:47.9375em)" : {
                            mb:0
                        }
                    }}
                >
                    <Text semiBold>
                        {t('reachtype')}
                    </Text>
                    
                </Box>
        <ShValidatedInputDropdown
            name="reachTypeId"
            displayName="description"
            changeField="id"
            selected={reachTypes?.data.findIndex(reachType => reachType.id === data.reachTypeId) ?? -1}
            noSelection={t("choose_reachtype")} //Custom text when nothing is selected
            options={reachTypes?.data ? reachTypes.data : []}
            onChange={handleChange}
            error={props.errors.reachTypeId}
        />
    </Box>
    <Box 
            display={"flex"} 
            flexDirection={"column"}
            sx={{
                //maxWidth: 160,
                width:1,
                paddingTop:3,
                "@media screen and (max-width:47.9375em)" : {
                    width:1,
                    flexDirection:"row",
                    gap:4,
                    alignItems:"center",
                    justifyContent:"space-between",
                }
            }}
            >
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        mb:0.5,
                        "@media screen and (max-width:47.9375em)" : {
                            mb:0
                        }
                    }}
                >
                    <Text semiBold>
                        {t('pickingprocesstype')}
                    </Text>
                    
                </Box>
        <ShValidatedInputDropdown
            name="pickingprocesstype"
            displayName="displayName"
            changeField="name"
            selected={pickingprocesstypes?.findIndex(pickingprocesstype => pickingprocesstype === data.pickingprocesstype) ?? -1}
            noSelection={t("choose_picking_process_type")} //Custom text when nothing is selected
            options={pickingprocesstypes ? pickingprocesstypes.map((item, index) => ({ id: index, name: item, displayName: t(item) })) : []}
            onChange={handleChange}
            error={props.errors.pickingprocesstype}
        />
</Box>
</>
    );
};

const StreamReachType = (props) => {
    const { t } = useTranslation();

    const schema = yup.object({
        reachTypeId: yupNumberRequired('reachtype',t),
        pickingprocesstype: yupRequired('pickingprocesstype',t)
    });
    return (
        <ProgressionTab
            tabId="tabReachType"
            subtitle={t("choose_picking_settings")}
            step={4}
            maxSteps={4}
            schema={schema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default StreamReachType;

